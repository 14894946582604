import {
  MainHeader,
  MainTable,
} from "../../components/screen/vendorAdminScreen";

import SearchTextField from "../../components/UI/textfield/searchTextField";

const vendorAdmin = () => {
  const handleSearchClick = (value) => {
    console.log(value);
  };

  return (
    <div>
      {/* main header from screen */}
      <MainHeader isFrom={"Shared"} hideSearch hidePagination={false} />

      {/* table from screen */}
      <MainTable />
    </div>
  );
};
export default vendorAdmin;
