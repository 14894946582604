import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },

    color: "#6C6C6C",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#EBF1F8",
    color: "#15314E",
    fontSize: "14px",
    fontWeight: 600,
  },
})(MuiListItem);

export default function SurveyList({ surveyList, handleSelectSection }) {
  const classes = useStyles();
  const [selectedSection, setSelectedSection] = React.useState(null);

  const handleListItemClick = (section) => {
    setSelectedSection(section);
    handleSelectSection(section);
  };

  const sortArray = surveyList?.length > 0 ? [...surveyList] : [];

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folder">
        {surveyList?.length > 0 &&
          sortArray
            ?.sort((a, b) => a?.order - b?.order)
            ?.map((section) => (
              <ListItem
                key={section?._id}
                button
                selected={selectedSection?._id === section?._id}
                onClick={() => handleListItemClick(section)}
                style={{ borderRadius: "8px", marginBottom: "5px" }}
              >
                <ListItemText primary={section?.title} />
              </ListItem>
            ))}
      </List>
    </div>
  );
}
