import { gql } from "@apollo/client";

export const GQL_GET_VENDOR_RISK = gql`
  query GetVendorRisk($vendorId: String!) {
    getVendorRisk(vendorId: $vendorId) {
      comment
      risk
    }
  }
`;

export const GQL_GET_ESG_RISK = gql`
  query GetEsgRisk($vendorId: String!) {
    getEsgRisk(vendorId: $vendorId) {
      comment
      risk
    }
  }
`;

export const GQL_UPDATE_VENDOR_RISK = gql`
  mutation SetVendorRisk($comment: String, $risk: String, $vendorId: String!) {
    setVendorRisk(comment: $comment, risk: $risk, vendorId: $vendorId)
  }
`;

export const GQL_UPDATE_ESG_RISK = gql`
  mutation SetEsgRisk($comment: String, $risk: String, $vendorId: String!) {
    setEsgRisk(comment: $comment, risk: $risk, vendorId: $vendorId)
  }
`;
