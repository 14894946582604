import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import WidgetComponent from "../../widgets/vendorDashboard/WidgetComponent";

import { IoPeopleCircleSharp } from "react-icons/io5";
import { FaArrowUpShortWide } from "react-icons/fa6";
import ChartsComponent from "../../widgets/vendorDashboard/ChartsComponent";
import ChartPie from "../../widgets/vendorDashboard/Charts";
import TaskContainer from "../../../containers/taskCont/taskContainer";
import GaugeChart from "../../../containers/taskCont/TempGaugeChart";
import chartImage from "../../../images/ContentChart.png";
import VendorListTable from "../../widgets/vendorDashboard/TableDashboard";
import SquareAreaChart from "../../widgets/vendorDashboard/SquareAreaChart";
import { useDispatch } from "react-redux";
import { gql_get_vendor_dashboard_data } from "../../../redux/actions/vendorActions/vendorDashboardAction";
import { selectVendor } from "../../../redux/actions/vendorActions/vendorAction";
import { useHistory } from "react-router-dom";
import VendorMaterialityTable from "../../widgets/vendorDashboard/VendorMaterialityTable";

function VendorDashboard({ data, brmData }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { vendorCount } = data;

  useEffect(() => {
    dispatch(gql_get_vendor_dashboard_data());
  }, []);

  console.log(data);

  const handleViewDetailsOrganization = (selectedOrg) => {
    localStorage.setItem("organization_id", selectedOrg._id);
    dispatch(selectVendor([selectedOrg]));

    history.push("/vendor/company_details", {
      data: brmData.organizationDetails,
      organization: selectedOrg._id,
    });
  };

  const getRiskString = (risk) => {
    if (risk >= 3.5) {
      return "High";
    } else if (risk >= 1.5 && risk < 3.5) {
      return "Medium";
    } else {
      return "Low";
    }
  };

  function severity(value, type) {
    if (type === "value") {
      return value >= 3.5
        ? "#52231D"
        : value >= 1.5 && value < 3.5
        ? "#8C7038"
        : "#154328";
    }

    if (type === "bg") {
      return value >= 3.5
        ? "#EDCAC6"
        : value >= 1.5 && value < 3.5
        ? "#FFEFD0"
        : "#BFE2CD";
    }
  }

  return (
    <Box>
      <Typography
        style={{ color: "#15314E", fontSize: "22px", fontWeight: 600 }}
      >
        Dashboard
      </Typography>
      <Box style={{ padding: "10px" }}>
        <Box style={{ display: "flex", gap: 20 }}>
          <WidgetComponent
            title={"Total No. of Vendors"}
            icon={
              <IoPeopleCircleSharp size={30} style={{ color: "#3374B9" }} />
            }
          >
            <Typography
              style={{ color: "#15314E", fontSize: "26px", fontWeight: 500 }}
            >
              {vendorCount}
            </Typography>
          </WidgetComponent>
          {/* <WidgetComponent
            title={"Avg. Security Risk"}
            icon={<FaArrowUpShortWide size={30} style={{ color: "#3374B9" }} />}
          >
            <Typography
              style={{
                color: "#52231D",
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: "#EDCAC6",
                padding: "10px 20px 10px 20px",
                borderRadius: "80px",
              }}
            >
              Low
            </Typography>
          </WidgetComponent> */}
          <WidgetComponent
            title={"Avg. Vendor Risk"}
            icon={<FaArrowUpShortWide size={30} style={{ color: "#3374B9" }} />}
          >
            <Typography
              style={{
                color: severity(data.avgVendorRisk, "value"),
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: severity(data.avgVendorRisk, "bg"),
                padding: "10px 20px 10px 20px",
                borderRadius: "80px",
              }}
            >
              {getRiskString(data.avgVendorRisk)}
            </Typography>
          </WidgetComponent>
          <WidgetComponent
            title={"Avg. ESG Risk"}
            icon={<FaArrowUpShortWide size={30} style={{ color: "#3374B9" }} />}
          >
            <Typography
              style={{
                color: severity(data.avgEsgRisk, "value"),
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: severity(data.avgEsgRisk, "bg"),
                padding: "10px 20px 10px 20px",
                borderRadius: "80px",
              }}
            >
              {getRiskString(data.avgEsgRisk)}
            </Typography>
          </WidgetComponent>
        </Box>

        <Box style={{ display: "flex", gap: 20 }}>
          <ChartsComponent title={"Vendors by Service type"}>
            <ChartPie
              backgroundColor={["#176BA0", "#19AADE", "#29066B", "#142459"]}
              label={data?.sectorCount?.industries ?? []}
              dataChart={data?.sectorCount?.values ?? []}
            />
          </ChartsComponent>
          {/* <ChartsComponent title={"Security Risk"}>
            <ChartPie
              backgroundColor={["#FFD685", "#5BB37F", "#D0766B"]}
              label={["Low", "Medium", "High"]}
              dataChart={[40, 15, 20]}
            />
          </ChartsComponent> */}
          <ChartsComponent title={"Vendor Risk"}>
            <ChartPie
              backgroundColor={["#5BB37F", "#FFD685", "#D0766B"]}
              label={["Low", "Medium", "High"]}
              dataChart={[
                data.vendorRiskGroupCount?.Low || 0,
                data.vendorRiskGroupCount?.Medium || 0,
                data.vendorRiskGroupCount?.High || 0,
              ]}
            />
          </ChartsComponent>
          <ChartsComponent title={"ESG Risk"}>
            <ChartPie
              backgroundColor={["#5BB37F", "#FFD685", "#D0766B"]}
              label={["Low", "Medium", "High"]}
              dataChart={[
                data.esgRiskGroupCount?.Low || 0,
                data.esgRiskGroupCount?.Medium || 0,
                data.esgRiskGroupCount?.High || 0,
              ]}
            />
          </ChartsComponent>
        </Box>

        <Box style={{ marginTop: "20px" }}>
          <VendorMaterialityTable vendors={data.vendors ?? []} />
        </Box>
        <Box style={{ display: "flex", gap: 20 }}>
          <ChartsComponent title={"Average Risk by Vendors"}>
            <GaugeChart value={(data.avgEsgRisk + data.avgVendorRisk) / 2} />
          </ChartsComponent>
          <ChartsComponent title={"Overall Risk Performance"}>
            {/* <Box
              style={{ width: "85%", marginLeft: "auto", marginRight: "auto" }}
            >
              <img src={chartImage} alt="chart" width={"100%"} />
            </Box> */}
            <SquareAreaChart
              data={
                data.overallRiskPerformance ?? { High: [], Medium: [], Low: [] }
              }
            />
          </ChartsComponent>
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <VendorListTable
            onClick={handleViewDetailsOrganization}
            vendors={data.vendors ?? []}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default VendorDashboard;
