// Date Middleware
export const handleDateMiddleware = (timestamp) => {
  const isMilliseconds = timestamp?.toString().length === 13;

  // Check if timestamp is in milliseconds format
  if (isMilliseconds) {
    // Convert milliseconds to ISO string
    const isoDate = new Date(parseInt(timestamp))?.toISOString();
    return isoDate;
  }

  // If timestamp is already in ISO string format, return as is
  if (timestamp instanceof Date && !isNaN(timestamp?.getTime())) {
    return timestamp?.toISOString();
  }

  // If timestamp is in another format, return as is
  return timestamp;
};

// -------------Converts to Month 'Year (Ex: March '24)-------------
export const toMonthYear = (timestamp) => {
  const formattedTimestamp = handleDateMiddleware(timestamp);
  const date = new Date(formattedTimestamp);
  const options = { year: "2-digit", month: "long" };
  let formattedDate = date?.toLocaleDateString("en-IN", options);
  formattedDate = formattedDate?.replace(/(\d{2}$)/, "'$1");
  return formattedDate;
};

// -------------Converts to Day Month Year (2digits) (Ex: 28-03-2024)-------------
export const toDDMMYY = (timestamp) => {
  const formattedTimestamp = handleDateMiddleware(timestamp);
  const date = new Date(formattedTimestamp);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}-${month}-${year}`;
};

export const toDateYear = (timestamp) => {
  const formattedTimestamp = handleDateMiddleware(timestamp);
  const date = new Date(formattedTimestamp);
  const options = { day: "2-digit", year: "2-digit", month: "short" };
  let formattedDate = date?.toLocaleDateString("en-IN", options);
  formattedDate = formattedDate?.replace(/(\d{2}$)/, "'$1");
  return formattedDate;
};
