import {
  AppBar,
  Box,
  Drawer,
  Grid,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RiskSurveyTop from "../../widgets/riskSurveyComponents/Main/RiskSurveyTop";
import RiskSurveyDrawer from "../../widgets/riskSurveyComponents/Main/RiskSurveyDrawer";
import VendorLeft from "./VendorLeft";
import VendorRight from "./VendorRight";
import ProceedButtonCard from "../../UI/proceedButtonCard/proceedButtonCard";
import { appbarStyles } from "../../widgets/esgCompanyProfile/esgCompantProfile";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { appTheme } from "../../widgets/riskAssessment/brmDetaails";
import VendorProceedButton from "./vendorComps/VendorProceedButton";
import { setVendorBasicDetails } from "../../../redux/actions/vendorActions/vendorAction";
import { useDispatch, useSelector } from "react-redux";

function VendorScreen(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { setVendorTabNo, setVendorSubTabNo, vendor } = props;
  // console.log("ven", props);
  // handling drawer open and close

  const { login } = useSelector((state) => state);
  const history = useHistory();
  const tabName = localStorage.getItem("selectedTab");

  useEffect(() => {
    if (tabName === "/vendor/basic_details" && login?.isLoggedIn) {
      setVendorSubTabNo(0);
      setVendorTabNo(0);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/basic_details`
      );
    } else if (tabName === "/vendor/stakeholders" && login?.isLoggedIn) {
      setVendorSubTabNo(1);
      setVendorTabNo(0);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/stakeholders`
      );
    } else if (
      tabName === "/vendor/register_of_information" &&
      login?.isLoggedIn
    ) {
      setVendorSubTabNo(2);
      setVendorTabNo(0);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/register_of_information`
      );
    } else if (tabName === "/vendor/vendor_materiality" && login?.isLoggedIn) {
      setVendorSubTabNo(3);
      setVendorTabNo(0);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/vendor_materiality`
      );
    } else if (tabName === "/vendor/vendor_performance" && login?.isLoggedIn) {
      setVendorSubTabNo(4);
      setVendorTabNo(0);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/vendor_performance`
      );
    } else if (
      tabName === "/vendor/vendor_categorization" &&
      login?.isLoggedIn
    ) {
      setVendorSubTabNo(5);
      setVendorTabNo(0);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/vendor_categorization`
      );
    }

    // Tab 2
    if (tabName === "/vendor/api_connection" && login?.isLoggedIn) {
      setVendorSubTabNo(0);
      setVendorTabNo(1);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/api_connection`
      );
    } else if (tabName === "/vendor/survey" && login?.isLoggedIn) {
      setVendorSubTabNo(1);
      setVendorTabNo(1);
      history.push(`/vendor/${localStorage.getItem("companyName")}/survey`);
    } else if (tabName === "/vendor/documents" && login?.isLoggedIn) {
      setVendorSubTabNo(2);
      setVendorTabNo(1);
      history.push(`/vendor/${localStorage.getItem("companyName")}/documents`);
    }

    // Tab 3
    if (tabName === "/vendor/vendor_profile" && login?.isLoggedIn) {
      setVendorSubTabNo(0);
      setVendorTabNo(2);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/vendor_profile`
      );
    } else if (tabName === "/vendor/document_compliance" && login?.isLoggedIn) {
      setVendorSubTabNo(1);
      setVendorTabNo(2);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/document_compliance`
      );
    } else if (
      tabName === "/vendor/vendor_survey_responses" &&
      login?.isLoggedIn
    ) {
      setVendorSubTabNo(2);
      setVendorTabNo(2);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/vendor_survey_responses`
      );
    }

    // Tab 4
    if (tabName === "/vendor/esg_profile" && login?.isLoggedIn) {
      setVendorSubTabNo(0);
      setVendorTabNo(3);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/esg_profile`
      );
    } else if (tabName === "/vendor/esg_kpis" && login?.isLoggedIn) {
      setVendorSubTabNo(1);
      setVendorTabNo(3);
      history.push(`/vendor/${localStorage.getItem("companyName")}/esg_kpis`);
    } else if (
      tabName === "/vendor/esg_survey_responses" &&
      login?.isLoggedIn
    ) {
      setVendorSubTabNo(2);
      setVendorTabNo(3);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/esg_survey_responses`
      );
    }

    // Tab 5
    if (tabName === "/vendor/digital_reputation" && login?.isLoggedIn) {
      setVendorSubTabNo(0);
      setVendorTabNo(4);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/digital_reputation`
      );
    } else if (tabName === "/vendor/articles" && login?.isLoggedIn) {
      setVendorSubTabNo(1);
      setVendorTabNo(4);
      history.push(`/vendor/${localStorage.getItem("companyName")}/articles`);
    } else if (tabName === "/vendor/insights" && login?.isLoggedIn) {
      setVendorSubTabNo(2);
      setVendorTabNo(4);
      history.push(`/vendor/${localStorage.getItem("companyName")}/insights`);
    }

    // Tab 6
    if (tabName === "/vendor/third_party_risk" && login?.isLoggedIn) {
      setVendorSubTabNo(0);
      setVendorTabNo(5);
      history.push(
        `/vendor/${localStorage.getItem("companyName")}/third_party_risk`
      );
    } else if (tabName === "/vendor/esg_risk" && login?.isLoggedIn) {
      setVendorSubTabNo(1);
      setVendorTabNo(5);
      history.push(`/vendor/${localStorage.getItem("companyName")}/esg_risk`);
    }
    // console.log(localStorage.getItem("selectedTab"));
  }, [history]);

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const classes = appbarStyles();
  const dispatch = useDispatch();

  const [linkageSurveyId, setLinkageSurveyId] = useState(null);

  // useEffect(() => {
  //   setVendorSubTabNo(0);
  //   setVendorTabNo(0);
  // }, []);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        // px: 5,
      }}
    >
      {/* Main areas */}
      {/* <RiskSurveyTop handleDrawer={handleDrawer} /> */}

      {/* Drawer */}
      {/* <Drawer anchor="right" open={drawerOpen} onClose={handleDrawer}>
        <RiskSurveyDrawer handleDrawer={handleDrawer} />
      </Drawer> */}

      {/* MAIN SECTION VENDOR LEFT AND RIGHT */}
      <Grid container>
        <ArrowBack
          onClick={() => {
            history.push("/vendor");
            setTimeout(() => {
              dispatch(setVendorBasicDetails({}));
            }, 100);
          }}
          style={{ cursor: "pointer" }}
        />
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          {props?.brmData?.vendorBasicDetails?.name}
        </Typography>
      </Grid>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          {
            <Tabs
              value={vendor.vendorTabNo}
              onChange={(e, newVal) => {
                setVendorTabNo(newVal);
                setVendorSubTabNo(0);
              }}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Company Setup</span>}
              />

              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Data Setup</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>Vendor Diagnosis</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>ESG Diagnosis</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>Digital Reputation</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Vendor Risk</span>}
              />
            </Tabs>
          }
        </AppBar>
      </MuiThemeProvider>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          // height: windowWidth >= 992 ? "76vh" : "91%",
          padding: 10,
        }}
      >
        <Grid item xs={12} sm={11} md={4} style={{ height: "75vh" }}>
          <VendorLeft {...props} setLinkageSurveyId={setLinkageSurveyId} />
        </Grid>
        <Grid item xs={12} sm={11} md={8} style={{ height: "75vh" }}>
          <VendorRight {...props} linkageSurveyId={linkageSurveyId} />
        </Grid>
        <Grid item xs={11} md={11}>
          {<VendorProceedButton {...props} />}
        </Grid>
      </Grid>
    </Box>
  );
}

export default VendorScreen;
