import React, { useEffect, useState } from "react";
import { Box, Icon, IconButton, Typography } from "@material-ui/core";

import StepRight from "../../stepLayout/right/StepRight";

import CustomButton from "../../../../../UI/button/button";
import SurveyQuestionTable from "./SurveyQuestionTable";
import AssignDrawer from "../assignDrawer/AssignDrawer";
import ExploreQuestion from "./ExploreQuestion";
import { FaListUl } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { FiActivity } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi";
import { FiUserCheck } from "react-icons/fi";
import NoData from "../../../../NoData/NoData";

import { useDispatch, useSelector } from "react-redux";
import { gql_get_assigned_status } from "../../../../../../redux/actions/vendorActions/vendorAction";
import ThreadDrawer from "../threadDrawer/ThreadDrawer";

function Step2Right({
  isFrom,
  sectionQuestions, // list of all the questions
  status,
  handleStatus,
  surveyList, // list of all the sections
  selectedSection, // state of section that was selected
  handleStatusChangeOnAssign,
}) {
  // from redux
  const dispatch = useDispatch();
  // states
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isThreadDrawerOpen, setIsThreadDrawerOpen] = useState(false);
  const [hasExplore, setHasExplore] = useState(false);
  const [selectedQues, setSelectedQues] = useState(null);
  const [quesAssignedData, setQuesAssignedData] = useState(null);

  const { selectedVASurvey } = useSelector((state) => state.vendorReducer);

  const handleExploreQuestion = (data) => {
    setHasExplore(true);
    setSelectedQues(data);
  };

  useEffect(() => {
    if (selectedQues?._id) {
      const getQuestionAssignedStatusInput = {
        assignedVersion: selectedQues?.version,
        frequencyId: selectedVASurvey?.frequencyId,
        questionId: selectedQues?._id,
      };

      dispatch(
        gql_get_assigned_status(
          getQuestionAssignedStatusInput,
          setQuesAssignedData
        )
      );
    } else {
      setQuesAssignedData(null);
    }
  }, [selectedQues?._id]);

  useEffect(() => {
    setHasExplore(false);
  }, [selectedSection?._id]);

  return (
    <StepRight>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          {hasExplore && (
            <IconButton size="small">
              <Icon
                style={{ color: "#3374B9" }}
                onClick={() => {
                  setHasExplore(false);
                  setQuesAssignedData(null);
                  setSelectedQues(null);
                }}
                fontSize="small"
              >
                arrow_back
              </Icon>
            </IconButton>
          )}
          Company Profile
        </Typography>
        {hasExplore
          ? // <CustomButton
            //   variant="contained"
            //   color="primary"
            //   onClick={() => {
            //     setIsThreadDrawerOpen(true);
            //   }}
            //   startIcon={
            //     <Icon style={{ transform: "scaleX(-1)" }}>keyboard_return</Icon>
            //   }
            //   style={{ textTransform: "capitalize" }}
            // >
            //   View Thread
            // </CustomButton>
            ""
          : isFrom !== "completedSurvey" &&
            selectedSection && (
              <CustomButton
                variant="text"
                color="primary"
                onClick={() => setIsDrawerOpen(true)}
                style={{ textTransform: "capitalize" }}
              >
                Assign Question
              </CustomButton>
            )}
      </Box>
      {selectedSection && !hasExplore && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 20,
            alignItems: "center",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FaListUl size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {selectedSection?.noOfQuestions} Questions
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FiUserPlus size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {sectionQuestions?.noOfAssignedQuestions} Questions Assigned
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FaCheck size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {sectionQuestions?.noOfCompletedQuestions} Questions Completed
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FiActivity size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {sectionQuestions?.overallStatus}
            </Typography>
          </Box>
        </Box>
      )}

      {hasExplore && quesAssignedData && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <HiOutlineUsers size={"16px"} style={{ color: "#5C90C7" }} />
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {/* {`11 contributors`} */}
            {quesAssignedData?.contributors?.length}{" "}
            {quesAssignedData?.contributors?.length > 1
              ? "Contributors"
              : "Contributor"}
          </Typography>
          <FiUserCheck size={"16px"} style={{ color: "#5C90C7" }} />

          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {/* {`0 Approver`} */}
            {quesAssignedData?.approvers?.length}{" "}
            {quesAssignedData?.approvers?.length > 1 ? "Approvers" : "Approver"}
          </Typography>
        </Box>
      )}

      {hasExplore && (
        <Box style={{ overflow: "scroll", height: "80%" }}>
          <ExploreQuestion quesAssignedData={quesAssignedData} />
        </Box>
      )}

      {!hasExplore &&
        (selectedSection === null ? (
          <Box style={{ height: "50%" }}>
            <NoData message={"Please Select Section"} />
          </Box>
        ) : sectionQuestions?.questions?.length > 0 ? (
          <Box style={{ overflow: "scroll", height: "80%" }}>
            <SurveyQuestionTable
              status={status}
              handleExploreQuestion={handleExploreQuestion}
              sectionQuestions={sectionQuestions?.questions}
            />
          </Box>
        ) : (
          <Box style={{ height: "50%" }}>
            <NoData message={"No Available Questions"} />
          </Box>
        ))}

      {isDrawerOpen && (
        <AssignDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={() => {
            setIsDrawerOpen(false);
            // handleStatus("In Progress");
          }}
          optList={sectionQuestions?.questions}
          drawerFor={"questions"}
          selectedSectionId={selectedSection?._id}
          handleStatusChangeOnAssign={handleStatusChangeOnAssign}
        />
      )}

      {isThreadDrawerOpen && (
        <ThreadDrawer
          isDrawerOpen={isThreadDrawerOpen}
          handleCloseDrawer={() => {
            setIsThreadDrawerOpen(false);
            // handleStatus("In Progress");
          }}
          selectedQues={selectedQues}
        />
      )}
    </StepRight>
  );
}

export default Step2Right;
