export const SET_BRM_MAIN_TAB_NO = "SET_BRM_MAIN_TAB_NO";
export const SET_BRM_SUB_TAB_NO = "SET_BRM_SUB_TAB_NO";
export const SET_SECTOR_INDUSTRIES = "SET_SECTOR_INDUSTRIES";
export const SET_SELECTED_SECTOR_INDUSTRIES = "SET_SELECTED_SECTOR_INDUSTRIES";
export const SET_ORGANIZATION_DETAILS = "SET_ORGANIZATION_DETAILS";
export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";
export const SET_VENDOR_DASHBOARD_DATA = "SET_VENDOR_DASHBOARD_DATA";
export const SET_ADD_VENDOR = "SET_ADD_VENDOR";
export const SET_MEMBERS = "SET_MEMBERS";
export const SET_ADD_STAKEHOLDER = "SET_ADD_STAKEHOLDER";
export const SET_VENDOR_BASIC_DETAILS = "SET_VENDOR_BASIC_DETAILS";
export const SET_VENDORS_BY_ISIN = "SET_VENDORS_BY_ISIN";
export const SET_SEARCH_SURVEYS_BY_NAME = "SET_SEARCH_SURVEYS_BY_NAME";
export const SET_SEARCH_VENDORS_BY_NAME = "SET_SEARCH_VENDORS_BY_NAME";
export const SET_SHOW_SURVEYS = "SET_SHOW_SURVEYS";
export const SET_SHOW_SELECTED_SURVEY = "SET_SHOW_SELECTED_SURVEY";
export const SET_LINKAGE_SURVEYS = "SET_LINKAGE_SURVEYS";
export const SET_LINKAGE_SURVEY_SECTIONS = "SET_LINKAGE_SURVEY_SECTIONS";
export const SET_LINKAGE_SURVEY_QUESTIONS = "SET_LINKAGE_SURVEY_QUESTIONS";
export const SET_VENDOR_MATERIALITY_SCORES = "SET_VENDOR_MATERIALITY_SCORES";

// Vendor Admin
export const SET_VA_BANKS_LIST = "SET_VA_BANKS_LIST";
export const SET_BANK_SHARED_SURVEY = "SET_BANK_SHARED_SURVEY";
export const SET_SELECTED_BANK_DETAILS_FOR_VA =
  "SET_SELECTED_BANK_DETAILS_FOR_VA";
export const SET_REQUESTED_FILES = "SET_REQUESTED_FILES";

export const SET_DECLARATION_TAB_NO = "SET_DECLARATION_TAB_NO ";
export const SET_CONTROVERSY_TAB_NO = "SET_CONTROVERSY_TAB_NO ";
export const SET_PROFILE_TAB_NO = "SET_PROFILE_TAB_NO ";
export const SET_COUNTRIES = "SET_COUNTRIES ";

export const SET_COMPANY_DOCUMENTS = "SET_COMPANY_DOCUMENTS ";
export const SET_UPDATE_ORGS_LIST = "SET_UPDATE_ORGS_LIST ";
export const SET_ORGANIZATION_DATA = "SET_ORGANIZATION_DATA";
