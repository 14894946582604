import React, { useEffect } from "react";

import { Grid, Paper, Typography, Box } from "@material-ui/core";
import ScoreChart from "./ScoreChart";
import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";
import { green } from "@material-ui/core/colors";
import { TopicData, TopicHeader } from "./esgComponents";
import VendorScoreChart from "./VendorScoreChart";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_vendor_Assessment_score } from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";

function VendorScore(props) {
  // const { scores } = props;
  // const { environment, social, governance } = scores?.scoreObject;
  const { vendorAssessmentScore } = useSelector(
    (state) => state.esgDiagnosisReducer
  );
  const { vendorBasicDetails } = useSelector((state) => state.brmData);

  const { scoreObject } = props?.scores;

  const dispatch = useDispatch();
  const storedWazuhGroup = localStorage.getItem("wazuhGroup");

  useEffect(() => {
    dispatch(
      gql_get_vendor_Assessment_score(
        vendorBasicDetails?.wazuhGroup || storedWazuhGroup
      )
    );
  }, []);

  // const { environment, social, governance } = scores?.scoreObject;

  // const TopicHeader = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         borderBottom: "1px solid lightblue",
  //         marginTop: "10px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <img src={getColoredLogo(topic)} alt="leaf" />
  //         <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };
  // const TopicData = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         marginTop: "8px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <Typography
  //           style={{ fontSize: "14px", marginLeft: "5px", fontWeight: 400 }}
  //         >
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };

  const dataNew = [
    {
      topic: "Security Information Management",
      score: scoreObject?.security_information_management?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Security Events",
          score:
            scoreObject?.security_information_management?.data_points?.security_events?.toFixed(
              2
            ),
        },
        {
          topic: "Integrity Monitoring",
          score:
            scoreObject?.security_information_management?.data_points?.integrity_monitoring?.toFixed(
              2
            ),
        },
      ],
    },
    {
      topic: "Audit and Policy Monitoring",
      score: scoreObject?.audit_and_policy_monitoring?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Policy Monitoring",
          score:
            scoreObject?.audit_and_policy_monitoring?.data_points?.policy_monitoring?.toFixed(
              2
            ),
        },
        // {
        //   topic: "Security Configuration Assessment",
        //   score:
        //     scoreObject?.audit_and_policy_monitoring?.data_points?.security_configuration_assessment?.toFixed(
        //       2
        //     ),
        // },
      ],
    },
    {
      topic: "Threat Detection and Response",
      score: scoreObject?.threat_detection_and_response?.score?.toFixed(2),
      subTopic: [
        // {
        //   topic: "Vulnerabilities",
        //   score:
        //     scoreObject?.threat_detection_and_response?.data_points?.vulnerabilities?.toFixed(
        //       2
        //     ),
        // },
        {
          topic: "Mitre Attack",
          score:
            scoreObject?.threat_detection_and_response?.data_points?.mitre_attack?.toFixed(
              2
            ),
        },
      ],
    },
    {
      topic: "Regulatory Compliance",
      score: scoreObject?.regulatory_compilance?.score?.toFixed(2),
      subTopic: [
        {
          topic: "NIST 800-53",
          score:
            scoreObject?.regulatory_compilance?.data_points?.nist?.toFixed(2),
        },
      ],
    },
  ];
  return (
    <div style={{ paddingBottom: "18px", marginTop: "30px", width: "100%" }}>
      <Grid container md={12}>
        <Grid item md={7} sm={12}>
          {/* <Typography
            style={{ fontSize: "13px", padding: "5px" }}
            component={Paper}
          >
            The score reflects the company's ESG performance and can guide
            stakeholders in making informed decisions. (Out of 100) 54
          </Typography> */}
          <div
            style={{ display: "flex", flexWrap: "wrap", gap: 10, rowGap: 30 }}
          >
            {vendorAssessmentScore?.topics?.map((data, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: "49%",
                    borderRadius: 8,
                    backgroundColor: "#FBFBFB",
                    paddingBottom: 5,
                  }}
                >
                  <TopicHeader topic={data.topic} score={data.score} />
                  {data.subTopics.map((sub, i) => (
                    <TopicData key={i} topic={sub.subTopic} score={sub.score} />
                  ))}
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item md={5} sm={12}>
          {<VendorScoreChart vendorAssessmentScore={vendorAssessmentScore} />}
        </Grid>

        {/* vendor scrore */}
        {/* <BlueBackgroundCard heading={"Vendor Score"} />
        <Box
          style={{
            padding: 5,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="h4">54%</Typography>
          <Typography variant="body2">
            Suporting text to the ESG Split score explaining Suporting text to
            the ESG Split score explaining{" "}
          </Typography>
        </Box> */}
      </Grid>
    </div>
  );
}

export default VendorScore;
