import { Box, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import CustomButton from "../../UI/button/button";
import {
  ZoomIn,
  ZoomOut,
  Fullscreen,
  Download,
  Print,
  Restore,
} from "@material-ui/icons";
import Draggable from "react-draggable";
import GetAppIcon from "@material-ui/icons/GetApp";
import Textfield from "../../UI/textfield/textfield";
import NoData from "../NoData/NoData";

function PdfViewer({ docUrl, setPageNumber, pageNumber }) {
  const [numPage, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.3);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // console.log(pageNumber);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2.0)); // Max zoom in level is 2.0
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom out level is 0.5
  };

  const handleResetZoom = () => {
    setScale(1.3);
    setPosition({ x: 0, y: 0 });
  };

  const handleDownload = () => {
    window.open(docUrl);
  };

  const handlePrint = () => {
    window.print();
  };

  console.log(pageNumber);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 10,
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleZoomOut}>
          <ZoomOut />
        </IconButton>
        <Typography style={{ textAlign: "center" }} variant="body2">
          {(scale * 100).toFixed(0)}%
        </Typography>
        <IconButton onClick={handleZoomIn}>
          <ZoomIn />
        </IconButton>
        <IconButton onClick={handleResetZoom}>
          <Restore />
        </IconButton>
        <IconButton onClick={handleDownload}>
          <GetAppIcon />
        </IconButton>
        {/* <IconButton onClick={handlePrint}>
          <Print />
        </IconButton> */}
      </Box>
      <Box
        style={{
          overflow: "scroll",
          height: 560,
          width: 600,
          border: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        {!isNaN(pageNumber) ? (
          <Draggable
            // #3374b9
            position={position}
            onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
          >
            <div
              style={{
                width: "fit-content",
                cursor: "grab",
              }}
            >
              <Document
                file={docUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Page height={600} pageNumber={pageNumber} scale={scale}></Page>
              </Document>
            </div>
          </Draggable>
        ) : (
          // <Typography style={{ textAlign: "center" }}>
          //   No Source Found!
          // </Typography>
          <Box style={{ marginTop: "100px" }}>
            <NoData message="No source found"></NoData>
          </Box>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          gap: 10,
          // padding: "20px",
          marginTop: 15,
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
          variant="body2"
        >
          Page{" "}
          <Textfield
            value={isNaN(pageNumber) ? "" : pageNumber}
            onChange={(e) => {
              if (numPage >= pageNumber) {
                setPageNumber(+e.target.value);
              } else {
                setPageNumber(numPage);
              }
            }}
            variant="outlined"
            size="small"
            style={{ width: 80 }}
          />{" "}
          of {numPage}
        </Typography>
        <Box style={{ display: "flex", justifyContent: "center", gap: 10 }}>
          <CustomButton
            variant="outlined"
            color="primary"
            disabled={pageNumber === 1}
            onClick={() => {
              setPageNumber((step) => step - 1);
              // handlePrev(step);
            }}
            style={{ textTransform: "none", fontSize: 12 }}
          >
            Prev
          </CustomButton>
          <CustomButton
            variant="outlined"
            disabled={pageNumber === numPage}
            color="primary"
            onClick={() => {
              setPageNumber((step) => step + 1);
            }}
            style={{ textTransform: "none", fontSize: 12 }}
          >
            Next
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}

export default PdfViewer;
