// import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  SET_SELECTED_QUESTION,
  SET_SELECTED_TASK,
  SET_SELECTED_BANK_SURVEY,
  SET_BANK_TASKS,
  SET_TASK_SECTIONS,
  SET_TASK_QUESTIONS,
  SET_TOTAL_SUBMITTED_QUESTIONS,
} from "../../../constants/taskConstants";
import {
  selectSection1,
  selectSection2,
} from "../../../components/screen/taskScreen/taskTemp";
import {
  GET_BANK_TASKS,
  GET_TASK_QUESTIONS,
  GET_TASK_SECTIONS,
  GET_TASK_SECTIONS_FOR_APPROVER,
  GET_TASK_SECTIONS_FOR_CONTRIBUTOR,
  GQL_REDO_ENTIRE_SECTION,
  SUBMIT_ANSWER,
} from "../../graphql/gql_TaskQueries";
import { client } from "../../store/store";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";
import { GET_TASK_QUESTIONS_FOR_APPROVER } from "../../graphql/gql_TaskQueries";
import { UPDATE_QUESTION } from "../../graphql/gql_SurveyQueries";
import { set_snack_bar } from "../snackbar/snackbar_action";
import encrypt from "../shared/sharedActions";

export const setSelectedQuestion = (payload) => {
  return {
    type: SET_SELECTED_QUESTION,
    payload,
  };
};

export const setSelectedTask = (payload) => {
  return {
    type: SET_SELECTED_TASK,
    payload,
  };
};

// -------------------------------------------------------------------
export const SelectedSurveyTask = (rowData) => {
  return (dispatch) => {
    dispatch(set_loader);

    dispatch(setSelectedTask(rowData));

    dispatch(unset_loader);
  };
};

export const setSelectedBankSurvey = (payload) => {
  return {
    type: SET_SELECTED_BANK_SURVEY,
    payload,
  };
};

// set list of bank tasks
export const setBankTasks = (tasks) => {
  return { type: SET_BANK_TASKS, payload: tasks };
};

// set list of sections for selected task
export const setTaskSections = (sections) => {
  return { type: SET_TASK_SECTIONS, payload: sections };
};

// set list of questions for selected task
export const setTaskQuestions = (questions) => {
  return { type: SET_TASK_QUESTIONS, payload: questions };
};

export const setTotalSubmittedQuestions = (total) => {
  return { type: SET_TOTAL_SUBMITTED_QUESTIONS, payload: total };
};

// ------------------------------- gql -------------------------------

export function gql_get_task_sections_for_approvers(
  frequencyId,
  surveyId,
  setActiveTab,
  vendorId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GET_TASK_SECTIONS_FOR_APPROVER,
        variables: {
          getAssignedSectionsInput: { frequencyId, surveyId, vendorId },
        },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setTaskSections(result.data?.getAssignedSectionsForApprover));

      if (result.data?.getAssignedSectionsForApprover) {
        setActiveTab(result.data.getAssignedSectionsForApprover[0]);
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_task_sections_for_contributors(
  frequencyId,
  surveyId,
  setActiveTab,
  vendorId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GET_TASK_SECTIONS_FOR_CONTRIBUTOR,
        variables: {
          getAssignedSectionsInput: { frequencyId, surveyId, vendorId },
        },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(
        setTaskSections(result.data?.getAssignedSectionsForContributors)
      );

      if (result.data?.getAssignedSectionsForContributors) {
        setActiveTab(result.data.getAssignedSectionsForContributors[0]);
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_task_questions(
  frequencyId,
  sectionId,
  setCurrentQuestion,
  vendorId,
  bankId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GET_TASK_QUESTIONS,
        variables: {
          getAssignedQuestionsInput: {
            frequencyId,
            sectionId,
            vendorId,
            bankId,
          },
        },
      };

      console.log(vendorId, bankId);

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // set total submitted questions
      dispatch(
        setTotalSubmittedQuestions(
          result.data?.getAssignedQuestionsForContributors[0]
            ?.totalSubmittedQuestions
        )
      );

      // set list of questions
      dispatch(
        setTaskQuestions(
          result.data?.getAssignedQuestionsForContributors[0]?.questions
        )
      );

      // set current question
      if (result.data) {
        setCurrentQuestion(
          result.data?.getAssignedQuestionsForContributors[0]?.questions[0]
        );
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_bank_tasks(active, bankId, vendorId, limit, page) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GET_BANK_TASKS,
        variables: { input: { active, vendorId, bankId } },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setBankTasks(result.data.tasks));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export const UpdateQuestionOption = (ques_id, selectedOpt, survey_id) => {
  return (dispatch, getState) => {
    dispatch(set_loader);

    try {
      const selectedSection = getState().taskReducer.selectedSection;

      const surveyToUpdate = selectedSection.find(
        (survey) => survey._id === survey_id
      );

      if (surveyToUpdate) {
        const questionToUpdate = surveyToUpdate.questions.find(
          (question) => question._id === ques_id
        );

        if (questionToUpdate) {
          // Update the selectedOpt of the question
          questionToUpdate.selectedOpt = selectedOpt;

          // Dispatch action to update selectedSection
          dispatch(setSelectedTask(selectedSection));
        }
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export const UpdateSurveyComplete = (type, survey_id) => {
  return (dispatch, getState) => {
    dispatch(set_loader);

    try {
      const selectedSection = getState().taskReducer.selectedSection;

      const surveyToUpdate = selectedSection.find(
        (survey) => survey._id === survey_id
      );

      if (surveyToUpdate) {
        if (type === "add") {
          surveyToUpdate.isSurveyComplete = true;
        } else {
          surveyToUpdate.isSurveyComplete = false;
          surveyToUpdate.questions.forEach((question) => {
            question.selectedOpt = "";
          });
        }
        dispatch(setSelectedTask(selectedSection));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export async function upload_contributor_submitted_doc(file, id) {
  // formData.append("file", file);
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(
      UNIVERSAL.BASEURL + `/file-upload/submit_survey_doc/${id}`,
      {
        method: "POST",
        body: formData,
        // headers: {
        //   "Content-Type": "application/pdf",
        // },
      }
    );

    const res = await response.json();

    console.log("File uploaded successfully", res);
    return res;
  } catch (error) {
    throw error.message;
  }
}

// const singleFileUpload = async (file, id) => {
//   console.log(file);
//   const formData = new FormData();
//   formData.append("file", file);
//   // console.log(questionType, formData);

//   const uploadPromise = await fetch(
//     UNIVERSAL.BASEURL + `/file-upload/submit_survey_doc/${id}`,
//     {
//       method: "POST",
//       body: formData,
//       // headers: {
//       //   "Content-Type": "application/pdf",
//       // },
//     }
//   );

//   const res = await uploadPromise.json();

//   return res;
// };

const multiFileUpload = async (files, id) => {
  if (!Array.isArray(files)) {
    throw new Error("The 'files' argument should be an array of file objects.");
  }

  const uploadPromises = files.map(async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      UNIVERSAL.BASEURL + `/file-upload/submit_survey_doc/${id}`,
      {
        method: "POST",
        body: formData,
      }
    );

    const result = await response.json();
    return result;
  });

  // Wait for all upload promises to complete
  const results = await Promise.allSettled(uploadPromises);

  return results;
};

export function gql_submit_answer(
  submitAnswerObj,
  fetchQuestionsObj,
  questionType,
  bankId,
  vendorId
) {
  return async (dispatch, getState) => {
    dispatch(set_loader());

    const surveyId = getState().taskReducer.selectedTask?.survey?._id;

    let documentObj = questionType === "TABLE" ? [] : null;
    let documentUrl = null;

    let answersArray;

    if (questionType === "TABLE") {
      answersArray = submitAnswerObj?.tableRows?.map((ans) => {
        return ans?.map((a) => {
          if (a?.documentUrl === null) return a;
          const { documentUrl: document, ...rest } = a;
          documentObj.push(document);

          return rest;
        });
      });
    } else {
      answersArray = submitAnswerObj?.selectedAnswer?.map((ans) => {
        if (ans?.documentUrl === null) return ans;
        const { documentUrl: document, ...rest } = ans;
        documentObj = document;

        return rest;
      });
    }

    // console.log(documentObj);

    if (questionType === "TABLE") {
      if (documentObj !== null || documentObj?.length > 0) {
        documentUrl = await multiFileUpload(
          documentObj,
          submitAnswerObj?.questionId,
          questionType
        );
      }
    } else {
      if (documentObj !== null || documentObj?.length > 0) {
        documentUrl = await upload_contributor_submitted_doc(
          documentObj,
          submitAnswerObj?.questionId,
          questionType
        );
      }
    }

    let answersUpdatedArray = [];

    if (questionType === "TABLE") {
      // answersUpdatedArray = submitAnswerObj.map(ans=>{
      //   return ans?.map((a,i) => {
      //     if(a?.documentUrl?.name === documentUrl[])
      //   })
      // })

      const apiMapping = {};
      documentUrl?.forEach((item) => {
        apiMapping[item?.value?.name] = item?.value;
      });

      // Function to replace the documentUrl in the initial array
      function replaceDocumentUrls(arr, mapping) {
        return arr?.map((subArray) => {
          return subArray?.map((item) => {
            if (item?.documentUrl && item?.documentUrl?.name) {
              const docName = item?.documentUrl?.name;
              if (mapping[docName]) {
                return {
                  option: item?.option,
                  weightage: item?.weightage,
                  comment: item?.comment,
                  documentUrl: mapping[docName],
                };
              }
            }
            return item;
          });
        });
      }

      // Replace the documentUrl in the initial array
      answersUpdatedArray = replaceDocumentUrls(
        submitAnswerObj?.tableRows,
        apiMapping
      );
    } else {
      answersUpdatedArray = answersArray.map((ans) => {
        if (ans?.documentUrl) return ans;
        return { ...ans, documentUrl };
      });
    }

    console.log("submitted data", answersUpdatedArray);

    try {
      const mutateObj = {
        mutation: SUBMIT_ANSWER,
        variables: {
          submitQuestionInput: {
            questionId: submitAnswerObj?.questionId,
            selectedAnswer: questionType === "TABLE" ? [] : answersUpdatedArray,
            tableRows: questionType === "TABLE" ? answersUpdatedArray : [],
            approverIds: submitAnswerObj?.approverIds,
            chatRoomId: submitAnswerObj?.chatRoomId,
            frequencyId: submitAnswerObj?.frequencyId,
            surveyId,
            sectionId: fetchQuestionsObj?.sectionId,
            bankId,
            vendorId,
          },
        },
      };
      const result = await client.mutate(mutateObj);
      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }
      // fetch updated questions
      await dispatch(
        gql_get_task_questions(
          fetchQuestionsObj?.frequencyId,
          fetchQuestionsObj?.sectionId,
          fetchQuestionsObj?.setCurrentQuestion,
          vendorId,
          bankId
        )
      );
      // return true;
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to update question"));
      console.log(err);
      dispatch(unset_loader());
      return false;
    }
    dispatch(unset_loader());
  };
}

// export function gql_submit_answer(
//   submitAnswerObj,
//   fetchQuestionsObj,
//   questionType
// ) {
//   return async (dispatch, getState) => {
//     dispatch(set_loader());

//     const surveyId = getState().taskReducer.selectedTask?.survey?._id;
//     const companyId = getState().brmData.vendorBasicDetails?._id;

//     let documentObj = null;
//     let documentUrl = null;

//     let answersArray;

//     if (questionType === "TABLE") {
//       answersArray = submitAnswerObj?.tableRows?.map((ans) => {
//         return ans?.map((a) => {
//           return a;
//         });
//       });
//     } else {
//       answersArray = submitAnswerObj?.selectedAnswer?.map((ans) => {
//         if (ans?.documentUrl === null) return ans;
//         const { documentUrl: document, ...rest } = ans;
//         documentObj = document;

//         return rest;
//       });
//     }

//     if (documentObj !== null || documentObj?.length > 0) {
//       documentUrl = await upload_contributor_submitted_doc(
//         documentObj,
//         submitAnswerObj?.questionId,
//         questionType
//       );
//     }

//     // console.log("returned doc", documentUrl);

//     let answersUpdatedArray = [];

//     if (questionType === "TABLE") {
//       for (let j = 0; j < answersArray.length; j++) {
//         let ans = answersArray[j];

//         let arr = [];
//         for (let i = 0; i < ans.length; i++) {
//           const a = ans[i];

//           console.log(a);
//           if (a?.documentUrl === null) {
//             arr.push(a);
//           } else {
//             const file = await singleFileUpload(
//               a?.documentUrl,
//               submitAnswerObj?.questionId
//             );

//             arr.push({ ...a, documentUrl: file });
//           }
//         }
//         answersUpdatedArray.push(arr);
//       }
//     } else {
//       answersUpdatedArray = answersArray.map((ans) => {
//         if (ans?.documentUrl) return ans;
//         return { ...ans, documentUrl };
//       });
//     }

//     // console.log("submitted data", answersUpdatedArray);

//     // try {
//     //   const mutateObj = {
//     //     mutation: SUBMIT_ANSWER,
//     //     variables: {
//     //       submitQuestionInput: {
//     //         questionId: submitAnswerObj?.questionId,
//     //         selectedAnswer: questionType === "TABLE" ? [] : answersUpdatedArray,
//     //         tableRows: questionType === "TABLE" ? answersUpdatedArray : [],
//     //         approverIds: submitAnswerObj?.approverIds,
//     //         chatRoomId: submitAnswerObj?.chatRoomId,
//     //         frequencyId: submitAnswerObj?.frequencyId,
//     //         surveyId,
//     //         sectionId: fetchQuestionsObj?.sectionId,
//     //       },
//     //     },
//     //   };
//     //   const result = await client.mutate(mutateObj);
//     //   if (result.errors) {
//     //     const isUnAuth = checkIsUnAuth(result.errors[0]);
//     //     if (isUnAuth) {
//     //       dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
//     //     } else {
//     //       throw result.errors[0];
//     //     }
//     //   }
//     //   // fetch updated questions
//     //   await dispatch(
//     //     gql_get_task_questions(
//     //       fetchQuestionsObj?.frequencyId,
//     //       fetchQuestionsObj?.sectionId,
//     //       fetchQuestionsObj?.setCurrentQuestion,
//     //       companyId
//     //     )
//     //   );
//     //   // return true;
//     // } catch (err) {
//     //   dispatch(set_snack_bar(true, "Failed to update question"));
//     //   console.log(err);
//     //   dispatch(unset_loader());
//     //   return false;
//     // }
//     // dispatch(unset_loader());
//   };
// }

export function gql_redo_entire_section(fetchQuestionsObj) {
  return async (dispatch, getState) => {
    dispatch(set_loader());

    const surveyId = getState().taskReducer.selectedTask?.survey?._id;
    const vendorId = getState().brmData.vendorBasicDetails?._id;

    try {
      const mutateObj = {
        mutation: GQL_REDO_ENTIRE_SECTION,
        variables: {
          redoEntireSectionQuestionsInput: {
            surveyId,
            frequencyId: fetchQuestionsObj?.frequencyId,
            sectionId: fetchQuestionsObj?.sectionId,
          },
        },
      };

      const result = await client.mutate(mutateObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      // fetch updated questions
      await dispatch(
        gql_get_task_questions(
          fetchQuestionsObj?.frequencyId,
          fetchQuestionsObj?.sectionId,
          fetchQuestionsObj?.setCurrentQuestion,
          vendorId
        )
      );

      dispatch(set_snack_bar(true, "Redo Entire Section"));

      // console.log(result);
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to redo entire section"));
      console.log(err);
      dispatch(unset_loader());
      return false;
    }
    dispatch(unset_loader());
  };
}

export const UpdateSelectedQuestion = (ques_id, survey_id, type) => {
  return (dispatch, getState) => {
    dispatch(set_loader);

    try {
      if (type === "add") {
        const selectedSection = getState().taskReducer.selectedSection;

        const selectedSurvey = selectedSection.find(
          (survey) => survey._id === survey_id
        );

        if (selectedSurvey) {
          const selectedQuestion = selectedSurvey.questions.find(
            (question) => question._id === ques_id
          );

          if (selectedQuestion) {
            dispatch(setSelectedQuestion(selectedQuestion));
          }
        }
      } else {
        dispatch(setSelectedQuestion(null));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export function gql_get_task_questions_for_approver(
  frequencyId,
  sectionId,
  vendorId,
  bankId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GET_TASK_QUESTIONS_FOR_APPROVER,
        variables: {
          getAssignedQuestionsInput: {
            frequencyId,
            sectionId,
            vendorId,
            bankId,
          },
        },
      };

      const result = await client.query(queryObj);
      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(
        setTaskQuestions(
          result.data?.getAssignedQuestionsForApprover[0].questions
        )
      );
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function rest_upload_requested_file(file, requestId, token) {
  return async (dispatch) => {
    dispatch(set_loader());

    // const data = encrypt({
    //   "user-token": token,
    //   file,
    // });

    // console.log(data);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await fetch(
        UNIVERSAL.BASEURL + `/vendors/upload/file_request/${requestId}`,
        {
          method: "POST",
          body: formData,
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.ok) {
        dispatch(set_snack_bar(true, "Failed to submit the file!"));
      } else {
        dispatch(set_snack_bar(true, "File submitted successfully"));
      }

      const data = await res.json();

      console.log("File submitted successfully", data);

      // return data;
    } catch (error) {
      throw error.message;
    }
    dispatch(unset_loader());
  };
}
