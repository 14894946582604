import {
  SET_VENODR_SUB_TAB_NO,
  SET_VENODR_TAB_NO,
  SET_CREATE_VENDOR_IMG,
  SET_SELECTED_VA_SURVEY,
  SET_STEP2_SECTIONS,
  SET_STEP2_SEC_QUESTIONS,
  SET_STEP3_SECTIONS,
  SET_STEP3_SEC_QUESTIONS,
  SET_COMPLETED_SEC_QUES,
  SET_VENDOR_UPLOADED_FILES,
  SET_VENDOR_UPLOADED_FILES_STATUS,
  SET_VENDOR_UPLOADED_FILES_IN_SURVEY,
} from "../../../constants/vendorConstants";
import { uploadFiles } from "../../actions/riskEvaluator/riskEvaluatorActions";

const initialState = {
  vendorTabNo: 0,
  vendorSubTabNo: 0,

  createVendorImg: "",

  selectedVASurvey: {},

  step2Sections: [],
  step2SecQuestions: [],

  step3Sections: null,
  step3SecQuestions: null,

  completedSecQues: [],

  uploadedFiles: [],
  uploadedFilesInSurvey: [],

  uploadedFilesStatus: [],
};

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENODR_TAB_NO:
      return { ...state, vendorTabNo: action.payload };
    case SET_VENODR_SUB_TAB_NO:
      return { ...state, vendorSubTabNo: action.payload };

    case SET_CREATE_VENDOR_IMG:
      return { ...state, createVendorImg: action.payload };

    case SET_SELECTED_VA_SURVEY:
      return { ...state, selectedVASurvey: action.payload };

    case SET_STEP2_SECTIONS:
      return { ...state, step2Sections: action.payload };

    case SET_STEP2_SEC_QUESTIONS:
      return { ...state, step2SecQuestions: action.payload };

    case SET_STEP3_SECTIONS:
      return { ...state, step3Sections: action.payload };

    case SET_STEP3_SEC_QUESTIONS:
      return { ...state, step3SecQuestions: action.payload };

    case SET_COMPLETED_SEC_QUES:
      return { ...state, completedSecQues: action.payload };

    case SET_VENDOR_UPLOADED_FILES:
      return { ...state, uploadedFiles: action.payload };

    case SET_VENDOR_UPLOADED_FILES_IN_SURVEY:
      return { ...state, uploadedFilesInSurvey: action.payload };

    case SET_VENDOR_UPLOADED_FILES_STATUS:
      return { ...state, uploadedFilesStatus: action.payload };
    default:
      return state;
  }
};
export default vendorReducer;
