import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@material-ui/core";

import QuestionTabDrawer from "../../../QuestionTabComponents/QuestionTabDrawer";
import CustomButton from "../../../../../../../../UI/button/button";

const AddRuleDrawer = ({
  open,
  handleClose,
  selectedQues,
  setSelectedQues,
  usingQues,
  selectedSection,
}) => {
  return (
    <QuestionTabDrawer
      headerName={"Question Settings"}
      buttonText={"Add Rules"}
      open={open}
      handleClose={handleClose}
      isFrom={"addRule"}
      selectedQues={selectedQues}
      selectedSection={selectedSection}
    >
      <FormControl
        style={{ width: "100%", padding: "20px" }}
        component="fieldset"
      >
        <FormGroup style={{ gap: "20px" }}>
          <FormControlLabel
            control={
              <Switch
                checked={selectedQues?.mandatory}
                defaultChecked={usingQues?.isMandatory}
                onChange={(e) =>
                  setSelectedQues((preValue) => ({
                    ...preValue,
                    [e.target.name]: e.target.checked,
                  }))
                }
                name="mandatory"
                color="primary"
              />
            }
            label="Make Question Mandatory"
          />
          <FormControlLabel
            control={
              <Switch
                checked={selectedQues?.comment}
                defaultChecked={usingQues?.showComment}
                onChange={(e) =>
                  setSelectedQues((preValue) => ({
                    ...preValue,
                    [e.target.name]: e.target.checked,
                  }))
                }
                name="comment"
                color="primary"
              />
            }
            label="Add comment box for the question"
          />
          <FormControlLabel
            control={
              <TextField
                style={{ width: "100%" }}
                id="outlined-multiline-static"
                label="Question hints"
                multiline
                minRows={10}
                value={selectedQues?.hints}
                defaultValue={usingQues?.hints}
                variant="outlined"
                name="hints"
                onChange={(e) =>
                  setSelectedQues((preValue) => ({
                    ...preValue,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
            }
            // label="Make Section Mandatory"
          />
        </FormGroup>
      </FormControl>
      {/* <CustomButton color="primary" variant="contained">
        Add Rules
      </CustomButton> */}
    </QuestionTabDrawer>
  );
};
export default AddRuleDrawer;
