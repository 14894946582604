import React, { useEffect } from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import CompanyProfile from "./companyProfile";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_vendor_materiality_scores } from "../../../redux/actions/vendorActions/vendorAction";
import Contoversies from "../controversiesTab/controversies";
import {
  Box,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { TopicData, TopicHeader } from "./esgComponents";

function VendorMaterialityScore() {
  const dispatch = useDispatch();

  const { vendorBasicDetails, vendorMaterialityScores } = useSelector(
    (state) => state.brmData
  );

  const storedVendorId = localStorage.getItem("vendorId");

  useEffect(() => {
    dispatch(
      gql_get_vendor_materiality_scores(
        vendorBasicDetails?._id || storedVendorId
      )
    );
  }, []);

  const nameFormat = (name) => {
    if (name === "vendorCategorization") {
      return "Vendor Categorization";
    } else if (name === "vendorMateriality") {
      return "Vendor Materiality";
    } else if (name === "vendorPerformance") {
      return "Vendor Performance";
    }
  };

  const defaultData = {
    vendorCategorization: {
      comment: "Some Comment",
      score: 0,
    },
    vendorMateriality: {
      comment: "",
      score: 0,
    },
    vendorPerformance: {
      comment: "",
      score: 0,
    },
  };

  const scoresDefaultData = {
    vendorCategorization: {
      "Importance and Impact of Vendor Services": 0,
      "Financial and Operational Impact": 0,
      "Risk of Service Disruption": 0,
      "Cost Considerations": 0,
      "Vendor Alternatives": 0,
      "Compliance and Contractual Agreements": 0,
    },
    vendorMateriality: {
      Customer: 0,
      Regulatory: 0,
      Exposure: 0,
      Concentration: 0,
      "Brand Reputation": 0,
      "Criticality  (over-riding factors)": 0,
    },
    vendorPerformance: {
      "Adherence to Bank's Instructions": 0,
      "Vendor Performance Review": 0,
      "Vendor Service Evaluation": 0,
      "Technical / Product Support": 0,
      "Compliance with On Boarding Terms & Conditions": 0,
    },
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {(vendorMaterialityScores?.vendorMateriality || defaultData) &&
        Object?.entries(vendorMaterialityScores ?? defaultData)?.map(
          ([key, value], index) => (
            <Grid md={4}>
              <BlueBackgroundCard heading={nameFormat(key)} />
              <Grid justifyContent="space-around">
                <Grid item md={5}>
                  <CompanyProfile
                    name={nameFormat(key)}
                    scores={{ score: value?.score }}
                    label={value?.comment}
                    customSize={250}
                    hideBottom
                  />
                </Grid>
                <Grid item md={5}>
                  <div
                    key={index}
                    style={{
                      width: "88%",
                      borderRadius: 8,
                      backgroundColor: "#FBFBFB",
                      marginTop: "20px",
                    }}
                  >
                    <TopicHeader
                      topic={nameFormat(key)}
                      score={value?.score}
                      noIcon
                    />
                    {(value?.sectionWiseScore || scoresDefaultData) &&
                      Object.entries(
                        value?.sectionWiseScore ?? scoresDefaultData[key]
                      )?.map(([section, score], i) => (
                        <TopicData key={i} topic={section} score={score} />
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
    </Grid>
  );
}

export default VendorMaterialityScore;
