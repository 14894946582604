import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { RiListCheck3 } from "react-icons/ri";

import SearchTextField from "../../UI/textfield/searchTextField";
import DynamicPagination from "../../UI/pagination/DynamicPagination";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_va_banks_list } from "../../../redux/actions/vendorActions/vendorAction";
import React, { useCallback } from "react";
import debounce from "lodash.debounce";

const TaskHeader = ({ hideSearch, hidePagination }) => {
  const dispatch = useDispatch();
  const { vaBanksList } = useSelector((state) => state?.brmData);
  const [searchChange, setSearchChange] = React.useState("");

  const handlePageChange = (page) => {
    dispatch(gql_get_va_banks_list("Assigned", 6, page));
  };

  const fetchIsinResults = useCallback(
    debounce((searchQuery) => {
      if (searchQuery) {
        dispatch(gql_get_va_banks_list("Assigned", 6, 1, searchQuery));
      } else if (searchQuery === "") {
        dispatch(gql_get_va_banks_list("Assigned", 6, 1));
      }
    }, 500),
    []
  );

  const handleSearchQuery = (searchQuery) => {
    fetchIsinResults(searchQuery);
    setSearchChange(searchQuery);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {/* My Task Text */}
      <Typography
        style={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "#15314E",
        }}
      >
        My Task
      </Typography>

      {/* Search Field */}
      <Box display={"flex"} sx={{ gap: 20 }} alignItems={"center"}>
        {hideSearch && (
          <SearchTextField
            searchChange={searchChange}
            handleSearchQuery={handleSearchQuery}
          />
        )}

        {/* Pagination */}
        {hidePagination && (
          <DynamicPagination
            totalCount={vaBanksList?.total}
            onPageChange={handlePageChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default TaskHeader;
