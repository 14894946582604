import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "../selfAssessment/selfAssessmentResponse";
import {
  Box,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  gql_get_linkage_survey_questions,
  gql_get_linkage_survey_sections,
  gql_submit_linkage_survey_questions,
} from "../../../redux/actions/vendorActions/vendorAction";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_LINKAGE_SURVEY_QUESTIONS,
  SET_LINKAGE_SURVEY_SECTIONS,
} from "../../../constants/brmConstants";
import RadioButton from "../../UI/radioButton/radioButton";

const responseStyles = makeStyles((theme) => ({
  questionCont: {
    border: "none",
    borderRadius: 5,

    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    padding: "16px 0px",
  },
  question: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    border: "1px solid #E9E9E9",
    backgroundColor: "#FCFCFC",
    borderRadius: "6px",
    marginLeft: 5,
    width: "100%",
  },
  weightage: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    color: "#3374B9",
    border: "1px solid #EBF1F8",
    backgroundColor: "#EBF1F8",
    borderRadius: "16px",
    marginLeft: 5,
    width: "18%",
    textAlign: "center",
    height: "fit-content",
  },
  userName: {
    padding: 6,
    margin: "8px 5px",
    fontSize: 13,

    borderRadius: 5,
    cursor: "pointer",
  },
}));

function VendorMateriality({ linkageSurveyId, vendorSubTabNo }) {
  const classes = responseStyles();
  const scroll = useRef();

  const [sectionSelect, setSectionSelect] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const dispatch = useDispatch();

  const {
    linkageSurveySections,
    vendorBasicDetails,
    linkageSurveyQuestions,
    linkageSurveys,
  } = useSelector((state) => state.brmData);

  const storedVendorId = localStorage.getItem("vendorId");

  useEffect(() => {
    scroll?.current?.scrollTo(0, 0);
  }, [sectionSelect]);

  const handleSelectedSection = (sectionId) => {
    dispatch(
      gql_get_linkage_survey_questions(
        sectionId,
        vendorBasicDetails?._id || storedVendorId
      )
    );

    setAnswers([]);
    setSelectedOption([]);
  };

  const surveyId = linkageSurveys?.find((survey) =>
    survey?.name?.toLowerCase()?.includes("vendor materiality")
  )?._id;

  useEffect(() => {
    dispatch(gql_get_linkage_survey_sections(surveyId));

    return () => {
      dispatch({ type: SET_LINKAGE_SURVEY_SECTIONS, payload: [] });
    };
  }, [linkageSurveys]);

  useEffect(() => {
    setSectionSelect({
      id: linkageSurveySections[0]?._id,
      title: linkageSurveySections[0]?.title,
    });

    if (sectionSelect) {
      dispatch(
        gql_get_linkage_survey_questions(
          linkageSurveySections[0]?._id,
          vendorBasicDetails?._id || storedVendorId
        )
      );
    }

    return () => {
      dispatch({ type: SET_LINKAGE_SURVEY_QUESTIONS, payload: [] });
    };
  }, [linkageSurveySections]);

  const handleUpdate = () => {
    const input = {
      vendorId: vendorBasicDetails?._id || storedVendorId,
      answers: answers,
    };
    dispatch(gql_submit_linkage_survey_questions(input));
  };

  const handleOptionChange = (e, question, index) => {
    const ansCopy = [...answers];

    ansCopy[index] = {
      ...ansCopy[index],
      answer: e.target.value,
      questionId: question?._id,
    };

    const filteredAnswers = ansCopy.filter((item) => item !== null);

    setAnswers(filteredAnswers);
    setSelectedOption(ansCopy);
  };

  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Vendor Materiality{" "}
            <span style={{ fontSize: 14, fontWeight: 500 }}>
              (Please save changes before moving to next materiality section!)
              <span style={{ fontSize: 14, fontWeight: 500, color: "red" }}>
                *
              </span>
            </span>
          </Typography>
        </div>

        <div>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "16px",
              color: "#3374B9",
              marginRight: 10,
              cursor: "pointer",
            }}
            onClick={handleUpdate}
          >
            Save
          </Typography>
        </div>
      </div>
      {linkageSurveySections?.length > 0 && (
        <Wrapper
          users={linkageSurveySections}
          sectionSelect={sectionSelect}
          setSectionSelect={setSectionSelect}
          hidesearch
          handleSelectedSection={handleSelectedSection}
        >
          <div
            className="scroll"
            ref={scroll}
            style={{
              height: "59vh",
              paddingLeft: "20px",
              width: "80%",
            }}
          >
            {linkageSurveyQuestions.map((question, index) => (
              <div className={classes.questionCont}>
                <div style={{ display: "flex", width: "100%" }}>
                  <Typography className={classes.question}>
                    {question.name}
                  </Typography>
                  {question?.weight && (
                    <Typography className={classes.weightage}>
                      {question?.weight} Weightage
                    </Typography>
                  )}
                </div>
                <div
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <RadioGroup
                    value={selectedOption[index]?.answer || question?.answer}
                    onChange={(e) => handleOptionChange(e, question, index)}
                  >
                    {question?.options.map((option, i) => (
                      <Box display={"flex"} key={i}>
                        <FormControlLabel
                          key={option?.option}
                          value={option?.option}
                          control={<RadioButton color="primary" />}
                          label={option?.option}
                          // onClick={() => handleOption(option)}
                        />
                      </Box>
                    ))}
                  </RadioGroup>
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      )}
    </div>
  );
}

export default VendorMateriality;
