// !------------- A children can be passed (no other things are needed) -------------!

import React, { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Breadcrumbs, Typography, Box } from "@material-ui/core";

import { IoIosArrowForward } from "react-icons/io";
import SearchTextField from "../../UI/textfield/searchTextField";
import DynamicPagination from "../../UI/pagination/DynamicPagination";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_va_banks_list } from "../../../redux/actions/vendorActions/vendorAction";
import debounce from "lodash.debounce";

export const ActiveCrumbStyle = (isActive) => {
  return {
    color: isActive ? "#15314E" : "",
    fontWeight: isActive ? 600 : 400,
    fontSize: "16px",
    lineHeight: "24px",
    cursor: isActive ? "default" : "pointer",
    textTransform: "capitalize",
  };
};

const MainHeader = ({
  children,
  mainCrumb,
  hidePagination,
  hideSearch,
  isFrom,
}) => {
  const location = useLocation();
  const history = useHistory();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const startIndex = 1; // Skip the first segment
  const [searchChange, setSearchChange] = React.useState("");

  const { vaBanksList } = useSelector((state) => state?.brmData);

  const dispatch = useDispatch();
  console.log(isFrom);

  const handleClick = (index) => {
    const routeTo = `/${pathnames.slice(0, index + startIndex + 1).join("/")}`;
    history.push(routeTo);
  };

  const fetchIsinResults = useCallback(
    debounce((searchQuery) => {
      if (searchQuery) {
        dispatch(gql_get_va_banks_list("Shared", 6, 1, searchQuery));
      } else if (searchQuery === "") {
        dispatch(gql_get_va_banks_list("Shared", 6, 1));
      }
    }, 500),
    []
  );

  const handleSearchQuery = (searchQuery) => {
    fetchIsinResults(searchQuery);
    setSearchChange(searchQuery);
  };

  const handlePageChange = (page) => {
    dispatch(gql_get_va_banks_list("Shared", 6, page));
  };
  // Create an array to store Typography components
  const breadcrumbs = [];

  breadcrumbs.push(
    <Typography
      key="main"
      color="inherit"
      style={ActiveCrumbStyle(pathnames.length === 1)}
      onClick={() => {
        if (pathnames.length !== 1) history.push(`/${pathnames[0]}`);
      }}
    >
      {mainCrumb || "Survey"}
    </Typography>
  );

  pathnames.slice(startIndex).forEach((name, index) => {
    const isLast = index + 1 === pathnames.length - 1;
    breadcrumbs.push(
      <Typography
        key={index}
        color={"inherit"}
        style={ActiveCrumbStyle(isLast)}
        onClick={() => {
          if (!isLast) handleClick(index);
        }}
      >
        {name.replace(/_/g, " ")}
      </Typography>
    );
  });

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 20px",
      }}
    >
      {/* Breadcrumbs */}
      <Breadcrumbs
        style={{
          pointerEvents:
            pathnames[pathnames.length - 1] === "completed" ? "none" : "",
        }}
        aria-label="breadcrumb"
        separator={
          <IoIosArrowForward
            style={{
              color: "#A1BFDF",
            }}
          />
        }
      >
        {breadcrumbs} {/* Pass the array as children */}
      </Breadcrumbs>

      {/* other options */}

      <Box display={"flex"} sx={{ gap: 20 }} alignItems={"center"}>
        {hideSearch && (
          <SearchTextField
            searchChange={searchChange}
            handleSearchQuery={handleSearchQuery}
          />
        )}

        {hidePagination && (
          <DynamicPagination
            totalCount={vaBanksList?.total}
            onPageChange={handlePageChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default MainHeader;
