import {
  SET_ROI_QUESTIONS,
  SET_ROI_RESPONSES,
  SET_ROI_SECTIONS,
} from "../../../constants/roiConstants";
import {
  GQL_GET_ROI_QUESTIONS,
  GQL_GET_ROI_RESPONSES,
  GQL_GET_ROI_SECTIONS,
} from "../../graphql/gql_RegisterOfInfoQueries";
import { client } from "../../store/store";
import { set_loader, unset_loader } from "../loader/loader_action";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";

const setRoiSections = (data) => {
  return { type: SET_ROI_SECTIONS, payload: data };
};

export function gqlGetRoiSections(boolean) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const objQuery = {
        query: GQL_GET_ROI_SECTIONS,
        variables: { create: boolean },
      };

      const result = await client.query(objQuery);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(objQuery, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);
      dispatch(setRoiSections(result.data.LinkageROISections));
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}
