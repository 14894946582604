import {
  Box,
  Grid,
  Icon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import pdfImg from "../../../images/PDF.png";
import user from "../../../images/userIconBlue.png";
import doc from "../../../images/fileIcon.svg";
import clock from "../../../images/clock.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import EditOutlined from "@material-ui/icons/EditOutlined";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import NearMeOutlinedIcon from "@material-ui/icons/NearMeOutlined";
import { gql_send_to_magpie } from "../../../redux/actions/vendorActions/vendorAction";

const PublicReports = React.memo(
  ({
    handleFileRemove,
    publicDocuments,
    isFrom,
    handleSelectedDoc,
    doraComplianceFiles,
    ...props
  }) => {
    const dispatch = useDispatch();

    const [showPopper, setShowPopper] = useState(null);
    const [fileIndex, setFileIndex] = useState(null);
    const anchorRef = useRef(null);

    const { vendorBasicDetails } = useSelector((state) => state.brmData);

    useEffect(() => {
      if (!anchorRef && anchorRef.current) {
        // Focus on the input element when showInput is true
        anchorRef.current.focus();
      }
    }, [showPopper]);

    function handleClick(e, index) {
      setFileIndex(index);
      setShowPopper(e.currentTarget);
    }

    function handleClose() {
      setShowPopper(null);
    }

    const shortenFileName = (fileName, maxLength) => {
      if (fileName?.length <= maxLength) {
        return fileName;
      }

      const headLength = Math.floor((maxLength - 3) / 2);
      const tailLength = Math.ceil((maxLength - 3) / 2);

      const head = fileName?.substring(0, headLength);
      const tail = fileName?.substring(fileName?.length - tailLength);

      return fileName === undefined ? "-" : `${head}...${tail}`;
    };

    const status = (value) => {
      if (value) {
        return "Completed";
      } else {
        return "Incomplete";
      }
    };
    // const status = (value) => {
    //   if (value === "UPLOADING") {
    //     return "Uploading";
    //   } else if (value === "COMPLETED") {
    //     return "Completed";
    //   } else {
    //     return "Failed";
    //   }
    // };

    const statusColor = (value, type) => {
      if (type === "value") {
        return !value ? "#8C7038" : "#154328";
      }
      if (type === "bg") {
        return !value ? "#FFEFD0" : "#BFE2CD";
      }
    };
    // const statusColor = (value, type) => {
    //   if (type === "value") {
    //     return value === "UPLOADING"
    //       ? "#8C7038"
    //       : value === "COMPLETED"
    //       ? "#154328"
    //       : "#52231D";
    //   }
    //   if (type === "bg") {
    //     return value === "UPLOADING"
    //       ? "#FFEFD0"
    //       : value === "COMPLETED"
    //       ? "#BFE2CD"
    //       : "#EDCAC6";
    //   }
    // };

    function openInNewTab(url) {
      window.open(url, "_blank").focus();
    }

    const handleFileSend = (type, file) => {
      let sendToMagpieInput;

      if (isFrom === "surveyDocuments") {
        sendToMagpieInput = {
          file: file?.document,
          isin: vendorBasicDetails?.isin,
          type: type,
        };
      } else {
        sendToMagpieInput = {
          file: file?.file,
          isin: vendorBasicDetails?.isin,
          type: type,
        };
      }

      console.log(file?.document);
      if (type === "Contract") {
        dispatch(gql_send_to_magpie(sendToMagpieInput));

        console.log(sendToMagpieInput);
      } else if (type === "Incident_Policy") {
        dispatch(gql_send_to_magpie(sendToMagpieInput));

        console.log(sendToMagpieInput);
      } else if (type === "TSPS_Policy") {
        dispatch(gql_send_to_magpie(sendToMagpieInput));

        console.log(sendToMagpieInput);
      } else if (type === "IS_Policy") {
        dispatch(gql_send_to_magpie(sendToMagpieInput));

        console.log(sendToMagpieInput);
      }
    };

    // console.log;

    return (
      <div
        style={{
          marginTop: isFrom === "doraDocuments" ? 5 : 18,
          marginBottom: isFrom === "doraDocuments" ? "50px" : 0,
          height: isFrom === "doraDocuments" ? "fit-content" : "60vh",
        }}
      >
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            marginTop: "10px",
            backgroundColor: "#EBF1F8",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <Grid item md={3} xs={3}>
            <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
              Documents
            </Typography>
          </Grid>
          {isFrom === "surveyDocuments" && <Grid item md={2} xs={2}></Grid>}

          {isFrom === "companyDocs" ? (
            <Grid item md={2} xs={2} align="center">
              <Typography style={{ fontSize: "13px" }}>
                Document Type
              </Typography>
            </Grid>
          ) : (
            <Grid item md={2} xs={2} align="center"></Grid>
          )}

          <Grid item md={2} xs={2} align="center">
            <Typography style={{ fontSize: "13px" }}>Uploaded By</Typography>
          </Grid>
          <Grid item md={2} xs={2} align="center">
            <Typography style={{ fontSize: "13px" }}>Upload Time</Typography>
          </Grid>

          {(isFrom === "companyDocs" || isFrom === "doraDocuments") && (
            <Grid item md={2} xs={2} align="center">
              <Typography style={{ fontSize: "13px" }}>Status</Typography>
            </Grid>
          )}

          <Grid item md={1} xs={1} align="center">
            <Typography style={{ fontSize: "13px" }}>
              {isFrom === "doraDocuments" ? "Explore" : "Action"}
            </Typography>
          </Grid>
        </Grid>
        {/* {console.log(props)} */}
        <Box>
          {publicDocuments &&
            publicDocuments?.length > 0 &&
            publicDocuments?.map((file, index) => (
              <Grid
                key={index}
                container
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "10px",
                  padding: "5px",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  borderRadius: "2px",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  md={3}
                  xs={3}
                  container
                  style={{ flexWrap: "nowrap", alignItems: "center" }}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                    src={pdfImg}
                    alt="pdf"
                  />
                  <div>
                    <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                      {shortenFileName(
                        file?.file_name ||
                          file?.document?.name ||
                          file?.file?.name,
                        25
                      ) || "publication name"}
                    </Typography>
                    {isFrom === "companyDocs" && (
                      <Typography
                        style={{
                          fontSize: "12px",
                          marginLeft: "5px",
                          color: "grey",
                        }}
                      >
                        {file.year || ""}
                      </Typography>
                    )}
                  </div>
                </Grid>
                {isFrom === "surveyDocuments" && (
                  <Grid item md={2} xs={2}></Grid>
                )}
                {isFrom === "companyDocs" && (
                  <Grid
                    item
                    md={2}
                    xs={2}
                    container
                    align="right"
                    style={{ flexWrap: "nowrap", justifyContent: "center" }}
                  >
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={doc}
                      alt="doc"
                    />
                    <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                      {file?.type?.split("_").join(" ").toUpperCase() ||
                        "Magpie"}
                    </Typography>
                  </Grid>
                )}

                {isFrom !== "companyDocs" && (
                  <Grid item md={2} xs={2} container></Grid>
                )}
                <Grid
                  item
                  md={2}
                  xs={2}
                  // container
                  align="center"
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {(file?.user_name || file?.uploadedBy?.name) && (
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "0px",
                      }}
                      src={user}
                      alt="pdf"
                    />
                  )}
                  <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                    {file?.user_name || file?.uploadedBy?.name || "-"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={2}
                  container
                  align="center"
                  style={{ flexWrap: "nowrap", justifyContent: "center" }}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                    src={clock}
                    alt="pdf"
                  />
                  <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                    {moment(file?.uploaded_on || file?.uploadedAt).format("ll")}
                  </Typography>
                </Grid>

                {isFrom === "companyDocs" && (
                  <Grid item md={2} xs={2} align="center">
                    <Typography
                      style={{
                        color: statusColor(
                          file?.KPI_process_completed || file?.inprogress
                            ? false
                            : true,
                          "value"
                        ),

                        backgroundColor: statusColor(
                          file?.KPI_process_completed || file?.inprogress
                            ? false
                            : true,
                          "bg"
                        ),
                        borderRadius: 12,
                        fontSize: 12,
                        padding: "3px 8px",
                        width: "fit-content",
                      }}
                    >
                      {status(
                        file?.KPI_process_completed || file?.inprogress
                          ? false
                          : true
                      )}
                    </Typography>
                  </Grid>
                )}

                {isFrom === "doraDocuments" && (
                  <Grid item md={2} xs={2} align="center">
                    <Typography
                      style={{
                        color: file?.status
                          ? statusColor(
                              file?.status === "Pass" ? true : false,
                              "value"
                            )
                          : "",

                        backgroundColor: file?.status
                          ? statusColor(
                              file?.status === "Pass" ? true : false,
                              "bg"
                            )
                          : "transparent",
                        borderRadius: 12,
                        fontSize: 12,
                        padding: "3px 40px",
                        width: "fit-content",
                        fontWeight: 500,
                      }}
                    >
                      {file?.status || "-"}
                    </Typography>
                  </Grid>
                )}

                {isFrom !== "doraDocuments" && (
                  <Grid item md={1} xs={1} align="center">
                    <Typography
                      // onClick={() => fileRemove(file)}
                      style={{ fontSize: "13px" }}
                      onClick={(e) => handleClick(e, index)}
                      ref={anchorRef}
                    >
                      {!file?.inprogress && (
                        <Icon
                          style={{
                            cursor: "pointer",
                            color: "#3374b9",
                            fontSize: 20,
                          }}
                        >
                          more_vert
                        </Icon>
                      )}
                    </Typography>

                    {showPopper && fileIndex === index && (
                      <div
                        style={{
                          padding: "0 10px",
                          color: "#3374b9",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        <Menu
                          id="simple-menu"
                          anchorEl={showPopper}
                          keepMounted
                          open={showPopper}
                          onClose={handleClose}
                          MenuListProps={{
                            style: {
                              paddingTop: 0,
                              paddingBottom: 0,
                              margin: 0,
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              openInNewTab(
                                file?.file_url ||
                                  file?.document?.url ||
                                  file?.file?.url
                              )
                            }
                            style={{
                              // padding: "0 10px",
                              color: "#3374b9",
                            }}
                          >
                            {/* <GetAppOutlinedIcon
                            style={{ marginRight: "5px", color: "#999" }}
                          /> */}
                            <ListItemText
                              primary="Download"
                              primaryTypographyProps={{
                                style: { fontSize: "14px" },
                              }}
                            />
                          </MenuItem>
                          {(file?.type === "Contract" ||
                            file?.type === "Incident_Policy" ||
                            file?.type === "TSPS_Policy" ||
                            file?.type === "IS_Policy") && (
                            <Box>
                              {[
                                {
                                  name: "Send to Magpie (Contract)",
                                  type: "Contract",
                                },
                                {
                                  name: "Send to Magpie (Incident Policy)",
                                  type: "Incident_Policy",
                                },
                                {
                                  name: "Send to Magpie (TSPS Policy)",
                                  type: "TSPS_Policy",
                                },
                                {
                                  name: "Send to Magpie (IS Policy)",
                                  type: "IS_Policy",
                                },
                              ].map((item) => (
                                <MenuItem
                                  onClick={() =>
                                    handleFileSend(item?.type, file)
                                  }
                                  style={{
                                    padding:
                                      file?.type === item?.type
                                        ? 0
                                        : "5px 18px",
                                    color: "#3374b9",
                                  }}
                                >
                                  {/* <NearMeOutlinedIcon
                            style={{ marginRight: "5px", color: "#999" }}
                          /> */}
                                  {file?.type !== item?.type && (
                                    <ListItemText
                                      primary={item?.name}
                                      primaryTypographyProps={{
                                        style: { fontSize: "14px" },
                                      }}
                                    />
                                  )}
                                </MenuItem>
                              ))}
                            </Box>
                          )}

                          {isFrom === "surveyDocuments" && (
                            <Box>
                              {[
                                {
                                  name: "Send to Magpie (Contract)",
                                  type: "Contract",
                                },
                              ].map((item) => (
                                <MenuItem
                                  onClick={() =>
                                    handleFileSend(item?.type, file)
                                  }
                                  style={{
                                    // padding: "0 10px",
                                    color: "#3374b9",
                                  }}
                                >
                                  {/* <NearMeOutlinedIcon
                            style={{ marginRight: "5px", color: "#999" }}
                          /> */}

                                  <ListItemText
                                    primary={item?.name}
                                    primaryTypographyProps={{
                                      style: { fontSize: "14px" },
                                    }}
                                  />
                                </MenuItem>
                              ))}
                            </Box>
                          )}
                        </Menu>
                      </div>
                    )}
                  </Grid>
                )}

                {isFrom === "doraDocuments" && (
                  <Grid item md={1} xs={1} align="center">
                    <KeyboardArrowRightRoundedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleSelectedDoc(
                          file?._id,
                          file?.file?.url,
                          file?.file?.name
                        )
                      }
                    />
                  </Grid>
                )}
                {/* <Grid item md={1} xs={1} align="center">
      <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
    </Grid> */}
              </Grid>
            ))}
        </Box>
      </div>
    );
  }
);

export default PublicReports;
