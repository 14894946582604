import React, { useState } from "react";

import StepLeft from "../../stepLayout/left/StepLeft";
import { Box, Typography } from "@material-ui/core";

import CustomButton from "../../../../../UI/button/button";
import SurveyList from "./SurveyList";
import AssignDrawer from "../assignDrawer/AssignDrawer";

import { MdOutlineAccessTime } from "react-icons/md";
import { BiDockLeft } from "react-icons/bi";

function Step2Left({
  isFrom,
  surveyList,
  handleSelectSection,
  handleStatus,
  frequencyType,
  handleStatusChangeOnAssign,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <StepLeft>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
          Survey Listing
        </Typography>
        {isFrom !== "completedSurvey" && (
          <CustomButton
            variant="text"
            color="primary"
            onClick={() => setIsDrawerOpen(true)}
          >
            Assign Section
          </CustomButton>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "flex-start",
          gap: 10,
          alignItems: "center",
        }}
      >
        <MdOutlineAccessTime size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {frequencyType}
        </Typography>
        <BiDockLeft size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          {surveyList?.length} Sections
        </Typography>
      </Box>
      <Box style={{ overflow: "scroll", height: "80%" }}>
        <SurveyList
          surveyList={surveyList}
          handleSelectSection={handleSelectSection}
        />
      </Box>
      {isDrawerOpen && (
        <AssignDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={() => {
            setIsDrawerOpen(false);
            // handleStatus("In Progress");
          }}
          optList={surveyList}
          drawerFor={"sections"}
          handleStatusChangeOnAssign={handleStatusChangeOnAssign}
        />
      )}
    </StepLeft>
  );
}

export default Step2Left;
