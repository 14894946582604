import { Box, Typography } from "@material-ui/core";

import QuestionsSection from "./QuestionsSection";
import CustomButton from "../../../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import { gql_submit_answer } from "../../../../../../../redux/actions/taskActions/TaskAction";

const SpecificQuestion = ({
  currentQuestion,
  activeSection,
  setCurrentQuestion,
  handleSelectQuestion,
}) => {
  // from redux
  const dispatch = useDispatch();
  const { selectedTask } = useSelector((state) => state.taskReducer);
  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  const handleSubmitQuestion = async () => {
    // to get new list of questions
    const fetchQuestionsObj = {
      frequencyId: selectedTask?.frequencyId,

      sectionId: activeSection?._id,
      setCurrentQuestion,
    };

    const submittedAnswer =
      currentQuestion?.questionType === "MULTICHOICE"
        ? currentQuestion?.submittedAnswer
            ?.filter((answer) => answer.checked)
            ?.map((answer) => {
              const {
                checked,
                justification,
                description,
                documentUpload,
                ...rest
              } = answer;
              return rest;
            })
        : currentQuestion?.questionType === "TABLE"
        ? currentQuestion?.tableRows
        : [currentQuestion?.submittedAnswer];

    let submitAnswerObj;

    if (currentQuestion?.questionType === "TABLE") {
      submitAnswerObj = {
        questionId: currentQuestion?.questionId, // question id
        selectedAnswer: [], // answers
        tableRows: submittedAnswer, // answers
        approverIds: currentQuestion?.approverIds, // approver ids
        chatRoomId: currentQuestion?.chatRoomId,
        frequencyId: selectedTask?.frequencyId,
      };
    } else {
      submitAnswerObj = {
        questionId: currentQuestion?.questionId, // question id
        selectedAnswer: submittedAnswer, // answers
        tableRows: [], // answers
        approverIds: currentQuestion?.approverIds, // approver ids
        chatRoomId: currentQuestion?.chatRoomId,
        frequencyId: selectedTask?.frequencyId,
      };
    }

    // dispatch action
    if (login?.user_type === "BSA") {
      const isSubmitted = await dispatch(
        gql_submit_answer(
          submitAnswerObj,
          fetchQuestionsObj,
          currentQuestion?.questionType,
          login?.bankId,
          selectedBankDetailsForVa?._id
        )
      );
      if (!isSubmitted) {
        handleSelectQuestion(null);
      }
    } else {
      const isSubmitted = await dispatch(
        gql_submit_answer(
          submitAnswerObj,
          fetchQuestionsObj,
          currentQuestion?.questionType,
          selectedBankDetailsForVa?._id,
          login?.vendorId
        )
      );
      if (!isSubmitted) {
        handleSelectQuestion(null);
      }
    }
  };

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      style={{ gap: 15 }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        style={{ gap: 5, backgroundColor: "#EBF1F8" }}
        p={2}
      >
        {/* title text */}
        <Typography
          style={{
            color: "#15314E",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {activeSection === 0 ? "Company Profile" : "Information Security"}
        </Typography>

        {/* sub text */}
        <Typography
          style={{
            color: "#245283",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {activeSection === 0
            ? "Shape the narrative of your company's sustainability journey by providing detailed insights in our Company Profile Section, capturing the essence of your environmental, social, and governance practices."
            : "Fortify your organization's digital resilience by meticulously addressing key aspects of Information Security in our section, ensuring robust measures are in place to protect sensitive data and bolster trust."}
        </Typography>
      </Box>
      <Box p={2}>
        <QuestionsSection
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      </Box>

      <Box
        style={{
          display: "flex",
          position: "absolute",
          right: 10,
          bottom: 10,
          gap: 10,
        }}
      >
        <CustomButton
          variant={"text"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            handleSelectQuestion(null);
          }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant={"contained"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={handleSubmitQuestion}
        >
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};
export default SpecificQuestion;
