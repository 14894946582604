import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector } from "react-redux";

import CustomButton from "../../../../UI/button/button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 20,
  },
  button: {
    marginRight: theme.spacing(2),
    minWidth: "100px",
    textTransform: "capitalize",
  },
}));

export default function SurveyButton({
  activeStep,
  handleBack,
  handleNext,
  steps,
  completedSecQues,
  isFrom,
}) {
  const classes = useStyles();
  const history = useHistory();

  const { step2Sections, step3Sections } = useSelector(
    (state) => state.vendorReducer
  );

  const numberOfTotalQuestions = step2Sections?.reduce(
    (cur, acc) => acc?.noOfQuestions + cur,
    0
  );

  // console.log(numberOfTotalQuestions, step3Sections?.totalQuestionsCompleted);

  const goBack = () => {
    history.goBack(); // This function takes you back to the previous route
  };

  return (
    <div className={classes.root}>
      {activeStep < steps.length && (
        <CustomButton
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          color="primary"
          // style={{ color: "#3374B9" }}
        >
          Previous
        </CustomButton>
      )}

      {activeStep === steps.length - 1 && isFrom === "completedSurvey"
        ? false
        : activeStep < steps.length && (
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              // style={{ background: "#3374B9" }}
              disabled={
                activeStep === steps.length - 1 &&
                numberOfTotalQuestions !==
                  step3Sections?.totalQuestionsCompleted
              }
            >
              {activeStep === steps.length - 1 ? "Submit Response" : "Proceed"}
            </CustomButton>
          )}
      {activeStep === steps.length && (
        <CustomButton
          variant="contained"
          color="primary"
          onClick={goBack}
          className={classes.button}
          // style={{ background: "#3374B9" }}
        >
          Go to Survey listing
        </CustomButton>
      )}
    </div>
  );
}
