import React, { useEffect, useState } from "react";
import StepLayOut from "../stepLayout";
import Step2Left from "./left/Step2Left";
import Step2Right from "./right/Step2Right";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_va_survey_sections,
  gql_get_va_section_questions,
} from "../../../../../redux/actions/vendorActions/vendorAction";

function SurveyStep2({ isFrom }) {
  // from redux
  const dispatch = useDispatch();
  const { step2Sections, step2SecQuestions, selectedVASurvey } = useSelector(
    (state) => state.vendorReducer
  );

  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  // states
  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionQuestions, setSectionQuestions] = useState(step2SecQuestions);
  // const [sectionQuestions, setSectionQuestions] = useState({});

  const [status, setStatus] = useState("yet to assign");

  // --------------- Inner Functions ---------------

  // when section is selected on left side
  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };

  // const handleStatus = (status) => {
  //   setStatus(status);
  // };

  // ---------------------------------------------

  // --------------- useEffects() ---------------

  // on page load useEffect
  useEffect(() => {
    // get left sections
    dispatch(gql_get_va_survey_sections(selectedVASurvey));
  }, []);

  // on section change
  useEffect(() => {
    // update Section based Question when selected section changes
    if (selectedSection?._id) {
      if (login?.user_type === "BSA") {
        dispatch(
          gql_get_va_section_questions(
            selectedSection?._id,
            selectedVASurvey?.frequencyId,
            setSectionQuestions,
            login?.bankId,
            selectedBankDetailsForVa?._id
          )
        );
      } else {
        dispatch(
          gql_get_va_section_questions(
            selectedSection?._id,
            selectedVASurvey?.frequencyId,
            setSectionQuestions,
            selectedBankDetailsForVa?._id,
            login?.vendorId
          )
        );
      }
    }
  }, [selectedSection]);

  const handleStatusChangeOnAssign = () => {
    if (login?.user_type === "BSA") {
      dispatch(
        gql_get_va_section_questions(
          selectedSection?._id,
          selectedVASurvey?.frequencyId,
          setSectionQuestions,
          login?.bankId,
          selectedBankDetailsForVa?._id
        )
      );
    } else {
      dispatch(
        gql_get_va_section_questions(
          selectedSection?._id,
          selectedVASurvey?.frequencyId,
          setSectionQuestions,
          selectedBankDetailsForVa?._id,
          login?.vendorId
        )
      );
    }
  };

  // --------------------------------------------

  return (
    <StepLayOut
      left={
        <Step2Left
          isFrom={isFrom}
          surveyList={step2Sections}
          handleSelectSection={handleSelectSection}
          // handleStatus={handleStatus}
          // sectionQuestions={sectionQuestions}
          frequencyType={selectedVASurvey?.frequencyType}
          handleStatusChangeOnAssign={handleStatusChangeOnAssign}
        />
      }
      right={
        <Step2Right
          isFrom={isFrom}
          sectionQuestions={sectionQuestions}
          // status={status}
          // handleStatus={handleStatus}
          surveyList={step2Sections}
          selectedSection={selectedSection}
          handleStatusChangeOnAssign={handleStatusChangeOnAssign}
        />
      }
    />
  );
}

export default SurveyStep2;
