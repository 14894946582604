import React from "react";
import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Icon,
  Collapse,
  Menu,
  MenuItem,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DynamicTable from "../../../../../UI/dynamicTable/DynamicTable";
import CustomButton from "../../../../../UI/button/button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql_update_question_status } from "../../../../../../redux/actions/vendorActions/vendorAction";
import QuestionBody from "../../../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";

const textStyle = (type) => {
  let width = "2em";
  if (type === "main") {
    width = "50em";
  }

  return {
    color: "#15314E",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    width: width,
    borderBottom: "none",
    padding: "10px",
  };
};

const headings = [
  { name: "Que.No", showSort: false },
  { name: "Questions", showSort: false },
  { name: "Status", showSort: false },
  { name: "View", showSort: false },
];

const options = [/*"approved",*/ "in progress", "complete"];

function AuditQuestionTable({
  selectedSection,
  handleExploreQuestion,
  sectionQuestions,
  handleWidgetsStatusChange,
}) {
  const dispatch = useDispatch();
  const { selectedVASurvey } = useSelector((state) => state.vendorReducer);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedStatuses, setSelectedStatuses] = React.useState({});

  // Initialize open state for each row
  const initialOpenStates = new Array(sectionQuestions.length).fill(false);
  const [openStates, setOpenStates] = React.useState(initialOpenStates);

  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  const handleClick = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // update status
  const handleMenuItemClick = async (option, index, rowIndex, questionId) => {
    if (login?.user_type === "BSA") {
      const isUpdated = await dispatch(
        gql_update_question_status(
          selectedVASurvey?.frequencyId,
          questionId,
          option,
          selectedBankDetailsForVa?._id,
          login?.bankId
        )
      );

      handleWidgetsStatusChange();

      if (isUpdated === false) return;
    } else {
      const isUpdated = await dispatch(
        gql_update_question_status(
          selectedVASurvey?.frequencyId,
          questionId,
          option,
          login?.vendorId,
          selectedBankDetailsForVa?._id
        )
      );

      handleWidgetsStatusChange();

      if (isUpdated === false) return;
    }

    const newSelectedStatuses = { ...selectedStatuses };
    newSelectedStatuses[rowIndex] = options[index];
    setSelectedStatuses(newSelectedStatuses);
    handleClose();
  };

  const getColorObj = (status) => {
    let color = "#154328";
    let bgColor = "#BFE2CD";
    if (status?.toLowerCase() === "in progress") {
      color = "#15314E";
      bgColor = "#EBF1F8";
    }

    return {
      textTransform: "capitalize",
      color: color,
      backgroundColor: bgColor,
    };
  };

  useEffect(() => {
    setSelectedStatuses({});
  }, [selectedSection?._id]);

  return (
    <DynamicTable headings={headings}>
      {sectionQuestions.map((rowData, rowIndex) => (
        <React.Fragment key={rowData?._id}>
          <TableRow>
            <TableCell align="center" style={textStyle()}>
              {rowIndex + 1}
            </TableCell>
            <TableCell style={textStyle("main")}>{rowData.question}</TableCell>
            <TableCell style={{ border: "none", padding: "0px", width: "9em" }}>
              {rowData?.status?.toLowerCase() === "approved" ? (
                // dropdown menu for status
                <CustomButton
                  aria-controls={`menu-${rowIndex}`}
                  aria-haspopup="true"
                  variant="contained"
                  size="small"
                  endIcon={<Icon>expand_more</Icon>}
                  onClick={(event) => handleClick(event, rowIndex)}
                  style={getColorObj(
                    selectedStatuses[rowIndex] || rowData?.status
                  )}
                >
                  {selectedStatuses[rowIndex] || rowData?.status}
                </CustomButton>
              ) : (
                <CustomButton
                  aria-controls={`menu-${rowIndex}`}
                  aria-haspopup="true"
                  variant="contained"
                  size="small"
                  style={{
                    textTransform: "capitalize",
                    pointerEvents: "none",
                    color:
                      rowData?.status?.toLowerCase() === "in progress"
                        ? "#15314E"
                        : "#154328",
                    backgroundColor:
                      rowData?.status?.toLowerCase() === "in progress"
                        ? "#EBF1F8"
                        : "#BFE2CD",
                  }}
                >
                  {rowData?.status}
                </CustomButton>
              )}

              <Menu
                id={`menu-${rowIndex}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) && selectedRow === rowIndex}
                onClose={handleClose}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    onClick={(event) =>
                      handleMenuItemClick(option, index, rowIndex, rowData?._id)
                    }
                    style={
                      selectedStatuses[rowIndex] === option ||
                      (selectedStatuses[rowIndex] === undefined &&
                        rowData?.status?.toLowerCase() === option)
                        ? {
                            backgroundColor: "lightgray",
                            textTransform: "capitalize",
                          }
                        : { textTransform: "capitalize" }
                    }
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </TableCell>
            <TableCell
              align="center"
              style={{ border: "none", padding: "0px" }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  const newOpenStates = [...openStates];
                  newOpenStates[rowIndex] = !newOpenStates[rowIndex];
                  setOpenStates(newOpenStates);
                  setSelectedRow(rowData?._id);
                }}
              >
                <AddIcon fontSize="small" style={{ color: "#3374B9" }} />
              </IconButton>
            </TableCell>
          </TableRow>
          {rowData?._id === selectedRow && (
            <TableRow style={{ backgroundColor: "#F6F6F6" }}>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
                colSpan={6}
              >
                <Collapse
                  in={openStates[rowIndex]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                    elevation={1}
                    margin={1}
                    style={{
                      border: "1px solid #E9E9E9",
                      backgroundColor: "#FEFEFE",
                      borderRadius: "8px",
                      padding: "10px",
                      minHeight: "70px",
                    }}
                  >
                    <QuestionBody
                      questionType={rowData?.questionType}
                      answers={rowData?.answers}
                      submittedAnswer={
                        rowData?.questionType === "TABLE"
                          ? rowData?.approvedTableRows
                          : rowData?.approvedAnswer
                      }
                      event={"none"}
                      comment={
                        rowData?.questionType === "TABLE"
                          ? rowData?.approvedTableRows?.length > 0 &&
                            rowData?.approvedTableRows[0][0]?.comment !==
                              null &&
                            rowData?.approvedTableRows[0][0]?.comment !== ""
                          : rowData?.approvedAnswer &&
                            rowData?.approvedAnswer[0]?.comment !== null &&
                            rowData?.approvedAnswer[0]?.comment !== ""
                      }
                      tableHeadersApi={rowData?.tableHeaders}
                    />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </DynamicTable>
  );
}

export default AuditQuestionTable;
