import { gql } from "@apollo/client";
export const GET_THREAD_MESSAGES = gql`
  query Messages($roomId: String!) {
    messages(roomId: $roomId) {
      _id
      next {
        submittedTableRows {
          comment
          documentUrl {
            type
            name
            url
          }
          weightage
          option
        }
        tableHeaders {
          title
          type
        }
        answer {
          option
          weightage
          comment
          documentUrl {
            name
            type
            url
          }
          endDate
          justificationText
          startDate
        }
        chatRoomId
        submissionId
        messages {
          text
          sentAt
          type
          sentBy {
            _id
            name
            profileImg {
              name
              type
              url
            }
          }
        }
      }
      sentAt
      text
      sentBy {
        _id
        name
        profileImg {
          name
          type
          url
        }
      }
      isApproved
      prev
    }
  }
`;

export const APPROVED_ANSWER = gql`
  mutation ApproveAnswer(
    $approvedBy: ID!
    $submissionId: ID!
    $bankId: ID!
    $vendorId: ID!
  ) {
    approveAnswer(
      approvedBy: $approvedBy
      submissionId: $submissionId
      bankId: $bankId
      vendorId: $vendorId
    ) {
      message
      success
    }
  }
`;

export const UNAPPROVED_ANSWER = gql`
  mutation UnApproveAnswer($submissionId: ID!, $bankId: ID!, $vendorId: ID!) {
    unApproveAnswer(
      submissionId: $submissionId
      bankId: $bankId
      vendorId: $vendorId
    ) {
      message
      success
    }
  }
`;

export const GET_ROOM_MEMBERS = gql`
  query RoomMembers($roomId: ID!) {
    roomMembers(roomId: $roomId) {
      _id
      name
      profileImg {
        name
        type
        url
      }
    }
  }
`;
