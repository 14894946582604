import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

function Instructions({ currentQuestion }) {
  const splitText = currentQuestion?.hints?.split(/;\s*/);

  console.log(splitText);
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<KeyboardArrowDownRoundedIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Instructions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {splitText?.map((hint, i) => (
            <Typography key={i}>{hint}</Typography>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Instructions;
