import React from "react";
import { useState } from "react";
import { Wrapper } from "../selfAssessment/selfAssessmentResponse";
import { useRef } from "react";
import { useEffect } from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import CustomTextfield from "./../../UI/textfield/textfield";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  makeStyles,
  Divider,
} from "@material-ui/core";
import Textfield from "../../UI/textfield/textfield";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countries from "../../../../src/utils/countries/countries";
import { useDispatch, useSelector } from "react-redux";
import {
  gqlGetRoiQuestions,
  gqlGetRoiSections,
} from "../../../redux/actions/roiActions/RoiActions";
import {
  gql_create_roi,
  gql_get_linkage_survey_questions,
  gql_get_linkage_survey_sections,
  gql_submit_linkage_survey_questions,
} from "../../../redux/actions/vendorActions/vendorAction";
import CustomButton from "../../UI/button/button";
import {
  SET_ROI_QUESTIONS,
  SET_ROI_RESPONSES,
  SET_ROI_SECTIONS,
} from "../../../constants/roiConstants";
import NoData from "../NoData/NoData";
import {
  SET_LINKAGE_SURVEY_QUESTIONS,
  SET_LINKAGE_SURVEY_SECTIONS,
} from "../../../constants/brmConstants";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 800, // Set the maximum width for the dropdown items
    borderTop: "1px solid rgba(0,0,0,0.03)",
  },
  selectMenu: {
    "& ul": {
      overflowX: "auto", // Enable horizontal scroll
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[300], // Light gray color for the divider
  },
}));

export default function RegisterOfInformation({
  isFrom,
  setAllResponseArray,
  allResponseArray,
  vendorId,
  pointerEvents,
}) {
  const scroll = useRef();
  const classes = useStyles();

  const [sectionSelect, setSectionSelect] = useState("");
  const [edit, setEdit] = useState(false);
  const [branchCountry, setBranchCountry] = useState("");
  const [responseArray, setResponseArray] = useState([]);
  const [linkageAnswer, setLinkageAnswer] = useState([]);
  const [step, setStep] = useState(0);

  const { roiSections, roiQuestions, roiResponses } = useSelector(
    (state) => state?.roiReducer
  );
  const {
    vendorBasicDetails,
    linkageSurveys,
    linkageSurveySections,
    linkageSurveyQuestions,
  } = useSelector((state) => state?.brmData);

  console.log(linkageSurveyQuestions);

  const createVendorSection = [
    {
      id: "667e9552f9a19ac2927e30d5",
      title: "RT.01.01",
    },
    {
      id: "667e955ef9a19ac2927e30d8",
      title: "RT.01.02",
    },
    {
      id: "667e9561f9a19ac2927e30db",
      title: "RT.01.03",
    },
  ];

  const dispatch = useDispatch();

  const surveyId = linkageSurveys?.find((survey) =>
    survey?.name?.toLowerCase()?.includes("register of information")
  )?._id;

  useEffect(() => {
    if (isFrom === "createVendor") {
      dispatch(gqlGetRoiSections(true));
    } else {
      dispatch(gql_get_linkage_survey_sections(surveyId));
    }

    return () => {
      dispatch({ type: SET_LINKAGE_SURVEY_SECTIONS, payload: [] });
    };
  }, [surveyId]);

  useEffect(() => {
    scroll?.current?.scrollTo(0, 0);
  }, [sectionSelect]);

  // const sections = [
  //   {
  //     title: "RT.01.01",
  //     _id: 1,
  //     data: [
  //       {
  //         name: "LEI of the entity maintaining the register of information",
  //         type: "Alphanumerical",
  //         instructions: `Identify the entity maintaining and updating the register of information using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard.`,
  //         mandatory: true,
  //       },
  //       {
  //         name: "Name of the entity",
  //         type: "Alphanumerical",
  //         instructions: `Legal name of the entity maintaining and updating the register of information.`,
  //         mandatory: true,
  //       },
  //       {
  //         name: "Country of the entity",
  //         type: "Country",
  //         instructions: `Identify the ISO 3166-1 alpha-2 code of the country where the license or the registration of the entity reported in the Register on Information has been issued.`,
  //         mandatory: true,
  //       },
  //       {
  //         name: "Type of entity",
  //         type: "Options",
  //         instructions: `Identify the type of entity using one of the options in the following closed list:`,
  //         mandatory: true,
  //         dropdown: [
  //           "1. Credit Institutions",
  //           "2. Payment institutions, including payment institutions exempted pursuant to Directive (EU) 2015/2366",
  //           "3. Account information service providers",
  //           "4. Electronic money institutions, including electronic money institutions exempted pursuant to Directive 2009/110/EC",
  //           "5. Investment firms",
  //           "6. Crypto-asset service providers as authorised under a Regulation of the European Parliament and of the Council on markets in cryptoassets, and amending Regulations (EU) No 1093/2010 and (EU) No 1095/2010 and Directives 2013/36/EU and (EU) 2019/1937 ('the Regulation on markets in crypto-assets') and issuers of assetreferenced tokens",
  //           "7. Central securities depositories",
  //           "8. Central counterparties",
  //           "9. Trading venues",
  //           "10. Trade repositories",
  //           "11. Managers of alternative investment funds ",
  //           "12. Management companies",
  //           "13. Data reporting service providers",
  //           "14. Insurance and reinsurance undertakings",
  //           "15. Insurance intermediaries, reinsurance intermediaries and ancillary insurance intermediaries",
  //           "16. Institutions for occupational retirement provision",
  //           "17. Credit rating agencies",
  //           "18. Administrators of critical benchmarks",
  //           "19. Crowdfunding service providers",
  //           "20. Securitisation repositories",
  //           "21. Other financial entity",
  //           "22. Non-financial entity: ICT intra-group service provider",
  //           "23. Non-financial entity: Other",
  //         ],
  //       },
  //       {
  //         name: "Competent Authority",
  //         type: "Alphanumerical",
  //         instructions: `Identify the competent authority according to Article 46 of Regulation (EU) 2022/2554 to which the register of information is reported.`,
  //         mandatory: true,
  //       },
  //       {
  //         name: "Date of the reporting",
  //         type: "Date",
  //         instructions: `Identify the ISO 8601 (yyyy-mm-dd) code of the date of reporting.`,
  //         mandatory: true,
  //       },
  //     ],
  //   },
  //   {
  //     title: "RT.01.02",
  //     _id: 2,
  //   },
  //   {
  //     title: "RT.01.03",
  //     _id: 3,
  //   },
  //   {
  //     title: "RT.02.01",
  //     _id: 4,
  //   },
  //   {
  //     title: "RT.02.02",
  //     _id: 5,
  //   },
  //   {
  //     title: "RT.02.03",
  //     _id: 6,
  //   },
  //   {
  //     title: "RT.03.01",
  //     _id: 7,
  //   },
  //   {
  //     title: "RT.03.02",
  //     _id: 8,
  //   },
  //   {
  //     title: "RT.03.03",
  //     _id: 9,
  //   },
  //   {
  //     title: "RT.04.01",
  //     _id: 10,
  //   },
  //   {
  //     title: "RT.05.01",
  //     _id: 11,
  //   },
  //   {
  //     title: "RT.05.02",
  //     _id: 12,
  //   },
  //   {
  //     title: "RT.06.01",
  //     _id: 13,
  //   },
  //   {
  //     title: "RT.07.01",
  //     _id: 14,
  //   },
  //   {
  //     title: "RT.99.01",
  //     _id: 15,
  //   },
  // ];

  useEffect(() => {
    if (isFrom === "editVendor" && linkageSurveySections?.length > 0) {
      dispatch(
        gql_get_linkage_survey_questions(
          linkageSurveySections[0]?._id,
          vendorBasicDetails?._id
        )
      );
    }

    if (isFrom === "createVendor") {
      dispatch(gql_get_linkage_survey_questions(roiSections[0]?._id, vendorId));
    }

    setSectionSelect({
      title: linkageSurveySections[0]?.title,
      id: linkageSurveySections[0]?._id,
    });

    return () => {
      dispatch({
        type: SET_LINKAGE_SURVEY_QUESTIONS,
        payload: [],
      });
    };
  }, [linkageSurveySections, roiSections, vendorId]);

  const handleSelectedSection = async (sectionId) => {
    await dispatch({
      type: SET_LINKAGE_SURVEY_QUESTIONS,
      payload: [],
    });

    if (isFrom === "editVendor") {
      dispatch(
        gql_get_linkage_survey_questions(sectionId, vendorBasicDetails?._id)
      );
    } else if (isFrom === "createVendor") {
      dispatch(gql_get_linkage_survey_questions(sectionId, vendorId));
    }

    setResponseArray([]);
    setBranchCountry("");
  };

  const handleNext = async () => {
    const modifiedArray = [...linkageAnswer, ...responseArray]?.filter(
      (item) => item !== undefined
    );

    const input = {
      vendorId: vendorId,
      answers: modifiedArray,
    };

    await dispatch(gql_submit_linkage_survey_questions(input));

    setResponseArray([]);
    setBranchCountry("");

    setStep((step) => step + 1);
  };

  const isFromSections =
    isFrom === "editVendor" ? linkageSurveySections : roiSections;

  useEffect(() => {
    dispatch({
      type: SET_LINKAGE_SURVEY_QUESTIONS,
      payload: [],
    });

    if (isFrom === "createVendor") {
      dispatch(
        gql_get_linkage_survey_questions(roiSections[step]?._id, vendorId)
      );
    }
  }, [step]);

  useEffect(() => {
    if (isFrom === "createVendor") {
      const linkageData = roiQuestions
        ?.filter((fData) => fData?.linkageAnswer !== null)
        .map((mData) => {
          return { questionId: mData?._id, answer: mData?.linkageAnswer };
        });
      setLinkageAnswer(linkageData);
    }
  }, [roiQuestions]);

  const handleUpdateRoi = () => {
    const modifiedArray = responseArray?.filter((item) => item !== undefined);

    const input = {
      vendorId: vendorBasicDetails?._id,
      answers: modifiedArray,
    };
    dispatch(gql_submit_linkage_survey_questions(input));
  };

  const handleOptionChange = (value, question, index) => {
    const copyArr = [...responseArray];
    copyArr[index] = {
      ...copyArr[index],
      questionId: question?._id,
      answer: question?.linkageAnswer || value,
    };

    setResponseArray(copyArr);
  };

  const answerFormat = (question, localResponse, index) => {
    if (question?.answer && !question.linkageAnswer) {
      return question?.answer;
    } else if (question?.linkageAnswer && !question?.answer) {
      return question?.linkageAnswer;
    }
    // data?.linkageAnswer || responseArray[index]?.answer || data?.answer || "";
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isFrom !== "createVendor" && (
          <div>
            <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
              Register Of Information{" "}
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                (Please save changes before moving to next RT section!)
                <span style={{ fontSize: 14, fontWeight: 500, color: "red" }}>
                  *
                </span>
              </span>
            </Typography>
          </div>
        )}
        {/* {isFrom === "createVendor" && (
          <div style={{ flex: 1 }}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              You can always fill out the fields later in the Vendor's profile,
              if you forgot to fill any field
              <span style={{ fontSize: 14, fontWeight: 500, color: "red" }}>
                *
              </span>
            </Typography>
            
          </div>
        )} */}
        {isFrom !== "createVendor" && (
          <div>
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#3374B9",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={handleUpdateRoi}
            >
              Save
            </Typography>
          </div>
        )}
      </div>
      {isFromSections?.length > 0 && (
        <Wrapper
          users={isFromSections}
          sectionSelect={
            isFrom === "createVendor"
              ? createVendorSection[step <= 2 ? step : 2]
              : sectionSelect
          }
          setSectionSelect={setSectionSelect}
          hidesearch
          handleSelectedSection={handleSelectedSection}
          isFrom={isFrom}
          pointerEvents={pointerEvents}
        >
          <div
            className="scroll"
            ref={scroll}
            style={{
              width: "78%",
              height: isFrom === "createVendor" ? "65vh" : "59vh",
            }}
          >
            <>
              {/* <BlueBackgroundCard
                heading={sectionSelect?.title}
                subHeading={sectionSelect?.description}
              /> */}
              {step !== 3 &&
                linkageSurveyQuestions?.map((data, index) => (
                  <Box
                    key={index}
                    style={{ marginRight: "10px", marginLeft: "15px" }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography style={{ fontWeight: 500 }} variant="body2">
                        {data?.name}
                        {data?.mandatory
                          ?.toLowerCase()
                          ?.includes("mandatory") ? (
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          ""
                        )}
                      </Typography>
                      {data?.type === "DROPDOWN" ? (
                        <Select
                          fullWidth
                          disabled={
                            data?.linkageAnswer &&
                            (data?.linkageAnswer !== null ? true : false)
                          }
                          value={
                            data?.linkageAnswer ||
                            responseArray[index]?.answer ||
                            data?.answer
                          }
                          // defaultValue={data?.answer}
                          onChange={(e) =>
                            handleOptionChange(e.target.value, data, index)
                          }
                          // label="Entity"
                          variant="outlined"
                          size="small"
                          // disabled={isFrom === "createVendor" ? false : !edit}
                          MenuProps={{ classes: { paper: classes.selectMenu } }}
                        >
                          {data?.options?.map((item, i) => (
                            <MenuItem
                              key={i}
                              value={item?.option}
                              className={classes.menuItem}
                            >
                              {item?.option}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : data?.type === "Country" ? (
                        <Autocomplete
                          getOptionSelected={(option, value) =>
                            option?.id === value?.id
                          }
                          id="country-select-demo"
                          sx={{ width: 300 }}
                          options={countries}
                          disabled={
                            data?.linkageAnswer &&
                            (data?.linkageAnswer !== null ? true : false)
                          }
                          // autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(option) => option.label}
                          renderInput={(params) => (
                            <CustomTextfield
                              {...params}
                              // label="Country"
                              variant="outlined"
                              value={
                                data?.linkageAnswer ||
                                branchCountry ||
                                data?.answer
                              }
                              name="Country"
                            />
                          )}
                          onChange={(event, newValue) => {
                            newValue === null
                              ? setBranchCountry("")
                              : setBranchCountry(newValue.label);
                          }}
                          inputValue={
                            responseArray[index]?.answer || data?.answer || ""
                          }
                          onInputChange={(event, newInputValue) =>
                            handleOptionChange(newInputValue, data, index)
                          }
                        />
                      ) : data?.type === "Date" ? (
                        <CustomTextfield
                          style={{ marginTop: "10px" }}
                          name="date"
                          variant="outlined"
                          size="small"
                          fullWidth
                          label="Pick your date"
                          onChange={(e) =>
                            handleOptionChange(e.target.value, data, index)
                          }
                          id="date"
                          type="date"
                          // inputProps={{
                          //   min: minDate,
                          // }}
                          disabled={
                            data?.linkageAnswer &&
                            (data?.linkageAnswer !== null ? true : false)
                          }
                          value={
                            data?.linkageAnswer || responseArray?.length > 0
                              ? responseArray[index]?.answer
                              : data?.answer
                          }
                          // defaultValue="2017-05-24"
                          // className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      ) : (
                        <Textfield
                          variant="outlined"
                          value={
                            answerFormat(data, responseArray, index)

                            // (isFrom === "editVendor"
                            //   ? responseArray?.length > 0
                            //     ? responseArray[index]?.answer
                            //     : data?.answer
                            //   : responseArray?.length > 0
                            //   ? responseArray[index]?.answer
                            //   : data?.answer
                            //   ? data?.answer
                            //   : "")
                          }
                          size="small"
                          fullWidth
                          disabled={
                            data?.linkageAnswer &&
                            (data?.linkageAnswer !== null ? true : false)
                          }
                          onChange={(e) =>
                            handleOptionChange(e.target.value, data, index)
                          }
                        />
                      )}

                      <Typography variant="body2" style={{ color: "#999" }}>
                        {data?.instruction}
                      </Typography>
                    </Box>
                  </Box>
                ))}

              {step === 3 && (
                <NoData
                  message={
                    "RT details are done, please click on create vendor!"
                  }
                />
              )}
            </>
          </div>
        </Wrapper>
      )}
      {isFrom === "createVendor" && (
        <Box style={{ display: "flex", justifyContent: "center", gap: 10 }}>
          <CustomButton
            variant="outlined"
            color="primary"
            disabled={step === 0}
            onClick={() => {
              setStep((step) => step - 1);
              // handlePrev(step);
            }}
            style={{ textTransform: "none", fontSize: 12 }}
          >
            Redo Prev Section
          </CustomButton>
          <CustomButton
            variant="outlined"
            disabled={
              step === 3 ||
              responseArray?.length !== linkageSurveyQuestions?.length
            }
            color="primary"
            onClick={() => {
              handleNext();
            }}
            style={{ textTransform: "none", fontSize: 12 }}
          >
            Save & Next
          </CustomButton>
        </Box>
      )}
    </div>
  );
}
