import { gql } from "@apollo/client";

export const GET_VENDOR_DASHBOARD_QUERY = gql`
  query DashboardData {
    dashboardData {
      count
      vendors {
        _id
        sector {
          sector {
            sector
          }
          industry {
            industry
          }
        }
        name
        vendorRisk
        esgRisk
        vendorCategorization
        vendorMateriality
        vendorPerformance
      }
      groupedBySector
      groupedByIndustry
      avgEsgRisk
      avgVendorRisk
      vendorRiskGroupCount
      esgRiskGroupCount
      overallRiskPerformance
    }
  }
`;
