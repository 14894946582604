import { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";

// icons
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import {
  questionTypes,
  radioOption,
  subQuestionTypes,
} from "../../../QuestionTabComponents/QuestionData";
import { CustomList } from "../../../QuestionTabComponents/SectionList";
import SaveDetailsDialog from "../../../../SaveDetailsDialog";

const QuestionFields = ({
  selectedQues,
  setSelectedQues,
  usingQues,
  isDefault,
  handleQuestionSave,
  selectedSection,
  checkBoxOption,
}) => {
  // states
  const [showQuesTypes, setShowQuesTypes] = useState(false);
  const [questionOption, setQuestionOption] = useState(questionTypes);
  const [openDefaultEditAlert, setOpenDefaultEditAlert] = useState(false);

  // -------------- Inner Functions --------------
  // toggle menu items
  const toggleQuesTypes = () => {
    setShowQuesTypes(!showQuesTypes);

    if (showQuesTypes) {
      setTimeout(() => {
        setQuestionOption(questionTypes);
      }, 500);
    }
  };

  useEffect(() => {
    const typeArray = [
      "SC",
      "SCT",
      "SCTD",
      "SCD",
      "TF",
      "TD",
      "CF",
      "NF",
      "EMAIL",
      "URL",
      "ND",
      "TABLE",
      "MULTICHOICE",
      "COMMENT_BOX",
      "LOCATION",
      "DOCUMENT_UPLOAD",
      "DATE_PICKER_SINGLE_DATE",
      "DATE_PICKER_RANGE_DATE",
      "DROPDOWN",
    ];

    if (
      selectedQues?.questionType &&
      !typeArray.includes(selectedQues?.questionType) &&
      !selectedSection?.default
    ) {
      const ansArray = [...radioOption[selectedQues?.questionType]];
      const formattedAnswer = ansArray?.map((ans, index) => {
        return { option: ans, weightage: index + 1 };
      });

      const updatedQuestion = {
        ...selectedQues,
        answers: formattedAnswer,
      };

      setSelectedQues(updatedQuestion);
    }
  }, [selectedQues?.questionType]);

  useEffect(() => {
    const typeArray = [
      "TF",
      "TD",
      "CF",
      "EMAIL",
      "NF",
      "URL",
      "ND",
      "TABLE",
      "COMMENT_BOX",
      "LOCATION",
      "DOCUMENT_UPLOAD",
      "DATE_PICKER_SINGLE_DATE",
      "DATE_PICKER_RANGE_DATE",
      "DROPDOWN",
    ];

    if (
      selectedQues?.questionType &&
      typeArray.includes(selectedQues?.questionType) &&
      !selectedSection?.default
    ) {
      const updatedQuestion = {
        ...selectedQues,
        answers: [],
      };

      setSelectedQues(updatedQuestion);
    }
  }, [selectedQues?.questionType]);

  useEffect(() => {
    if (showQuesTypes && selectedQues?.questionType === "MULTICHOICE") {
      const updatedQuestion = {
        ...selectedQues,
        answers: checkBoxOption,
      };

      setSelectedQues(updatedQuestion);
    }
  }, [selectedQues?.questionType, showQuesTypes, checkBoxOption]);

  // handling more options for some menu items for question types
  const handleMoreListOption = useCallback((value) => {
    setQuestionOption(subQuestionTypes[value]);
  }, []);

  // handling changing of menu item for question types
  const handleQuestionUpdate = (e, propertyName) => {
    const updatedQuestion = {
      ...selectedQues,
      [propertyName]:
        propertyName === "questionType" ? e?.primary : e.target.value,
    };

    setSelectedQues(updatedQuestion);
    propertyName === "questionType" && toggleQuesTypes();
  };

  const toggleAlertDialog = () => {
    setOpenDefaultEditAlert(!openDefaultEditAlert);
  };

  const formatTypes = (val) => {
    if (val === "SC") {
      return "Single Choice";
    } else if (val === "TF") {
      return "Text Field";
    } else if (val === "SCT") {
      return "Single Choice with Text";
    } else if (val === "SCTD") {
      return "Single Choice with Text & Doc";
    } else if (val === "SCD") {
      return "Single Choice with Document";
    } else if (val === "TD") {
      return "Text Field & Document";
    } else if (val === "CF") {
      return "Currency Field";
    } else if (val === "NF") {
      return "Numeric Field";
    } else if (val === "ND") {
      return "Numeric Field & Document";
    } else if (val === "URL") {
      return "Url Field";
    } else if (val === "EMAIL") {
      return "Email Field";
    }
    return val
      ?.split("_")
      ?.map((v) => v[0]?.toUpperCase() + v?.slice(1).toLowerCase())
      ?.join(" ");
  };

  // ---------------------------------------------

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2px 25px",
      }}
    >
      {/* Question field */}
      <TextField
        id="outlined-basic"
        label="Question"
        variant="outlined"
        fullWidth
        style={{
          width:
            selectedQues?._id === usingQues?._id
              ? "65%"
              : selectedQues?._id !== usingQues?._id
              ? "80%"
              : "75%",
          pointerEvents: selectedQues?._id === usingQues?._id ? "auto" : "none",
        }}
        size="small"
        value={selectedQues ? selectedQues?.question : usingQues?.question}
        disabled={selectedQues && isDefault}
        onChange={(event) =>
          setSelectedQues((prevSelectedQues) => ({
            ...prevSelectedQues,
            question: event.target.value,
          }))
        }
      />

      {/* Question type menu (Hidden when not editing) */}
      {selectedQues?._id === usingQues?._id && (
        <FormControl
          variant="outlined"
          style={{ width: "20%" }}
          size="small"
          disabled={selectedQues && isDefault}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Type of Question
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedQues?.questionType}
            onClose={() => toggleQuesTypes()}
            onOpen={() => toggleQuesTypes()}
            label="Type of Question"
            open={showQuesTypes}
          >
            <CustomList
              value={selectedQues?.questionType}
              list={questionOption}
              handleListIcon={handleMoreListOption}
              handleListClick={(e) => handleQuestionUpdate(e, "questionType")}
              isMenuItem={true}
            >
              {formatTypes(selectedQues?.questionType)}
            </CustomList>
          </Select>
        </FormControl>
      )}

      {/* Question Weightage */}
      {usingQues?.weightage ? (
        <FormControl
          variant="outlined"
          style={{
            width: "10%",
            display:
              // hide form when editing section which is not default
              selectedQues?._id === usingQues?._id
                ? isDefault
                  ? "block"
                  : "none"
                : "block",
          }}
          size="small"
        >
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedQues?.weightage || usingQues?.weightage}
            onChange={(e) => handleQuestionUpdate(e, "weightage")}
            disabled={!selectedQues}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((weight) => (
              <MenuItem key={weight} value={weight}>
                {weight}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      {/* Edit button */}
      {selectedQues?._id !== usingQues?._id && (
        <IconButton
          onClick={() =>
            isDefault
              ? setOpenDefaultEditAlert(true)
              : handleQuestionSave(usingQues, false)
          }
        >
          <EditOutlinedIcon style={{ color: "#3374B9" }} />
        </IconButton>
      )}

      {/* Alert Dialog box for editing default sections */}
      {openDefaultEditAlert && (
        <SaveDetailsDialog
          open={openDefaultEditAlert}
          handleClose={toggleAlertDialog}
          title={"Default Section Edit"}
          contentText={
            "In the default survey section, you can only edit the question's weightage. The question, type, and options are fixed for this section. Customize the weightage as needed"
          }
          leftButtonText={"Cancel"}
          rightButtonText={"Continue to edit"}
          handleSave={() => {
            handleQuestionSave(usingQues, false);
            setOpenDefaultEditAlert(false);
          }}
        />
      )}
    </Box>
  );
};
export default QuestionFields;
