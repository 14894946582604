import Box from "@material-ui/core/Box";

import ProgressHeader from "../CommonComps/ProgressHeader";
import QuestionsSection from "../CommonComps/QuestionsSection";
import CustomButton from "../../../../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import { gql_submit_answer } from "../../../../../../../redux/actions/taskActions/TaskAction";
import { setSelectedOption } from "../../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";
import Instructions from "./Instructions";

const QuestionBox = ({
  totalQues,
  taskQuestions,
  currentQuestion,
  setCurrentQuestion,
  currentQuesNo,
  setCurrentQuesNo,
  progress,
  setProgress,
  changeProgressBy,
  activeSection,
  activeSectionIndex,
  setActiveSectionIndex,
  setSectionComplete,
}) => {
  // from redux
  const dispatch = useDispatch();
  const { selectedTask } = useSelector((state) => state.taskReducer);
  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  // console.log(currentQuestion?.submittedAnswer);
  // -------------------- Inner Functions --------------------

  // handling prev question button click
  const handlePrevQues = () => {
    // if progress is more than initial
    if (progress > (1 / totalQues) * 100) {
      setCurrentQuesNo((prev) => prev - 1);
      setProgress(progress - changeProgressBy);

      console.log("check1");
    }
    // if progress becomes initial
    else if (
      activeSectionIndex !== 0 &&
      currentQuestion?.questionId === taskQuestions[0]?.questionId
    ) {
      setActiveSectionIndex((prev) => prev - 1);

      setProgress(changeProgressBy);
      setCurrentQuesNo(1);

      console.log("check2");
    } else {
      console.log("check3");
      setCurrentQuesNo((prev) => prev - 1);
      setProgress(progress - changeProgressBy);
    }
  };

  const submittedAnswer =
    currentQuestion?.questionType === "MULTICHOICE"
      ? currentQuestion?.submittedAnswer
          ?.filter((answer) => answer.checked)
          ?.map((answer) => {
            const {
              checked,
              justification,
              description,
              documentUpload,
              ...rest
            } = answer;
            return rest;
          })
      : currentQuestion?.questionType === "TABLE"
      ? currentQuestion?.tableRows
      : [currentQuestion?.submittedAnswer];

  console.log(submittedAnswer);

  const handleSkipAndNextQues = () => {
    if (progress < 99.9) {
      setProgress(progress + changeProgressBy);
      setCurrentQuesNo((prev) => prev + 1);
    }
    // when progress is 100%
    else if (currentQuesNo === totalQues && progress >= 99.9) {
      // if survey is already on complete
      setSectionComplete(true);
    }
  };

  const handleNextQues = async () => {
    // to get new list of questions
    const fetchQuestionsObj = {
      frequencyId: selectedTask?.frequencyId,
      sectionId: activeSection?._id,
      setCurrentQuestion,
    };

    const submittedAnswer =
      currentQuestion?.questionType === "MULTICHOICE"
        ? currentQuestion?.submittedAnswer
            ?.filter((answer) => answer.checked)
            ?.map((answer) => {
              const {
                checked,
                justification,
                description,
                documentUpload,
                ...rest
              } = answer;
              return rest;
            })
        : currentQuestion?.questionType === "TABLE"
        ? currentQuestion?.tableRows
        : [currentQuestion?.submittedAnswer];

    let submitAnswerObj;

    if (currentQuestion?.questionType === "TABLE") {
      submitAnswerObj = {
        questionId: currentQuestion?.questionId, // question id
        selectedAnswer: [], // answers
        tableRows: submittedAnswer, // answers
        approverIds: currentQuestion?.approverIds, // approver ids
        chatRoomId: currentQuestion?.chatRoomId,
        frequencyId: selectedTask?.frequencyId,
      };
    } else {
      submitAnswerObj = {
        questionId: currentQuestion?.questionId, // question id
        selectedAnswer: submittedAnswer, // answers
        tableRows: [], // answers
        approverIds: currentQuestion?.approverIds, // approver ids
        chatRoomId: currentQuestion?.chatRoomId,
        frequencyId: selectedTask?.frequencyId,
      };
    }

    // dispatch action

    if (login?.user_type === "BSA") {
      const isSubmitted = await dispatch(
        gql_submit_answer(
          submitAnswerObj,
          fetchQuestionsObj,
          currentQuestion?.questionType,
          login?.bankId,
          selectedBankDetailsForVa?._id
        )
      );
      if (isSubmitted === false) return;
    } else {
      const isSubmitted = await dispatch(
        gql_submit_answer(
          submitAnswerObj,
          fetchQuestionsObj,
          currentQuestion?.questionType,
          selectedBankDetailsForVa?._id,
          login?.vendorId
        )
      );
      if (isSubmitted === false) return;
    }

    setCurrentQuestion(null);

    dispatch(setSelectedOption(null));

    if (progress < 99.9) {
      setProgress(progress + changeProgressBy);
      setCurrentQuesNo((prev) => prev + 1);
    }
    // when progress is 100%
    else if (currentQuesNo === totalQues && progress >= 99.9) {
      // if survey is already on complete
      setSectionComplete(true);
    }
  };

  const isDisabled =
    currentQuestion?.linkageAnswer === null &&
    currentQuestion?.linkageId !== null
      ? false
      : currentQuestion?.questionType === "MULTICHOICE"
      ? !currentQuestion?.submittedAnswer?.some((answer) => answer.checked)
      : currentQuestion?.questionType === "DATE_PICKER_SINGLE_DATE"
      ? !currentQuestion?.submittedAnswer?.startDate
      : currentQuestion?.questionType === "DATE_PICKER_RANGE_DATE"
      ? !currentQuestion?.submittedAnswer?.startDate ||
        !currentQuestion?.submittedAnswer?.endDate
      : currentQuestion?.questionType === "TABLE"
      ? !currentQuestion?.submittedAnswer?.every((answer) =>
          answer.every((ans) => ans?.option !== "")
        )
      : !currentQuestion?.submittedAnswer?.option &&
        !currentQuestion?.submittedAnswer?.documentUrl;

  // ---------------------------------------------------------

  return (
    <Box width={"70vw"} height={"100%"} style={{ position: "relative" }}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          paddingBottom: "65px",
          boxShadow: "0px 0px 8px 0px #3374B940",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{ gap: 20 }}
          style={{ height: "inherit" }}
        >
          {/* Header */}
          <ProgressHeader
            title={activeSection?.title}
            sub={subName}
            progress={progress}
            totalQues={totalQues}
            currentQuesNo={currentQuesNo}
          />
          {/* Instructions */}
          {currentQuestion?.hints !== "" && currentQuestion?.hints !== null && (
            <Instructions currentQuestion={currentQuestion} />
          )}
          {/* Main */}
          <QuestionsSection
            activeSection={activeSection}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        </Box>
      </Box>

      {/* action buttons */}
      <Box
        style={{
          display: "flex",
          position: "absolute",
          right: 10,
          bottom: 10,
          gap: 10,
        }}
      >
        <CustomButton
          variant={"text"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={handlePrevQues}
          disabled={
            activeSectionIndex === 0 &&
            currentQuestion?.questionId === taskQuestions[0]?.questionId
          }
        >
          Previous
        </CustomButton>
        <CustomButton
          variant={"contained"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={
            currentQuestion?.linkageAnswer === null &&
            currentQuestion?.linkageId !== null
              ? handleSkipAndNextQues
              : handleNextQues
          }
          disabled={isDisabled}
        >
          {currentQuestion?.linkageAnswer === null &&
          currentQuestion?.linkageId !== null
            ? "Skip"
            : "Submit & Next"}
        </CustomButton>
      </Box>
    </Box>
  );
};
export default QuestionBox;

const subName =
  "Shape the narrative of your company's sustainability journey by providing detailed insights in our Company Profile Section, capturing the essence of your environmental, social, and governance practices.";
