import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";

// other imports
import { BlueBackgroundCard } from "../../../../../../../widgets/esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Question } from "../../../../../../selfAssessment/selfAssessmentResponse";
import OptimizeInitialRender from "./OptimizeInitialRender";
import { gql_get_completed_questions } from "../../../../../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { useDispatch, useSelector } from "react-redux";
import { SET_COMPLETED_QUESTIONS } from "../../../../../../../../constants/esgDiagnosisConstant";
import NoData from "../../../../../../NoData/NoData";
const ResponseSectionList = ({
  submittedQuestions,
  response,
  frequencyId,
  vendor,
}) => {
  const nonClickableStyle = {
    pointerEvents: "none",
  };

  const { completedQuestions } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  return (
    <Box sx={{ height: "80%", overflowY: "scroll", paddingBottom: "10px" }}>
      {completedQuestions?.length > 0 ? (
        <OptimizeInitialRender chunkSize={1}>
          {completedQuestions?.map((data) => (
            <>
              <BlueBackgroundCard heading={data?.sectionTitle} />
              {data?.questions?.map((question) => (
                <Question
                  key={question?.questionId}
                  question={question?.question}
                  options={question?.answers}
                  type={question?.questionType}
                  weightage={question?.weightage}
                  submittedAnswer={
                    question?.questionType === "TABLE"
                      ? question?.approvedTableRows
                      : question?.approvedAnswer
                  }
                  comment={
                    question?.questionType === "TABLE"
                      ? question?.approvedTableRows?.length > 0 &&
                        question?.approvedTableRows[0][0]?.comment !== null &&
                        question?.approvedTableRows[0][0]?.comment !== ""
                      : question?.approvedAnswer &&
                        question?.approvedAnswer[0]?.comment !== null &&
                        question?.approvedAnswer[0]?.comment !== ""
                  }
                  tableHeadersApi={question?.tableHeaders}
                />
              ))}
            </>
          ))}
        </OptimizeInitialRender>
      ) : (
        <NoData message={"No responses found"} />
      )}
    </Box>
  );
};

export default ResponseSectionList;
