import {
  SET_LOGIN,
  SET_LOGOUT,
  SET_TOKEN,
  SET_USER_PROFILE,
  SET_SIGNUP_STEP_NO,
  SET_CHAT_TOKENS,
} from "./loginActionType";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../../actions/shared/sharedActions";

import UNIVERSAL from "../../../config/config";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setDashboardPage } from "../riskdashboard/riskDashboardActions";

// graphql imports
import { client } from "../../store/store";
import {
  GQL_FORGOT_PASSWORD,
  LOGIN_WITH_EMAIL,
  LOGOUT,
  REFRESH_TOKEN,
  SIGNUP,
  VERIFY_OTP,
} from "../../graphql/gql_AuthQueries";

import { createHttpLink } from "@apollo/client";

// -----------------Custom Interceptor-----------------
export function checkIsUnAuth(error) {
  if (
    error.extensions &&
    error.extensions.code === "BAD_REQUEST" &&
    error.message === "Could not authenticate with token" &&
    error.extensions.originalError.statusCode === 400
  ) {
    return true;
  } else {
    return false;
  }
}

export function handleUnauthInterceptor(originalRequest, type) {
  return async (dispatch) => {
    try {
      // Call gql_refresh_token function to get a new token and add it to header
      await dispatch(refreshTokens());

      // Redo the original API call for the request
      const result = await client[type](originalRequest);
      return result;
    } catch (error) {
      console.error("Error refreshing token:", error);
      // handling error
      throw error;
    }
  };
}
// ----------------------------------------------------
export function setLogin(payload) {
  return {
    type: SET_LOGIN,
    payload: payload,
  };
}
export function setSignupStepNo(payload) {
  localStorage.setItem("signup_process_no", payload);
  return {
    type: SET_SIGNUP_STEP_NO,
    payload: payload,
  };
}

export function setToken(payload) {
  return {
    type: SET_TOKEN,
    payload: payload,
  };
}

export function setLogOut() {
  return {
    type: SET_LOGOUT,
  };
}

export function setUserProfile(payload) {
  return {
    type: SET_USER_PROFILE,
    payload: payload,
  };
}

export function setChatTokens(payload) {
  return {
    type: SET_CHAT_TOKENS,
    payload: payload,
  };
}

// save login data to localstorage
export function storeLoginToLocalstorage(data) {
  localStorage.setItem("user_type", data?.user?.user_type);
  localStorage.setItem("bank_id", data?.user?.bank?._id);
  localStorage.setItem("vendor_id", data?.user?.vendor?._id);
  localStorage.setItem("userName", data?.user?.name);
  localStorage.setItem("designation", data?.user?.designation);
  localStorage.setItem("is_premium", data?.user?.is_premium);
  data?.user?.profile_img &&
    localStorage.setItem(
      "userProfile",
      JSON.stringify(data?.user?.profile_img)
    );
  localStorage.setItem(
    "organization_data",
    JSON.stringify(data?.user?.bank || data?.user?.vendor)
  );
  localStorage.setItem(
    "organization_name",
    JSON.stringify(data?.user?.bank?.name || data?.user?.vendor?.name)
  );
  localStorage.setItem("user_id", data?.user?._id);

  localStorage.setItem("userEmail", data?.user?.email);
  localStorage.setItem("signup_process_no", 4);
}

export function login_with_email(email, password, remember) {
  // console.log("REMEMBER", remember);
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/auth/login", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        remember: remember ? "yes" : "no",
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode && result.error) {
          dispatch(set_snack_bar(true, result.message));
        } else {
          dispatch(setSignupStepNo(4));
          storeLoginToLocalstorage(result);
          client.setLink(
            createHttpLink({
              uri: process.env.REACT_APP_GRAPHQL,
              headers: {
                Authorization: `Bearer ${result?.accessToken}`,
              },
            })
          );
          dispatch(setLogin(result));
          // console.log(result);
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(
          set_snack_bar(true, "Failed to login. Check Network connection")
        );
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export const verifyOtp = (token, otp) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      otp,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/verify_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSignupStepNo(3));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
export const resendOtp = (token) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/resend_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // if (responseJson.status) {
        //   dispatch(setSignupStepNo(3));
        // }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
export function postSignupProcessNo(token, signup_process_no) {
  const data = encrypt({
    "user-token": token,
    signup_process_no,
  });
  return (dispatch, getState) => {
    const { login } = getState();
    // console.log(state
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/set_signup_process_no", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson.status);
          // dispatch(setSignupStepNo(2));
          // localStorage.setItem("signup_process_no", 2);

          // dispatch(setLogin(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        if (responseJson.result) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const forgetPassword = (email, domain_name) => {
  return (dispatch) => {
    dispatch(set_loader());

    let url = window.origin;
    let domain_name = window.location.hostname;
    const data = encrypt({
      email: email,
      domain_name,
      url,
    });
    return fetch(UNIVERSAL.BASEURL + "/users/forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const resetForgetPassword = (password, reset_code) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      password,
      reset_code,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/reset_forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

// -------------------- GQL Actions --------------------
export function gql_refresh_token() {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      // refresh token logic
      const { data } = await client.query({
        query: REFRESH_TOKEN,
      });

      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {
            Authorization: `Bearer ${data?.refreshTokens?.accessToken}`,
          },
        })
      );

      storeLoginToLocalstorage(data?.refreshTokens);

      dispatch(setLogin(data?.refreshTokens));
    } catch (error) {
      // handle refresh token error
      // console.error("GraphQL: ", error.message);
      dispatch(set_snack_bar(false, "Token Expired! Please login again"));

      // logout user is error with refreshing token
      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {}, // Empty headers object will remove the Authorization header
        })
      );

      localStorage.clear();
      dispatch(setLogOut());
    }
    dispatch(unset_loader());
  };
}

export function refreshTokens(history) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/auth/refresh", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode && result.error) {
          // console.log(result);
          // console.log(result.message);
          if (result.message !== "Token expired") {
            dispatch(set_snack_bar(false, result.message));
          }
          client.setLink(
            createHttpLink({
              uri: process.env.REACT_APP_GRAPHQL,
              headers: {}, // Empty headers object will remove the Authorization header
            })
          );

          localStorage.clear();
          history?.push("/login");
          dispatch(setLogOut());
        } else {
          client.setLink(
            createHttpLink({
              uri: process.env.REACT_APP_GRAPHQL,
              headers: {
                Authorization: `Bearer ${result?.accessToken}`,
              },
            })
          );
          dispatch(setLogin(result));
        }
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function gql_login_with_email(email, password) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      // login user call
      const { data } = await client.mutate({
        mutation: LOGIN_WITH_EMAIL,
        variables: { email, password },
      });

      dispatch(setSignupStepNo(4));
      // localStorage.setItem("user_token", data?.login?.accessToken);
      storeLoginToLocalstorage(data?.login);
      // localStorage.setItem(
      //   "isDisclaimer",
      //   responseJson.result?.isDisclaimer || false
      // );

      // setting default header for apollo client
      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {
            Authorization: `Bearer ${data?.login?.accessToken}`,
          },
        })
      );

      // console.log(data.login);

      dispatch(setLogin(data.login));

      console.log(data);

      dispatch(set_snack_bar(true, "Login successful"));
    } catch (error) {
      // gql apollo
      console.error("GraphQL: ", error.message);
      dispatch(set_snack_bar(false, error.message));
    }
    dispatch(unset_loader());
  };
}

export function gql_vendor_signup(
  name,
  email,
  organization_name,
  password,
  designation
) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      // login user call
      const result = await client.mutate({
        mutation: SIGNUP,
        variables: { name, email, organization_name, password, designation },
      });

      if (result?.errors && result.errors.length > 0) {
        const error = result.errors[0];
        dispatch(set_snack_bar(true, error.message));
      } else {
        dispatch(setSignupStepNo(2));

        localStorage.setItem("user_id", result?.data?.signup?.userId);
        localStorage.setItem("email_id", email);

        dispatch(set_snack_bar(true, result.data.signup?.message));
      }
    } catch (error) {
      // gql apollo
      console.error("GraphQL: ", error);
      dispatch(set_snack_bar(false, error.message));
    }
    dispatch(unset_loader());
  };
}

export function gpt_signup(
  name,
  email,
  password,
  organization_name,
  designation
) {
  const data = encrypt({
    name,
    email,
    password,
    organization_name,
    designation,
  });
  console.log(email, name, password, organization_name, designation);
  return (dispatch, getState) => {
    const { login } = getState();
    // console.log(state
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/add_gpt_user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson.status);
          dispatch(setSignupStepNo(2));
          localStorage.setItem("user_token", responseJson.result?.user_token);
          localStorage.setItem("bank_id", responseJson.result?.bank_id);
          localStorage.setItem("user_type", responseJson.result?.user_type);
          localStorage.setItem("userName", responseJson.result?.name);
          localStorage.setItem(
            "signup_process_no",
            responseJson.result?.signup_process_no
          );

          responseJson.result?.profile_img &&
            localStorage.setItem(
              "userProfile",
              JSON.stringify(responseJson.result?.profile_img)
            );
          localStorage.setItem(
            "organization_data",
            JSON.stringify(responseJson.result?.organization_data) || `{}`
          );
          localStorage.setItem("user_id", responseJson.result?.user_id);
          localStorage.setItem("userEmail", email);

          localStorage.setItem("chat_tokens", responseJson.result?.tokens);

          console.log("chec for signup data: ", responseJson.result);

          dispatch(setLogin(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        if (responseJson.result) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function verify_otp(id, strOtp) {
  return async (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/auth/otp", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({ otp: strOtp, userId: id }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          dispatch(set_snack_bar(true, result.message));
        } else if (result && result.success) {
          client.setLink(
            createHttpLink({
              uri: process.env.REACT_APP_GRAPHQL,
              headers: {
                Authorization: `Bearer ${result?.accessToken}`,
              },
            })
          );
          dispatch(setSignupStepNo(4));
          storeLoginToLocalstorage(result);
          dispatch(setLogin(result));
          dispatch(set_snack_bar(true, result.message));
        }
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(set_snack_bar(true, error.message));
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function gql_verify_otp(id, strOtp) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const otp = parseInt(strOtp);

      // login user call
      const { data } = await client.mutate({
        mutation: VERIFY_OTP,
        variables: { id, otp },
      });

      dispatch(setSignupStepNo(4));

      // setting default header for apollo client
      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {
            Authorization: `Bearer ${data?.checkOtp?.accessToken}`,
          },
        })
      );

      storeLoginToLocalstorage(data?.checkOtp);
      dispatch(setLogin(data.checkOtp));

      dispatch(set_snack_bar(true, "OTP Verified"));
    } catch (error) {
      // gql apollo
      console.error("GraphQL: ", error);
      dispatch(set_snack_bar(false, error.message));
    }
    dispatch(unset_loader());
  };
}

export function logOut() {
  return async (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/auth/logout", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((result) => {
        // console.log("Logout result", result);
        if (result === "SUCCESS") {
          client.setLink(
            createHttpLink({
              uri: process.env.REACT_APP_GRAPHQL,
              headers: {}, // Empty headers object will remove the Authorization header
            })
          );
          localStorage.clear();
          // console.log("LOGGIN OUT");
          dispatch(setLogOut());
          dispatch(set_snack_bar(true, "Logout success"));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          set_snack_bar(true, "Failed to login. Check Network connection")
        );
      })
      .finally(() => {
        dispatch(unset_loader());
      });
    //   try {
    //     // login user call
    //     const result = await client.query({
    //       query: LOGOUT,
    //       errorPolicy: "all",
    //     });

    //     if (result.errors) {
    //       const isUnAuth = checkIsUnAuth(result.errors[0]);

    //       if (isUnAuth) {
    //         // Call the interceptor function with the original request
    //         await dispatch(
    //           handleUnauthInterceptor(
    //             { query: LOGOUT, errorPolicy: "all" }, // original query
    //             "query" // query type {query || mutation}
    //           )
    //         );
    //       } else {
    //         throw result.errors[0];
    //       }
    //     }

    //     // Remove the Authorization header from the client
    //     client.setLink(
    //       createHttpLink({
    //         uri: process.env.REACT_APP_GRAPHQL,
    //         headers: {}, // Empty headers object will remove the Authorization header
    //       })
    //     );

    //     localStorage.clear();
    //     dispatch(setLogOut());
    //   } catch (error) {
    //     // gql apollo
    //     console.error("GraphQL: ", error);
    //     dispatch(set_snack_bar(false, error.message));
    //   }
    // dispatch(unset_loader());
  };
}

export function gql_forgot_password(email) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const obj = {
        mutation: GQL_FORGOT_PASSWORD,
        variables: {
          email,
        },
      };

      const result = await client.mutate(obj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(obj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);
      dispatch(set_snack_bar(true, "Email sent!"));
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}
