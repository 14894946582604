import {
  SET_ROI_QUESTIONS,
  SET_ROI_RESPONSES,
  SET_ROI_SECTIONS,
} from "../../../constants/roiConstants";

const initialState = {
  roiSections: [],
  roiQuestions: [],
  roiResponses: [],
};

export default function roiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROI_SECTIONS:
      return { ...state, roiSections: action.payload };
    case SET_ROI_QUESTIONS:
      return { ...state, roiQuestions: action.payload };
    case SET_ROI_RESPONSES:
      return { ...state, roiResponses: action.payload };

    default:
      return state;
  }
}
