import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";

import QuestionBody from "../../../../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";

const subText = [
  `Please be aware that a ""yes"" answer may imply your willingness to undergo a further security assessment for your service.`,
  `Please describe the specific access needs and protocols involved. if 'Yes' you maybe required to complete an additional Security Assessment). If ""No"" or ""Not Applicable,"" please provide an explanation. "`,
  `This may include the ability to view, modify, or manage the data. Please provide details of data access requirements and roles (controller/processor). If yes, be aware that your answers will be reviewed by our senior data privacy officer and may trigger a further data privacy assessment due to the sensitivity of the data involved.`,
];

const QuestionsSection = ({
  currentQuestion,
  setCurrentQuestion,
  activeSection,
}) => {
  const [randomIndex, setRandomIndex] = useState(0);
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * subText.length);
    setRandomIndex(randomIndex);
  }, [currentQuestion?.questionId]);

  const showComment = activeSection?.questions?.find(
    (que) => que?._id === currentQuestion?._id
  );

  // console.log(showComment);

  const handleQuesAnswer = (obj) => {
    // Possible values from "obj".
    // const { value, justification, document } = obj;

    console.log(obj);

    if (currentQuestion?.questionType === "MULTICHOICE") {
      setCurrentQuestion((prevQuestion) => ({
        ...prevQuestion,
        submittedAnswer: obj,
      }));
    } else if (
      currentQuestion?.questionType === "DATE_PICKER_SINGLE_DATE" ||
      currentQuestion?.questionType === "DATE_PICKER_RANGE_DATE"
    ) {
      setCurrentQuestion((prevQuestion) => ({
        ...prevQuestion,
        submittedAnswer: {
          startDate: obj?.startDate || null,
          endDate: obj?.endDate || null,
          option: "",
          justificationText: "",
          documentUrl: null,
          weightage: obj?.weightage,
          comment: obj?.comment || null,
        },
      }));
    } else if (currentQuestion?.questionType === "TABLE") {
      setCurrentQuestion((prevQuestion) => ({
        ...prevQuestion,
        submittedAnswer: [],
        tableRows: obj,
      }));
    } else {
      setCurrentQuestion((prevQuestion) => ({
        ...prevQuestion,
        submittedAnswer: {
          option: obj?.value || "",
          weightage: obj?.weightage,
          justificationText: obj?.justification || "",
          documentUrl: obj?.document || null,
          comment: obj?.comment || null,
        },
      }));
    }
  };

  return (
    <Box p={2} style={{ height: "inherit", overflow: "scroll" }}>
      <Box display={"flex"} flexDirection={"column"} sx={{ gap: 10 }}>
        {/* Question */}
        <Box
          style={{
            borderRadius: "8px",
            padding: "16px",
            border: "1px solid #E9E9E9",
            marginBottom: "20px",
          }}
        >
          <Typography
            style={{
              color: "#242424",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {currentQuestion?.question}
          </Typography>
        </Box>

        {/* disclamer */}
        {/* <Typography
          style={{
            color: "#878787",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {subText[randomIndex]}
        </Typography> */}
      </Box>

      {/* options */}
      <Box style={{ padding: "10px" }}>
        {currentQuestion?.linkageAnswer === null &&
        currentQuestion?.linkageId !== null ? (
          <Box>
            <Typography>
              This question is linked to another section, you can skip and
              proceed!
            </Typography>
            <Typography style={{ marginTop: "20px" }}>
              <span style={{ fontWeight: 500 }}>Instructions: </span>{" "}
              {currentQuestion?.hints}
            </Typography>
          </Box>
        ) : (
          <QuestionBody
            questionId={currentQuestion?.questionId}
            questionType={currentQuestion?.questionType}
            answers={currentQuestion?.answers}
            handleQuesAnswer={handleQuesAnswer}
            removeEditOption={currentQuestion?.questionType === "MULTICHOICE"}
            isFrom={"taskSection"}
            linkageAnswer={currentQuestion?.linkageAnswer}
            comment={showComment?.showComment}
            tableHeadersApi={currentQuestion?.tableHeaders}
          />
        )}
      </Box>
    </Box>
  );
};

export default QuestionsSection;
