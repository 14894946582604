import React, { useEffect } from "react";
import CompanyProfile from "../esgCompanyProfile/companyProfile";
import DimentionCharts from "../esgDeclaration/dimentionChart/dimentionCharts";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_default_surveys,
  gql_get_self_assessment_score,
} from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_SELF_ASSESSMENT_SCORE } from "../../../constants/esgDiagnosisConstant";

function SelfAssessmentSurvey(props) {
  const { showSurveys, vendorBasicDetails } = useSelector(
    (state) => state.brmData
  );
  const { selfAssessmentScore } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const { esgScore } = useSelector((state) => state.esgDiagnosisReducer);

  const dispatch = useDispatch();

  const storedVendorId = localStorage.getItem("vendorId");

  useEffect(() => {
    const defaultSurveyId = showSurveys?.find(
      (survey) => survey?.name?.toLowerCase() === "default survey"
    );

    const getScoreInput = {
      vendorId: vendorBasicDetails?._id || storedVendorId,
      surveyId: defaultSurveyId?._id,
    };
    dispatch(gql_get_self_assessment_score(getScoreInput));

    return () => {
      dispatch({ type: SET_SELF_ASSESSMENT_SCORE, payload: {} });
    };
  }, [showSurveys]);

  const usestyles = makeStyles(() => ({
    topicHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "3px 10px",
      position: "relative",
    },
    signleTopicTypeContainer: {
      display: "flex",
      alignItems: "center",
    },
    topicTypeCount: {
      padding: "0px 8px",
      color: "#9FA1AF",
      fontWeight: "600",
    },
    size: { fontSize: 46 },
    Typography: {
      overflowWrap: "anywhere",
      fontWeight: "500",
    },
    typonumb: {
      fintSize: 24,
      fontWeight: 600,
      marginLeft: "auto",
    },
    divider: {
      width: 2,
      height: 22,
      backgroundColor: "#9FA1AF",
      borderRadius: 1,
      margin: "0px 5px",
    },
  }));
  let classes = usestyles();

  const scoreFormatter = (score) => {
    return Math.round(score * 100) / 100;
  };

  return (
    <div className="scroll" style={{ height: "55vh" }}>
      <div style={{ display: "flex", gap: 10 }}>
        <div style={{ width: "40%" }}>
          <BlueBackgroundCard heading={"Assessment Score"} />
          <CompanyProfile
            scores={selfAssessmentScore}
            name={"Self Assessment Score"}
            description={
              "Allow vendors to self-assess their ESG practices and report on their own sustainability efforts. Compare self-reports with actual ESG profiles."
            }
          />
        </div>
        <div style={{ width: "60%" }}>
          <BlueBackgroundCard heading={"Dimentions"} />
          <DimentionCharts isProfile {...props} scores={selfAssessmentScore} />
        </div>
      </div>
      <div style={{ padding: "15px 0px" }}>
        <Grid md={12} container wrap="wrap" spacing={2}>
          {selfAssessmentScore?.score &&
            Object.entries(selfAssessmentScore?.categories).map(
              ([topic, score]) => {
                return (
                  <>
                    {topic === "Environment" && (
                      <Grid item xs={6} md={4}>
                        <div className={classes.signleTopicTypeContainer}>
                          <Environmental className={classes.size} />
                          <div className={classes.divider}></div>

                          <Typography className={classes.Typography}>
                            Environmental
                          </Typography>
                          <Typography className={classes.typonumb}>
                            {scoreFormatter(score)}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                    {topic === "Social Capital" && (
                      <Grid item xs={6} sm={3} md={4}>
                        <div className={classes.signleTopicTypeContainer}>
                          <Social className={classes.size} />
                          <div className={classes.divider}></div>
                          <Typography className={classes.Typography}>
                            Social
                          </Typography>
                          <Typography className={classes.typonumb}>
                            {scoreFormatter(score)}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                    {topic === "Leadership & Governance" && (
                      <Grid item xs={6} sm={3} md={4}>
                        <div className={classes.signleTopicTypeContainer}>
                          <Governance className={classes.size} />
                          <div className={classes.divider}></div>
                          <Typography className={classes.Typography}>
                            Governance
                          </Typography>
                          <Typography className={classes.typonumb}>
                            {scoreFormatter(score)}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                    {topic === "Business Model & Innovation" && (
                      <Grid item xs={6} sm={3} md={4}>
                        <div className={classes.signleTopicTypeContainer}>
                          <Business className={classes.size} />
                          <div className={classes.divider}></div>
                          <Typography className={classes.Typography}>
                            Business
                          </Typography>
                          <Typography className={classes.typonumb}>
                            {scoreFormatter(score)}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                    {topic === "Human Capital" && (
                      <Grid item xs={6} md={4} lg={4}>
                        <div className={classes.signleTopicTypeContainer}>
                          <Human className={classes.size} />
                          <div className={classes.divider}></div>
                          <Typography className={classes.Typography}>
                            Human capital
                          </Typography>
                          <Typography className={classes.typonumb}>
                            {scoreFormatter(score)}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                  </>
                );
              }
            )}
        </Grid>
      </div>
    </div>
  );
}

export default SelfAssessmentSurvey;
