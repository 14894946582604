import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";

import { MainHeader } from "../../screen/vendorAdminScreen";
import TaskLeftPanel from "./TaskSurveyComps/TaskLeftPanel";
import DataRequest from "./DataRequestComps/DataRequest";

// from redux
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_task_sections,
  gql_get_task_sections_for_approvers,
  gql_get_task_sections_for_contributors,
  setTaskSections,
} from "../../../redux/actions/taskActions/TaskAction";
import ContributorMain from "./TaskSurveyComps/RightPanelComps/ContributorRight/ContributorMain";
import ApproverMain from "./TaskSurveyComps/RightPanelComps/ApproverRight/ApproverMain";

const TaskSurveyMain = () => {
  // from redux
  const dispatch = useDispatch();
  const { selectedTask, taskSections } = useSelector(
    (state) => state.taskReducer
  );

  const isDataRequestRoute =
    selectedTask?.type.toLowerCase() === "data_request";

  const isApprover =
    !isDataRequestRoute &&
    selectedTask?.actionType?.toLowerCase() === "approver";

  // stats
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [activeSection, setActiveSection] = useState(
    taskSections[activeSectionIndex]
  );
  const { login } = useSelector((state) => state);
  const { selectedBankDetailsForVa } = useSelector((state) => state?.brmData);

  // console.log(selectedTask);

  // -------------------- Inner Functions --------------------

  // ---------------------------------------------------------

  // -------------------- useEffects() --------------------

  // initial fetch for sections
  useEffect(() => {
    if (
      selectedTask?.type !== "data_request" &&
      selectedTask?.actionType === "Approver"
    ) {
      if (login?.user_type === "BSA") {
        dispatch(
          gql_get_task_sections_for_approvers(
            selectedTask?.frequencyId,
            selectedTask?.survey?._id,
            setActiveSection,
            selectedBankDetailsForVa?._id
          )
        );
      } else {
        dispatch(
          gql_get_task_sections_for_approvers(
            selectedTask?.frequencyId,
            selectedTask?.survey?._id,
            setActiveSection,
            login?.vendorId
          )
        );
      }
    }

    if (
      selectedTask?.type !== "data_request" &&
      selectedTask?.actionType === "Contribution"
    ) {
      if (login?.user_type === "BSA") {
        dispatch(
          gql_get_task_sections_for_contributors(
            selectedTask?.frequencyId,
            selectedTask?.survey?._id,
            setActiveSection,
            selectedBankDetailsForVa?._id
          )
        );
      } else {
        dispatch(
          gql_get_task_sections_for_contributors(
            selectedTask?.frequencyId,
            selectedTask?.survey?._id,
            setActiveSection,
            login?.vendorId
          )
        );
      }
    }

    return () => {
      dispatch(setTaskSections([]));
    };
  }, []);

  // make changes whenever activeSurveyIndex changes
  useEffect(() => {
    setActiveSection(taskSections[activeSectionIndex]);
  }, [activeSectionIndex, taskSections]);

  // --------------------------------------------------------

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: 30, padding: "12px 0px" }}
    >
      {/* Header with Breadcrumbs */}
      <MainHeader mainCrumb={"My Task"} />

      {isDataRequestRoute ? (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DataRequest />
        </Box>
      ) : (
        <Box
          display={"flex"}
          width={"98%"}
          height={"75vh"}
          sx={{ gap: 20, padding: "0px 20px" }}
        >
          {/* Left Panel */}
          <TaskLeftPanel
            taskSections={taskSections}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            setActiveSectionIndex={setActiveSectionIndex}
          />

          {/* Right Panel */}
          {isApprover ? (
            <ApproverMain activeSection={activeSection} />
          ) : (
            <ContributorMain
              activeSection={activeSection}
              activeSectionIndex={activeSectionIndex}
              setActiveSectionIndex={setActiveSectionIndex}
            />
          )}

          {/* <TaskRightPanel
            taskSections={taskSections}
            activeSurvey={activeSurvey}
            setActiveSurvey={setActiveSurvey}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            completedTab={completedTab}
            setCompletedTab={setCompletedTab}
          /> */}
        </Box>
      )}
    </Box>
  );
};
export default TaskSurveyMain;
