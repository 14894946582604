import React, { useState } from "react";
import MoreIcon from "@material-ui/icons/MoreVert";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import CustomButton from "../../UI/button/button";
import logo from "../../../images/logo.svg";
import ImpactGrows from "../../../images/ImpactGrows.png";
import ProfilePic from "../../../images/DP.png";
import AWS from "../../../images/AWS.jpg";
import IndustryLogo from "../../../images/IndustryLogo.png";
import Office from "../../../images/Office.svg";
import UserProfileCard from "../../widgets/userProfileCard/userProfileCard";
import moment from "moment";
// import notificationDrawer from './NotificationDrawer'
import AppBarUseStyles from "./AppBarStyles";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import defaultUser from "../../../images/Default_user.svg";

function CustomAppBar(props) {
  const { isOpen, login, logOut } = props;
  const { token, name, isLoggedIn, email, user_type } = login;
  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const classes = AppBarUseStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleMenuUser = (event) => {
    if (event.currentTarget !== anchorElUser) {
      setAnchorElUser(event.currentTarget);
    }
  };

  function handleUserProfileCard() {
    setAnchorElUser(null);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [organization_data] = useState(
    JSON.parse(localStorage.getItem("organization_data"))
  );
  return (
    <div className={classes.grow}>
      <AppBar position="fixed" color="inherit">
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={11}>
            <Toolbar>
              {/* <img
                style={{
                  width: "200px",
                  height: "72px",
                  objectFit: "contain",
                }}
                src={ImpactGrows}
                alt="logo"
              /> */}
              <div className={classes.grow} />
              <div
                className={classes.sectionDesktop}
                data-tut="reactour__profile_card"
              >
                {isLoggedIn && (
                  <>
                    <div
                      className={classes.userImage}
                      onClick={handleMenuUser}
                      data-tut="reactour__profile_user"
                    >
                      <Avatar
                        size="small"
                        style={{ width: 35, height: 35 }}
                        src={
                          login?.userProfile && login?.userProfile?.url
                            ? login.userProfile?.url
                            : defaultUser
                        }
                      ></Avatar>
                      <UserProfileCard
                        ProfilePic={login?.userProfile?.url}
                        anchorEl={anchorElUser}
                        onClose={handleUserProfileCard}
                        userName={name}
                        userEmail={email}
                        token={token}
                        logOut={logOut}
                        userProfile={login?.userProfile}
                        loginDetails={login}
                      />
                    </div>
                    <div className={classes.border}>
                      <div
                        className={classes.name}
                        onClick={handleMenu}
                        data-tut="reactour__profile"
                      >
                        <Typography
                          variant="subtitle2"
                          style={{ marginRight: "20px" }}
                        >
                          {login?.organization_data?.name ||
                            vendorBasicDetails?.name}
                        </Typography>
                        {/* <Avatar
                          size="small"
                          className={classes.small}
                          src={
                            organization_data?.logo
                              ? organization_data?.logo?.url
                              : "https://imgrows.s3.us-east-2.amazonaws.com/logo/61dbef48ea21d3292041bee0_1677070761831.png"
                          }
                        ></Avatar> */}
                        <Avatar
                          src={
                            login?.user_type === "BA" ||
                            login?.user_type === "BSA"
                              ? login?.organization_data?.logo?.url || Office
                              : login?.user_type === "VA"
                              ? vendorBasicDetails?.logo?.url || Office
                              : vendorBasicDetails?.logo?.url || Office
                          }
                          alt="logo"
                          style={{ width: 25, height: 25 }}
                        />
                      </div>
                      {/* {open && (
                        <ProfileCard
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          userName={userName}
                          userEmail={userEmail}
                          organisationDetails={organisation_details}
                          parent_organisation={parent_organisation}
                          viewAssessments={viewAssessments}
                          surveyListing={surveyListing}
                          token={token}
                          logOut={logOut}
                          viewAssignedAssessments={viewAssignedAssessments}
                          setCurrentOrganzation={setCurrentOrganzation}
                          loginDetails={loginDetails}
                          setCurrentOrganisationUserType={
                            setCurrentOrganisationUserType
                          }
                          setIsNewCompany={setIsNewCompany}
                          setSelectedOraganization={setSelectedOraganization}
                          setSignupProcessNo={setSignupProcessNo}
                        />
                      )} */}
                    </div>
                  </>
                )}
              </div>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
}
export default React.memo(CustomAppBar);
