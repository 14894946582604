import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  makeStyles,
  AppBar,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  Breadcrumbs,
} from "@material-ui/core";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom";

// components imports
import BasicDetailsTab from "./Elements/MainTabs/BasicDetailsTab";
import OldQuestionsTab from "./Elements/MainTabs/OldQuestionsTab";
import ResponsesTab from "./Elements/MainTabs/ResponsesTab";
import SettingsTab from "./Elements/MainTabs/SettingsTab";
import {
  getSurveySectionQuestion,
  setSelectedSection,
} from "../../../../redux/actions/riskdashboard/riskDashboardActions";
import { ActiveCrumbStyle } from "../../../screen/vendorAdminScreen/MainHeader";
import NewQuestionsTab from "./Elements/MainTabs/QuestionsTab";

// styling
const useStyles = makeStyles(() => ({
  AppBar: {
    boxShadow: "none",
    // marginBottom: "20px",
    // marginTop: "auto",
    // border: "solid",
    width: "85%",
    height: "100%",
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  activeTabLabel: {
    color: "#154328",
  },

  // Save Button
  saveButton: {
    position: "absolute",
    bottom: "20px",
    right: "50px",
    textTransform: "none",
    width: "200px",
    height: "36px",
    color: "#fff",
    borderRadius: "8px",
    padding: "8px 16px 8px 16px",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374B9",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
}));

// ----------------- Main Render -----------------
const RiskSurveyInMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const login = useSelector((state) => state.login);
  const { selectedSurveyData } = useSelector((state) => state.riskDashboard);
  // using styles
  const classes = useStyles();

  // State to track the active tab
  const [activeTab, setActiveTab] = useState(0);

  // Component mapping based on the selected tab
  const tabComponents = [
    <BasicDetailsTab />,
    <NewQuestionsTab />,
    <ResponsesTab />,
    // <OldQuestionsTab />,
    // <SettingsTab />,
  ];

  const handleSaveChanges = () => {
    // Add your save changes logic here
    console.log("Save Changes clicked");
  };

  useEffect(() => {
    // dispatch(getSurveySectionQuestion(login?.token))
    // dispatch(
    //   setSelectedSection(
    //     selectedSurveyData?.survey_name === "survey1"
    //       ? "Self Assessment Section"
    //       : "Company Profile"
    //   )
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // Main Container
    <Box>
      {/* Tabs Section */}
      <Box
        style={{
          // border: "solid red",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginBottom: "50px",
          flexDirection: "column",
          gap: 20,
          // height:"60px"
        }}
      >
        {/* breadcrumbs */}
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<IoIosArrowForward style={{ color: "#A1BFDF" }} />}
        >
          <Typography
            color="inherit"
            style={ActiveCrumbStyle(false)}
            onClick={() => {
              history.goBack();
            }}
          >
            {"Surveys"}
          </Typography>

          <Typography color={"inherit"} style={ActiveCrumbStyle(true)}>
            {selectedSurveyData?.name}
          </Typography>
        </Breadcrumbs>

        <MuiThemeProvider>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={activeTab}
              onChange={(e, newVal) => setActiveTab(newVal)}
              style={{
                // marginLeft: "auto",
                // marginRight: "auto",
                // width: "100%",
                // border: "solid yellow",
                alignSelf: "start",
              }}
            >
              <Tab
                disableRipple
                label={
                  <span
                    className={`${classes.tabLabel} ${
                      activeTab === 0 ? classes.activeTabLabel : ""
                    }`}
                  >
                    Basic Details
                  </span>
                }
              />
              <Tab
                disableRipple
                label={
                  <span
                    className={`${classes.tabLabel} ${
                      activeTab === 1 ? classes.activeTabLabel : ""
                    }`}
                  >
                    Questions
                  </span>
                }
              />
              <Tab
                disableRipple
                label={
                  <span
                    className={`${classes.tabLabel} ${
                      activeTab === 2 ? classes.activeTabLabel : ""
                    }`}
                  >
                    Responses
                  </span>
                }
              />
              {/* <Tab
                disableRipple
                label={
                  <span
                    className={`${classes.tabLabel} ${
                      activeTab === 3 ? classes.activeTabLabel : ""
                    }`}
                  >
                    Old Questions
                  </span>
                }
              /> */}
              {/* <Tab
                  disableRipple
                  label={
                    <span
                      className={`${classes.tabLabel} ${
                        activeTab === 3 ? classes.activeTabLabel : ""
                      }`}
                    >
                      Settings
                    </span>
                  }
                /> */}
            </Tabs>
          </AppBar>
        </MuiThemeProvider>
      </Box>

      {/* Active Component */}
      {tabComponents[activeTab]}

      {/* Save Changes Button */}
      {/* <Button
        variant="contained"
        color="primary"
        className={classes.saveButton}
        onClick={handleSaveChanges}
      >
        Save Changes
      </Button> */}
    </Box>
  );
};

export default RiskSurveyInMain;
