import { Bar } from "react-chartjs-2";

const BarChart = ({ data }) => {
  console.log(data);
  const chartData = {
    labels: data?.map((item) => item.month),
    datasets: [
      {
        label: "Monthly Distribution of Articles (2022-23)",
        data: data?.map((item) => item.doc_count),
        backgroundColor: "#A1BFDF",
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
        text: "Monthly Distribution of Articles (2022-23)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months (2022-23)", // X-axis label
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Articles", // Y-axis label
        },
        ticks: {
          precision: 0, // Ensures no decimal places
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
  };

  return (
    <div style={{ height: "100%" }}>
      <Bar data={chartData} options={options} height={200} />
    </div>
  );
};

export default BarChart;
