import { useEffect, useState } from "react";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import moment from "moment";

// icons
import SearchIcon from "@material-ui/icons/Search";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import QuestionTabDrawer from "../../QuestionTabComponents/QuestionTabDrawer";
import CustomTextfield from "../../../../../../../UI/textfield/textfield";
import { useStyles } from "../../QuestionTabComponents/SectionList";
import CustomButton from "../../../../../../../UI/button/button";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_survey_sections,
  gql_survey_version_history,
} from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";

const VersionDrawer = ({ open, handleClose, setSelectedSection }) => {
  const classes = useStyles();

  // from redux
  const dispatch = useDispatch();
  const { selectedSurveyData, usingVersion } = useSelector(
    (state) => state.riskDashboard
  );

  // states
  const [versionHistory, setVersionHistory] = useState();
  const [selectedVersion, setSelectedVersion] = useState(usingVersion);

  console.log(versionHistory);

  const handleVersionChange = async (version) => {
    setSelectedVersion(version);

    // get survey sections based on version
    await dispatch(
      gql_get_survey_sections(
        selectedSurveyData?._id,
        setSelectedSection,
        true,
        version
      )
    );

    handleClose();
  };

  useEffect(() => {
    dispatch(
      gql_survey_version_history(selectedSurveyData?._id, setVersionHistory)
    );
  }, []);

  // console.log(versionHistory);

  return (
    <QuestionTabDrawer
      headerName={"Versions"}
      buttonText={"Select Version"}
      open={open}
      handleClose={handleClose}
    >
      <Box style={{ width: "100%" }}>
        <CustomTextfield
          name="Search"
          variant="outlined"
          fullWidth
          size="small"
          type="text"
          label="All Versions"
          onChange={(e) => {}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#3374B9" }} />
              </InputAdornment>
            ),
          }}
          //   value={}
        />
        <Box
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
          }}
          style={{ width: "100%", marginTop: "10px" }}
        >
          {versionHistory?.toReversed()?.map((v) => (
            <Box
              key={v?.addedOn}
              classes={{
                root: `${classes.root} ${
                  v.version === selectedVersion ? "Mui-selected" : ""
                }`,
                selected: classes.selected,
              }}
              style={{
                border: "1px solid #E0E0E0",
                width: "100%",
                // height: "100px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <AccessTimeIcon style={{ color: "#5C90C7" }} />
                <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                  {moment(parseInt(v?.addedOn)).format("DD MMMM YY")}
                </Typography>
                <CustomButton
                  style={{
                    textTransform: "none",
                    width: "auto",
                    borderRadius: "8px",
                    marginLeft: "auto",
                    marginRight: v.version === selectedVersion ? "10px" : "0px",
                  }}
                  size="small"
                  color="primary"
                  variant="text"
                  onClick={() => {
                    handleVersionChange(v?.version);
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {v?.version === selectedVersion
                      ? "Current Version"
                      : "View"}
                  </Typography>
                </CustomButton>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FiberManualRecordIcon
                  style={{ color: "#32A05F", fontSize: "12px" }}
                />
                <Typography>{v?.addedBy},</Typography>
                <Typography>Version: {v?.version}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </QuestionTabDrawer>
  );
};
export default VersionDrawer;
