import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import tickGreen from "../../../images/TickGreen.png";
import refinitive from "../../../images/refinitive.png";
import riskXchange from "../../../images/riskXchange.png";
import complyAdv from "../../../images/refinitive.png";
import wazuh from "../../../images/wazuh.png";
import vendorIQ from "../../../images/VendorIQ.svg";
import arivu from "../../../images/arivu.png";
import graphene from "../../../images/graphene.png";
import sbt from "../../../images/sbt.png";
import moodys from "../../../images/moodys.png";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },
  dialogTitle: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  dialogTitle1: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  typoCont: { width: "100%" },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
}));
function ApiConnections() {
  const classes = useStyles();

  const data = [
    {
      logo: riskXchange,
      api: "Risk Xchange",
      description:
        "Connect with RiskXChange for real-time risk intelligence. Monitor third-party risks effectively with up-to-date security insights.",
    },
    // {
    //   logo: complyAdv,
    //   api: "Refinitiv",
    //   description:
    //     "Leverage REFINITIV's financial and risk data through API integration. Gain critical insights into market trends and vendor stability..",
    // },
    {
      logo: wazuh,
      api: "Wazuh",
      description:
        "Enhance security with WAZUH's advanced threat detection. Monitor and manage security events across your vendor network in real-time.",
    },
    {
      logo: arivu,
      api: "Arivu",
      description:
        "Gain AI-driven insights from ARIVU's API for predictive risk assessments. Anticipate and mitigate third-party risks proactively.",
    },
    {
      logo: vendorIQ,
      api: "Vendor iQ",
      description:
        "Access deep vendor risk profiles with Vendor iQ's API. Make informed decisions with detailed data analysis and insights.",
    },
  ];

  const esgdata = [
    {
      logo: sbt,
      api: "Science Based Targets",
      description:
        "Align your TPRM strategy with global sustainability goals using this API. Assess vendor environmental impacts and drive sustainable practices.",
    },
    {
      logo: refinitive,
      api: "Refinitiv",
      description:
        "Evaluate vendor ESG compliance with REFINITIV's extensive data. Enhance corporate responsibility with reliable environmental, social, and governance metrics.",
    },
    {
      logo: moodys,
      api: "Moody's",
      description:
        "Assess vendor sustainability and ethics with Moody's ESG API. Obtain detailed scores and reports for responsible decision-making.",
    },
  ];

  const [popup, setPopup] = useState(false);
  const ApiCard = ({ data, setPopup }) => (
    <Grid item xs={4}>
      <div className={classes.paper}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "120px",
              objectFit: "contain",
              height: "55px",
            }}
            src={data.logo}
            alt="logo"
          />
          <Button
            style={{
              backgroundColor: "#32A05F",
              color: "#EBF6EF",
              borderRadius: "8px",
              textTransform: "none",
            }}
            onClick={() => setPopup(true)}
          >
            Connect
          </Button>
        </div>
        <div>
          <Typography
            style={{
              marginTop: 10,
              fontWeight: 600,
              color: "#374151",
              fontSize: 14,
            }}
          >
            {data.api}
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "#A3A8AF",
              marginTop: "5px",
            }}
          >
            {data.description}
          </Typography>
        </div>
      </div>
    </Grid>
  );

  const User = ({ popup, setPopup }) => {
    return (
      <Dialog
        open={popup}
        onClose={() => setPopup(false)}
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            className={classes.dialogTitle1}
          >
            <Grid item xs={4}>
              <Typography className={classes.dialogTitle}>
                Connect With Us
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
            // style={{}}
          >
            <Typography
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#9A9A9A",
                marginBottom: "1em",
              }}
            >
              Fill up the form and our team will get back to you within 48
              hours.
            </Typography>
            {true && (
              <>
                <Grid item className={classes.typoCont}>
                  <label>Email ID</label>
                  <CustomTextfield
                    label="Email ID"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    // onChange={stakeHolderContentChange}
                    helperText=""
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={12} md={12} className={classes.grid3}>
              <CustomButton
                color="primary"
                className={classes.CustomButton3}
                onClick={() => {
                  setPopup(false);
                  // setEmails([]);
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => {
                  setPopup(false);
                }}
              >
                Send
              </CustomButton>
            </Grid>
            {/* <Grid item xs={3} md={3} className={classes.grid6}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => {
                  setPopup(false);
                }}
              >
                Send
              </CustomButton>
            </Grid> */}
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div style={{ height: "63vh" }}>
      {popup && <User popup={popup} setPopup={setPopup} />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
          API Connection
        </Typography>
        <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
          more_vert
        </Icon>
      </div>
      <div className="scroll" style={{ height: "61vh", flexGrow: 1 }}>
        <BlueBackgroundCard heading="Vendor API Connectors" />
        <Grid
          md={12}
          sm={12}
          style={{ display: "flex", spacing: 2, marginTop: 10 }}
          container
        >
          <Grid container spacing={1}>
            {data?.map((dt, i) => (
              <ApiCard key={i} data={dt} setPopup={setPopup} />
            ))}
          </Grid>
        </Grid>
        <BlueBackgroundCard heading="ESG API Connectors" />
        <Grid
          md={12}
          sm={12}
          style={{ display: "flex", spacing: 2, marginTop: 10 }}
          container
        >
          <Grid container spacing={1}>
            {esgdata?.map((dt, i) => (
              <ApiCard data={dt} key={i} setPopup={setPopup} />
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ApiConnections;
