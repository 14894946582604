import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Icon,
  Typography,
  makeStyles,
  Button,
  TextField,
  Modal,
  Fade,
  Backdrop,
  Box,
} from "@material-ui/core";

import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import docIcon from "../../../images/PDF.png";
import blueCircleDoc from "../../../images/blueCircleDoc.png";
import calendar from "../../../images/calendar.png";
import arrow from "../../../images/arrow-right.png";
import pdfDoc from "../../../doc/pdfDoc.pdf";
import samplePdf from "../../../doc/sample.pdf";

import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import CloseIcon from "@material-ui/icons/Close";
import PdfViewer from "./PdfViewer";
import { useDispatch } from "react-redux";
import { SET_DORA_COMPLIANCE_RESULTS } from "../../../constants/esgDiagnosisConstant";
import NoData from "../NoData/NoData";
const DocComplaintCard = ({ doc }) => {
  const { heading, name, date, status, description } = doc;
  return (
    <div style={{ padding: 12 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{ fontSize: 14, fontWeight: 500, padding: "10px 0px" }}
        >
          {heading}
        </Typography>
        <div
          style={{
            backgroundColor: status === "Compliant" ? "#BFE2CD" : "#FFEFD0",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            padding: "3px 8px",
            width: "fit-content",
          }}
        >
          <div
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              marginRight: 2,
              backgroundColor: "#154328",
            }}
          />
          <Typography
            style={{
              fontSize: 11,
              fontWeight: 600,
              color: status === "Compliant" ? "#154328" : "#8C7038",
            }}
          >
            {status}
          </Typography>
        </div>
      </div>
      <Typography
        style={{
          fontSize: 13,
          fontWeight: 400,
          padding: "8px 8px",
          wordBreak: "break",
          border: "1px solid #E9E9E9",
          backgroundColor: "#FCFCFC",
          borderRadius: "6px",
          marginTop: 8,
          width: "100%",
        }}
      >
        {description}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: "transparent",
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transparent",
    // backgroundColor: "rgba(255,255,255,0.4)",
    // backgroundColor: "rgba(0,0,0,0.4)",
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    overflow: "hidden",
    width: "90%",
  },
  data: {
    backgroundColor: theme.palette.background.paper,
    padding: "20px",
    borderRadius: "10px",
    flex: 1,
  },
  pdf: {
    backgroundColor: theme.palette.background.paper,
    padding: "0 20px 20px 20px",
    borderRadius: "10px",
  },
  iframe: {
    /* Custom scrollbar for webkit-based browsers */
    "::-webkit-scrollbar": {
      width: "12px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    /* Custom scrollbar for Firefox */
    "*": {
      scrollbarWidth: "thin", // "auto" or "thin"
      scrollbarColor: "#888 #f1f1f1", // thumb and track color
    },
  },
}));

const data = [
  {
    no: "1",
    name: "Incident Management Process",
    desc: "Define and implement an ICT incident management process for detection, handling, and notification.",
    status: "Compliant",
    ref: "",
    pageNo: 2,
  },
  {
    no: "2",
    name: "Recording and Monitoring",
    desc: "Record all ICT incidents and significant cyber threats.",
    ref: "",
    status: "Not Compliant",
    pageNo: 3,
  },
  {
    no: "3 (a)",
    name: "Incident Management Process Details",
    desc: "Implement early warning indicators.",
    ref: "",
    status: "Compliant",
    pageNo: 4,
  },
  {
    no: "3 (b)",
    name: "Incident Management Process Details",
    desc: "Establish procedures for incident identification, tracking, logging, categorization, and classification based on priority, severity, and criticality.",
    ref: "",
    status: "Compliant",
    pageNo: 1,
  },
  {
    no: "3 (c)",
    name: "Incident Management Process Details",
    desc: "Assign roles and responsibilities for different incident types.",
    ref: "",
    status: "Not Compliant",
    pageNo: 2,
  },
  {
    no: "3 (d)",
    name: "Incident Management Process Details",
    desc: "Develop communication plans for staff, stakeholders, media, and clients, with internal escalation procedures and notification to financial entities",
    ref: "",
    status: "Compliant",
    pageNo: 3,
  },
  {
    no: "3 (e)",
    name: "Incident Management Process Details",
    desc: "Report major incidents to senior management and inform the management body, explaining impact, response, and additional controls",
    ref: "",
    status: "Compliant",
    pageNo: 4,
  },
  {
    no: "3 (f)",
    name: "Incident Management Process Details",
    desc: "Establish incident response procedures for timely service restoration",
    ref: "",
    status: "Compliant",
    pageNo: 1,
  },
  {
    no: "3 (f)",
    name: "Incident Management Process Details",
    desc: "Establish incident response procedures for timely service restoration",
    ref: "",
    status: "Compliant",
    pageNo: 2,
  },
  {
    no: "3 (f)",
    name: "Incident Management Process Details",
    desc: "Establish incident response procedures for timely service restoration",
    ref: "",
    status: "Compliant",
    pageNo: 3,
  },
  {
    no: "3 (f)",
    name: "Incident Management Process Details",
    desc: "Establish incident response procedures for timely service restoration",
    ref: "",
    status: "Compliant",
    pageNo: 4,
  },
  {
    no: "3 (f)",
    name: "Incident Management Process Details",
    desc: "Establish incident response procedures for timely service restoration",
    ref: "",
    status: "Compliant",
    pageNo: 1,
  },
  {
    no: "3 (f)",
    name: "Incident Management Process Details",
    desc: "Establish incident response procedures for timely service restoration",
    ref: "",
    status: "Compliant",
    pageNo: 2,
  },
  {
    no: "3 (f)",
    name: "Incident Management Process Details",
    desc: "Establish incident response procedures for timely service restoration",
    ref: "",
    status: "Compliant",
    pageNo: 3,
  },
];

const doraDocuments = {
  0: {
    "Compliance Item": "Type of service provided 2",
    "Yes/No": "No",
    Source: "Page 1",
  },
  1: {
    "Compliance Item": "Office Address",
    "Yes/No": "Yes",
    Source: "Page 3",
  },
  2: {
    "Compliance Item": "Data protection clauses",
    "Yes/No": "No",
    Source: "Page 2",
  },
  3: {
    "Compliance Item": "Insolvency clauses",
    "Yes/No": "No",
    Source: "Page 7",
  },
  4: {
    "Compliance Item": "SLAs (Service Level Agreements)",
    "Yes/No": "No",
    Source: "Not mentioned",
  },
  5: {
    "Compliance Item":
      "Support to the financial entity in the event of any incident",
    "Yes/No": "No",
    Source: "Not mentioned",
  },
  6: {
    "Compliance Item": "Access for unrestricted inspection and audit",
    "Yes/No": "No",
    Source: "Page 8",
  },
  7: {
    "Compliance Item":
      "Abidance of reporting requirements as per DORA regulations",
    "Yes/No": "No",
    Source: "Not mentioned",
  },
  8: {
    "Compliance Item": "Exit Clauses or Sections",
    "Yes/No": "No",
    Source: "Not mentioned",
  },
  9: {
    "Compliance Item": "Termination Clauses or Sections",
    "Yes/No": "No",
    Source: "Not mentioned",
  },
};

function DoraDocuments({ setDocId, docId, fileData, pdfUrl, pdfName }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [itemName, setItemName] = useState("");

  console.log(pageNumber);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({ type: SET_DORA_COMPLIANCE_RESULTS, Payload: [] });
    };
  }, []);

  const handleOpen = (url) => {
    setDocUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDocUrl("");
    setItemName("");
  };
  const handleViewDoc = (docUrl) => {
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div
              className={classes.paper}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: 20,
                position: "relative",
              }}
            >
              {/* <iframe
                src={docUrl}
                title="PDF_Doc"
                height="700"
                width="600"
                style={{ border: "none", scrollbar: "none" }}
                // className={classes.iframe}
              ></iframe> */}
              <Box className={classes.data}>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    padding: 10,
                    // marginTop: 15,
                    backgroundColor: "#EBF1F8",
                    borderBottom: "1px solid #EBF1F8",
                  }}
                >
                  <Grid item md={1}>
                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                      Item No.
                    </Typography>
                  </Grid>
                  <Grid item md={7}>
                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                      Item
                    </Typography>
                  </Grid>
                  <Grid item md={2} align="center">
                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid item md={2} align="center">
                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                      Reference
                    </Typography>
                  </Grid>
                </Grid>
                <Box style={{ height: 600, overflow: "scroll" }}>
                  {fileData &&
                    Object?.entries(fileData)?.map(([key, value], i) => (
                      <Grid
                        key={i}
                        container
                        justifyContent="space-between"
                        style={{
                          padding: 10,
                          backgroundColor: "#FBFBFB",
                          borderBottom: "1px solid #EBF1F8",
                        }}
                      >
                        <Grid item md={1}>
                          <Typography style={{ fontSize: 13, fontWeight: 400 }}>
                            {parseInt(key) + 1}
                          </Typography>
                        </Grid>
                        <Grid item md={7}>
                          <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                            {value?.["Compliance Item"]}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              color: "#676767",
                              fontWeight: 400,
                            }}
                          >
                            {value?.desc}
                          </Typography>
                        </Grid>
                        <Grid item md={2} align="center">
                          <div
                            style={{
                              backgroundColor:
                                value?.["Yes/No"] === "Yes"
                                  ? "#BFE2CD"
                                  : "#FFEFD0",
                              borderRadius: "13px",
                              display: "flex",
                              alignItems: "center",
                              padding: "3px 8px",
                              width: "fit-content",
                            }}
                          >
                            <div
                              style={{
                                width: "4px",
                                height: "4px",
                                borderRadius: "50%",
                                marginRight: 2,
                                backgroundColor:
                                  value?.["Yes/No"] === "Yes"
                                    ? "#154328"
                                    : "#8C7038",
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: 11,
                                fontWeight: 600,
                                color:
                                  value?.["Yes/No"] === "Yes"
                                    ? "#154328"
                                    : "#8C7038",
                              }}
                            >
                              {value?.["Yes/No"] === "Yes"
                                ? "Compliant"
                                : "Non-Compliant"}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          align="center"
                          // disabled={
                          //   value?.Source === "Not mentioned" ? true : false
                          // }
                        >
                          <Typography
                            coloe="primary"
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                              color:
                                itemName === value?.["Compliance Item"]
                                  ? "#fff"
                                  : "#3374B9",
                              cursor: "pointer",
                              // outline:
                              //   itemName === key?.["Compliance Item"]
                              //     ? "2px solid #3374B9"
                              //     : "none",
                              borderRadius: "10px",
                              backgroundColor:
                                itemName === value?.["Compliance Item"]
                                  ? "#3374B9"
                                  : "transparent",
                            }}
                            onClick={() => {
                              setPageNumber(() => {
                                const num = +value?.Source?.match(/\d+/)
                                  ? +value?.Source?.match(/\d+/)[0]
                                  : NaN;
                                return num;
                              });
                              setItemName(value?.["Compliance Item"]);
                            }}
                          >
                            View
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Box>
              </Box>
              <Box className={classes.pdf} style={{ position: "relative" }}>
                <CloseIcon
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 20,
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
                <PdfViewer
                  docUrl={docUrl}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </Box>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  return (
    <div style={{ height: "63vh" }}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Dora Compliance
        </Typography>
        <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
          more_vert
        </Icon>
      </div> */}

      <div
        className="scroll"
        style={{ height: "63vh", marginTop: "10px", paddingBottom: 20 }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between",
              justifyContent: "start",
            }}
          >
            <ArrowBackRoundedIcon
              style={{ cursor: "pointer", marginRight: 10, fontSize: 22 }}
              onClick={() => setDocId(null)}
            />
            <img src={blueCircleDoc} alt="docIcon" style={{ width: 28 }} />
            <div style={{ width: "65%", paddingLeft: 8 }}>
              <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                {pdfName}
              </Typography>
              {/* <Typography
                style={{ fontSize: 12, color: "#676767", fontWeight: 400 }}
              >
                Refers to an adverse event in an information , communication and
                technology system, and/or network, or the threat of the
                occurrence of such an event.
              </Typography> */}
            </div>
            {/* <div
              style={{ display: "flex", alignItems: "center", width: "30%" }}
            >
              <img src={calendar} alt="" style={{ width: 20 }} />
              <Typography
                style={{ fontSize: 12, padding: 2, wordBreak: "no-break" }}
              >
                23rd Aug 2030
              </Typography>
              <img src={arrow} alt="" style={{ width: 20 }} />
              <Typography
                style={{ fontSize: 12, padding: 2, wordBreak: "no-break" }}
              >
                23rd Aug 2030
              </Typography>
            </div>
            <Typography
              style={{
                fontSize: 12,
                padding: 2,
                wordBreak: "no-break",
                width: "8%",
              }}
            >
              <b>12</b> times
            </Typography> */}
          </div>
        </div>

        <Grid
          container
          justifyContent="space-between"
          style={{
            padding: 10,
            marginTop: 15,
            backgroundColor: "#EBF1F8",
            borderBottom: "1px solid #EBF1F8",
          }}
        >
          <Grid item md={1}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Item No.
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Item
            </Typography>
          </Grid>
          <Grid item md={2} align="center">
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Status
            </Typography>
          </Grid>
          <Grid item md={2} align="center">
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Reference
            </Typography>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {fileData &&
            Object?.entries(fileData)?.map(([key, value], i) => (
              <Grid
                key={i}
                container
                justifyContent="space-between"
                style={{
                  padding: 10,
                  backgroundColor: "#FBFBFB",
                  borderBottom: "1px solid #EBF1F8",
                }}
              >
                <Grid item md={1}>
                  <Typography style={{ fontSize: 13, fontWeight: 400 }}>
                    {parseInt(key) + 1}
                  </Typography>
                </Grid>
                <Grid item md={7}>
                  <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                    {value?.["Compliance Item"]}
                  </Typography>
                  <Typography
                    style={{ fontSize: 12, color: "#676767", fontWeight: 400 }}
                  >
                    {value?.desc}
                  </Typography>
                </Grid>
                <Grid item md={2} align="center">
                  <div
                    style={{
                      backgroundColor:
                        value?.["Yes/No"] === "Yes" ? "#BFE2CD" : "#FFEFD0",
                      borderRadius: "13px",
                      display: "flex",
                      alignItems: "center",
                      padding: "3px 8px",
                      width: "fit-content",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "4px",
                        borderRadius: "50%",
                        marginRight: 2,
                        backgroundColor:
                          value?.["Yes/No"] === "Yes" ? "#154328" : "#8C7038",
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: 11,
                        fontWeight: 600,
                        color:
                          value?.["Yes/No"] === "Yes" ? "#154328" : "#8C7038",
                      }}
                    >
                      {value?.["Yes/No"] === "Yes"
                        ? "Compliant"
                        : "Non-Compliant"}
                    </Typography>
                  </div>
                </Grid>
                <Grid item md={2} align="center">
                  <Typography
                    coloe="primary"
                    style={{
                      fontSize: 13,
                      fontWeight: 500,
                      color: "#3374B9",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleOpen(pdfUrl);
                    }}
                  >
                    View
                  </Typography>
                </Grid>
              </Grid>
            ))}
          {open && handleViewDoc(docUrl)}
          {!fileData && (
            <NoData message={"No data is there to be shown"}></NoData>
          )}
        </div>

        {/* {[
          {
            heading: "Incident Management Process",
            name: "Document Name 1",
            date: " 24th Aug 2019",
            status: "Compliant",
            description: `Article 12: The Incident Response Plan (IRP) is a crucial component of
            an organization's cybersecurity strategy, designed to effectively and
            efficiently manage and mitigate security incidents. It serves as a
            structured approach for identifying, containing, eradicating, recovering
            from, and learning from security breaches or unexpected events that may
            impact the confidentiality, integrity, or availability of data and
            systems.`,
          },
          {
            heading: "Document Retention and Destruction",
            name: "Document Name 1",
            date: " 24th Aug 2019",
            status: "Compliant",
            description: `Article 124a : Document Retention and Destruction is a critical aspect of an organization's compliance strategy, focusing on the systematic management, storage, and secure disposal of business records. This process involves establishing policies and procedures to govern the lifecycle of documents, ensuring that information is retained for the necessary duration to meet legal, regulatory, and operational requirements while also being securely destroyed when it is no longer needed.`,
          },
        ].map((doc, i) => (
          <DocComplaintCard doc={doc} key={i} />
        ))} */}
      </div>
    </div>
  );
}

export default DoraDocuments;
