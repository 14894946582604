import { useEffect } from "react";
import ApproverQuestions from "./ApproverComps/ApproverQuestions";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_task_questions_for_approver } from "../../../../../../redux/actions/taskActions/TaskAction";
import { SET_TASK_QUESTIONS } from "../../../../../../constants/taskConstants";

const ApproverMain = ({ activeSection }) => {
  const dispatch = useDispatch();

  const { taskQuestions, selectedTask } = useSelector(
    (state) => state.taskReducer
  );

  const { vendorBasicDetails } = useSelector((state) => state.brmData);

  const { login } = useSelector((state) => state);
  const { selectedBankDetailsForVa } = useSelector((state) => state?.brmData);

  const { isApproved } = useSelector((state) => state.threadReducer);

  useEffect(() => {
    if (activeSection?._id && (isApproved || !isApproved)) {
      if (login?.user_type === "BSA") {
        dispatch(
          gql_get_task_questions_for_approver(
            selectedTask?.frequencyId,
            activeSection?._id,
            selectedBankDetailsForVa?._id,
            login?.bankId
          )
        );
      } else {
        dispatch(
          gql_get_task_questions_for_approver(
            selectedTask?.frequencyId,
            activeSection?._id,
            login?.vendorId,
            selectedBankDetailsForVa?._id
          )
        );
      }
    }

    // return () => {
    //   dispatch({ type: SET_TASK_QUESTIONS, payload: [] });
    // };
  }, [activeSection?._id, isApproved]);

  return (
    <>
      <ApproverQuestions
        taskQuestions={taskQuestions}
        frequencyId={selectedTask?.frequencyId}
        activeSectionId={activeSection?._id}
      />
    </>
  );
};
export default ApproverMain;
