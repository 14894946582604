import { SET_VENDOR_DASHBOARD_DATA } from "../../../constants/brmConstants";
import { GET_VENDOR_DASHBOARD_QUERY } from "../../graphql/gql_VendorDashboardQueries";
import { client } from "../../store/store";
import { set_loader, unset_loader } from "../loader/loader_action";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";

const setVendorDashboardData = (payload) => {
  return {
    type: SET_VENDOR_DASHBOARD_DATA,
    payload,
  };
};

export function gql_get_vendor_dashboard_data() {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.query({
        query: GET_VENDOR_DASHBOARD_QUERY,
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              { query: GET_VENDOR_DASHBOARD_QUERY },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }
      const data = {
        vendorCount: result.data.dashboardData.count,
        vendors: result.data.dashboardData.vendors,
        sectorCount: {
          industries: Object.keys(result.data.dashboardData.groupedByIndustry),
          values: Object.keys(result.data.dashboardData.groupedByIndustry).map(
            (key) => result.data.dashboardData.groupedByIndustry[key]
          ),
        },
        avgEsgRisk: result.data.dashboardData.avgEsgRisk,
        avgVendorRisk: result.data.dashboardData.avgVendorRisk,

        vendorRiskGroupCount: result.data.dashboardData.vendorRiskGroupCount,
        esgRiskGroupCount: result.data.dashboardData.esgRiskGroupCount,
        overallRiskPerformance:
          result.data.dashboardData.overallRiskPerformance,
      };
      await dispatch(setVendorDashboardData(data));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}
