import Box from "@material-ui/core/Box";

import { TaskHeader, TaskMain } from "../../components/screen/taskScreen";

const taskContainer = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      style={{ padding: "0 20px", gap: 30 }}
    >
      {/* Dynamic Header */}
      <TaskHeader hidePagination={false} hideSearch />
      {/* Main Section */}
      <TaskMain />
    </Box>
  );
};
export default taskContainer;
