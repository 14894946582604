import {
  AppBar,
  Box,
  createTheme,
  Grid,
  Icon,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import docIcon from "../../../images/PDF.png";
import blueCircleDoc from "../../../images/blueCircleDoc.png";
import calendar from "../../../images/calendar.png";
import arrow from "../../../images/arrow-right.png";
import TabPanel from "../../UI/tabPanel/tabPanel";
import PublicReports from "../publicInfo/publicReports";
import { useDispatch, useSelector } from "react-redux";
import Analytics from "../../../images/Analytics.png";
import DoraDocuments from "./DoraDocuments";
import {
  gql_get_dora_compliance_files,
  gql_get_dora_compliance_results,
} from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_DORA_COMPLIANCE_RESULTS } from "../../../constants/esgDiagnosisConstant";
import { PaymentOutlined } from "@material-ui/icons";

const docStyles = makeStyles((theme) => ({
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },

  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },

  endButton: {
    position: "fixed",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    textTransform: "capitalize",
    marginRight: "5px",
    fontSize: "14px",
    padding: 10,
    color: "#fff",
    backgroundColor: "#3374B9",
    "&:hover": {
      backgroundColor: "#3374D9",
      boxShadow: "1px 1px 3px #3374B9",
    },
  },
}));

export const appbarStyles = makeStyles((theme) => ({
  AppBar: { boxShadow: "none" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
}));

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#3374B9",
    },
    secondary: {
      main: "#47BB76",
    },
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});

function DoraCompilance() {
  const { token } = useSelector((state) => state.login);
  const [docId, setDocId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfName, setPdfName] = useState("");

  const { vendorBasicDetails } = useSelector((state) => state.brmData);
  const { doraComplianceFiles, doraComplianceResults } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  // console.log(doraComplianceResults);

  const customDoraResults =
    doraComplianceResults?.length > 0 ? [...doraComplianceResults] : [];

  const doraDocuments = [
    {
      uploadedAt: "2024-05-20T10:43:25.286Z",
      uploadedBy: {
        name: "Million VA",
        profileImg: {
          name: "",
          type: "",
          url: "",
        },
      },

      document: {
        name: "esfb_05122023185918_BRSRReportfortheFinancialYear202223.pdf",
        type: "application/pdf",
        url: "https://imgrows.s3.amazonaws.com/submit_survey_docs/664b28e60d77a20849a1e390_1716201797420.pdf",
      },
      _id: 1,
    },
    {
      uploadedAt: "2024-05-20T10:43:32.746Z",
      uploadedBy: {
        name: "Million VA",
        profileImg: {
          name: "",
          type: "",
          url: "",
        },
      },
      document: {
        name: "6634da081386949de038c6b8_1714740523854.pdf",
        type: "application/pdf",
        url: "https://imgrows.s3.amazonaws.com/submit_survey_docs/664b28f40d77a20849a1e39f_1716201811079.pdf",
      },
      _id: 2,
    },
    {
      uploadedAt: "2024-05-31T09:27:49.103Z",
      uploadedBy: {
        name: "Million VA",
        profileImg: {
          name: "",
          type: "",
          url: "",
        },
      },
      document: {
        name: "664b28f40d77a20849a1e39f_1716201811079.pdf",
        type: "application/pdf",
        url: "https://imgrows.s3.amazonaws.com/submit_survey_docs/6650242e51f2550131cb2805_1717147660064.pdf",
      },
      _id: 3,
    },
  ];

  const classes = docStyles();
  const tabClasses = appbarStyles();

  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const storedVendorIsin = localStorage.getItem("vendorIsin");

  useEffect(() => {
    if (tab === 0) {
      dispatch(
        gql_get_dora_compliance_files(
          vendorBasicDetails?.isin || storedVendorIsin,
          "Contract"
        )
      );
    } else if (tab === 1) {
      dispatch(
        gql_get_dora_compliance_files(
          vendorBasicDetails?.isin || storedVendorIsin,
          "Policy"
        )
      );
    }
  }, []);

  const handleSelectedDoc = (id, pdfUrl, pdfName) => {
    setDocId(id);
    dispatch(gql_get_dora_compliance_results(id));
    setPdfUrl(pdfUrl);
    setPdfName(pdfName);

    console.log(id, pdfUrl, pdfName);
  };

  const handleTabChange = async (event, newValue) => {
    setTab(newValue);
    if (newValue === 0) {
      dispatch(
        gql_get_dora_compliance_files(
          vendorBasicDetails?.isin || storedVendorIsin,
          "Contract"
        )
      );
    } else if (newValue === 1) {
      dispatch(
        gql_get_dora_compliance_files(
          vendorBasicDetails?.isin || storedVendorIsin,
          "Policy"
        )
      );
    }
  };

  const nameFormat = (name) => {
    if (name === "incidentPolicy") {
      return "Incident Policy";
    }
    if (name === "tspsPolicy") {
      return "TSPS Policy";
    }
    if (name === "isPolicy") {
      return "IS Policy";
    }
  };

  return (
    <>
      {docId === null && (
        <div style={{ height: "63vh" }}>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Document Compliance
          </Typography>
          {/* <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
            more_vert
          </Icon> */}

          <MuiThemeProvider theme={appTheme}>
            <AppBar
              position="static"
              color="inherit"
              className={tabClasses.AppBar}
            >
              {
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab
                    disableRipple
                    className={tabClasses.tab}
                    label={
                      <span className={tabClasses.tabLabel}>Contract</span>
                    }
                  />

                  <Tab
                    disableRipple
                    className={tabClasses.tab}
                    label={<span className={tabClasses.tabLabel}>Policy</span>}
                  />
                </Tabs>
              }
            </AppBar>
          </MuiThemeProvider>
          {doraComplianceFiles?.contractFiles?.length > 0 && tab === 0 ? (
            <>
              <TabPanel>
                <PublicReports
                  publicDocuments={doraComplianceFiles?.contractFiles}
                  isFrom={"doraDocuments"}
                  handleSelectedDoc={handleSelectedDoc}
                />
              </TabPanel>
            </>
          ) : tab === 1 ? (
            <Box className="scroll">
              {Object.entries(doraComplianceFiles)?.map(([key, value]) => (
                <>
                  {value?.length > 0 && (
                    <TabPanel>
                      <BlueBackgroundCard heading={nameFormat(key)} />
                      <PublicReports
                        publicDocuments={value}
                        isFrom={"doraDocuments"}
                        handleSelectedDoc={handleSelectedDoc}
                      />
                    </TabPanel>
                  )}
                </>
              ))}

              {doraComplianceFiles?.incidentPolicy?.length !== 0 ||
                doraComplianceFiles?.tspsPolicy?.length !== 0 ||
                doraComplianceFiles?.isPolicy?.length !== 0 || (
                  <div className={classes.emptyCont}>
                    <img
                      className={classes.emptyImg}
                      src={Analytics}
                      alt="analytics"
                    />
                    <Typography className={classes.text}>
                      Currently no file has been uploaded to showcase
                    </Typography>
                  </div>
                )}
            </Box>
          ) : (
            <div className={classes.emptyCont}>
              <img
                className={classes.emptyImg}
                src={Analytics}
                alt="analytics"
              />
              <Typography className={classes.text}>
                Currently no file has been uploaded to showcase
              </Typography>
            </div>
          )}
        </div>
      )}

      {docId !== null && (
        <DoraDocuments
          setDocId={setDocId}
          docId={docId}
          pdfUrl={pdfUrl}
          pdfName={pdfName}
          fileData={customDoraResults[0]?.data}
        />
      )}
    </>
  );
}

export default DoraCompilance;
