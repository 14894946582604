import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const data = [
  {
    id: 1,
    vendorName: "Prolender",
    serviceType: "Software",
    security: "Low",
    esgRisk: "Low",
    vendorRisk: "Low",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 2,
    vendorName: "AWS",
    serviceType: "Cloud",
    security: "Medium",
    esgRisk: "Medium",
    vendorRisk: "High",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 3,
    vendorName: "Oracle",
    serviceType: "Software",
    security: "Low",
    esgRisk: "Low",
    vendorRisk: "Low",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 4,
    vendorName: "S&P Global",
    serviceType: "Software",
    security: "Medium",
    esgRisk: "Medium",
    vendorRisk: "Medium",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 5,
    vendorName: "Dun & Bradstreet",
    serviceType: "Datasets",
    security: "High",
    esgRisk: "High",
    vendorRisk: "Low",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 6,
    vendorName: "Dell",
    serviceType: "Datasets",
    security: "Medium",
    esgRisk: "Medium",
    vendorRisk: "Low",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 7,
    vendorName: "Fortinet",
    serviceType: "Datasets",
    security: "Low",
    esgRisk: "Low",
    vendorRisk: "High",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 8,
    vendorName: "Cisco",
    serviceType: "Hardware",
    security: "Medium",
    esgRisk: "Medium",
    vendorRisk: "Low",
    viewVendor: "https://imgrows.com",
  },
  {
    id: 9,
    vendorName: "Azure",
    serviceType: "Software",
    security: "Low",
    esgRisk: "Low",
    vendorRisk: "Low",
    viewVendor: "https://imgrows.com",
  },
];

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

export default function VendorList({ vendors, onClick }) {
  const classes = useStyle();

  function severity(value, type) {
    if (type === "text") {
      return value === "Low"
        ? "#154328"
        : value === "Medium"
        ? "#8C7038"
        : value === "High"
        ? "#52231D"
        : "#154328";
    }

    if (type === "bg") {
      return value === "Low"
        ? "#BFE2CD"
        : value === "Medium"
        ? "#FFEFD0"
        : value === "High"
        ? "#EDCAC6"
        : "#BFE2CD";
    }
  }

  return (
    <Paper elevation={3}>
      <Box sx={{ margin: "10px 0px" }}>
        <Box className={classes.tableHeader}>
          <Typography variant="h6" className={classes.tableHeaderTitle}>
            Vendor List
          </Typography>
          {/* <Box className={classes.tableHeaderDorpdown}>
            <FormControl
              style={{ width: "200px" }}
              variant="outlined"
              size="small"
            >
              <InputLabel id="menu">DropDown</InputLabel>
              <Select name="drop-down" labelId="menu" label="drop-down">
                <MenuItem value="mongodb">MongoDB</MenuItem>
                <MenuItem value="node">Node</MenuItem>
                <MenuItem value="react">React</MenuItem>
                <MenuItem value="express">Express</MenuItem>
              </Select>
            </FormControl>
            <MoreVertIcon color="primary" />
          </Box> */}
        </Box>

        <TableContainer component="paper">
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell align="left">Vendor Name</TableCell>
                <TableCell align="left">Service Type</TableCell>
                {/* <TableCell align="left" width={200}>
                  Security
                </TableCell> */}
                <TableCell align="left" width={200}>
                  ESG Risk
                </TableCell>
                <TableCell align="left" width={200}>
                  Vendor Risk
                </TableCell>
                <TableCell align="center">View Vendor</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {vendors?.map((data, index) => (
                <TableRow
                  key={data?._id}
                  className={classes.tableRow}
                  onClick={() => console.log(data.id)}
                >
                  {/* <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell> */}
                  <TableCell align="left">{data.name}</TableCell>
                  <TableCell align="left">
                    {data.sector.sector.sector}
                  </TableCell>
                  {/* <TableCell align="left">
                    <Typography
                      variant="body2"
                      style={{
                        color: severity(data.security, "text"),
                        backgroundColor: severity(data.security, "bg"),
                      }}
                      className={classes.severityStyle}
                    >
                      {data.security || "Low"}
                    </Typography>
                  </TableCell> */}
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      style={{
                        color: severity(data.esgRisk, "text"),
                        backgroundColor: severity(data.esgRisk, "bg"),
                      }}
                      className={classes.severityStyle}
                    >
                      {data.esgRisk || "Low"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      style={{
                        color: severity(data.vendorRisk, "text"),
                        backgroundColor: severity(data.vendorRisk, "bg"),
                      }}
                      className={classes.severityStyle}
                    >
                      {data.vendorRisk || "Low"}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={() => onClick(data)} align="center">
                    View
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}
