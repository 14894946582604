export const SET_VENODR_TAB_NO = "SET_VENODR_TAB_NO";
export const SET_VENODR_SUB_TAB_NO = "SET_VENODR_SUB_TAB_NO";

export const SET_CREATE_VENDOR_IMG = "SET_CREATE_VENDOR_IMG";

export const SET_SELECTED_VA_SURVEY = "SET_SELECTED_VA_SURVEY";
export const SET_STEP2_SECTIONS = "SET_STEP2_SECTIONS";
export const SET_STEP2_SEC_QUESTIONS = "SET_STEP2_SEC_QUESTIONS";

export const SET_STEP3_SECTIONS = "SET_STEP3_SECTIONS";
export const SET_STEP3_SEC_QUESTIONS = "SET_STEP3_SEC_QUESTIONS";

export const SET_COMPLETED_SEC_QUES = "SET_COMPLETED_SEC_QUES";

export const SET_VENDOR_UPLOADED_FILES = "SET_VENDOR_UPLOADED_FILES";
export const SET_VENDOR_UPLOADED_FILES_IN_SURVEY =
  "SET_VENDOR_UPLOADED_FILES_IN_SURVEY";
export const SET_VENDOR_UPLOADED_FILES_STATUS =
  "SET_VENDOR_UPLOADED_FILES_STATUS";
