import React, { useEffect } from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import ControversyChart from "./ChartSection";
import InterestMap from "./MapSection";
import { useDispatch, useSelector } from "react-redux";
import { rest_get_contro_insights } from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { Box, Typography } from "@material-ui/core";

export default function Insights() {
  const { vendorBasicDetails } = useSelector((state) => state?.brmData);
  const { controInsights } = useSelector((state) => state?.esgDiagnosisReducer);
  const dispatch = useDispatch();

  const storedVendorIsin = localStorage.getItem("vendorIsin");

  useEffect(() => {
    dispatch(
      rest_get_contro_insights(vendorBasicDetails?.isin || storedVendorIsin)
    );
  }, []);

  return (
    <div>
      {/* chart section */}
      <BlueBackgroundCard heading={"Digital Reputation Over Time"} />

      <ControversyChart finalData={controInsights?.final_dates} />

      {/* map section */}
      <BlueBackgroundCard heading={"Interest by Region"} />

      <InterestMap />
    </div>
  );
}
