import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";

import HighlightComp from "./DrawerMainInner/HighlightComp";
import MessageBox from "./DrawerMainInner/MessageBox";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import SubmissionBox from "./DrawerMainInner/SubmissionBox";
import { useSelector, useDispatch } from "react-redux";
import { getReceivedMessages } from "../../../../../../../redux/actions/threadActions/ThreadActions";
import QuestionBody from "../../../../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    // background: "#999",
    height: "100vh",
    overflow: "scroll",
    margin: "10px 0",
  },
  conversation: {
    marginTop: "auto",
  },
}));

const DrawerMain = ({
  msg,
  setMsg,
  roomId,
  userId,
  setContributor,
  contributor,
  selectedQues,
}) => {
  const classes = useStyles();
  const bottomMessageRef = useRef(null);
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.threadReducer);

  // console.log(contributor);

  useEffect(() => {
    dispatch(getReceivedMessages(roomId));
  }, [roomId]);

  useEffect(() => {
    if (bottomMessageRef.current) {
      bottomMessageRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "end",
      });
    }
  }, [msg]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      // console.log("setting msg", messages);
      setMsg(messages);
    }
  }, [messages]);

  // console.log(contributor?.next?.answer[0]?.comment !== "");
  return (
    <>
      {contributor && (
        <Box
          style={{
            // border: "#e0e0e0e0 solid 1px",
            // borderRadius: "10px",
            margin: "10px 0",
            padding: "10px",
            overflow: "scroll",
            // height: "100vh",
          }}
        >
          <QuestionBody
            answers={selectedQues?.answers}
            questionType={selectedQues?.questionType}
            submittedAnswer={
              selectedQues?.questionType === "TABLE"
                ? contributor?.next?.submittedTableRows
                : contributor?.next?.answer
            }
            removeEditOption={selectedQues?.questionType === "MULTICHOICE"}
            event={"none"}
            isFrom={"approvalSection"}
            comment={
              selectedQues?.questionType === "TABLE"
                ? contributor?.next?.submittedTableRows &&
                  contributor?.next?.submittedTableRows[0][0]?.comment !== "" &&
                  contributor?.next?.submittedTableRows[0][0]?.comment !== null
                : contributor?.next?.answer &&
                  contributor?.next?.answer[0]?.comment !== "" &&
                  contributor?.next?.answer[0]?.comment !== null
            }
            tableHeadersApi={contributor?.next?.tableHeaders}
          />
        </Box>
      )}

      {!contributor && (
        <Box className={classes.container}>
          <Box className={classes.conversation}>
            {msg?.map((e, index) =>
              e?.next ? (
                <SubmissionBox
                  key={index}
                  userId={userId}
                  data={e}
                  setContributor={setContributor}
                />
              ) : (
                <MessageBox
                  key={index}
                  data={e}
                  id={index}
                  userId={userId}
                  contributor={contributor}
                />
              )
            )}
            <div ref={bottomMessageRef}></div>
          </Box>
        </Box>
      )}
    </>
  );
};
export default DrawerMain;
