import React, { useEffect, useState } from "react";
import StepLayOut from "../stepLayout";
import Step3Left from "./left/Step3Left";
import Step3Right from "./right/Step3Right";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_step3_section_questions,
  gql_get_step3_survey_sections,
  setStep3SecQues,
} from "../../../../../redux/actions/vendorActions/vendorAction";

function SurveyStep3({ response }) {
  // from redux
  const dispatch = useDispatch();
  const { step3Sections, step3SecQuestions, selectedVASurvey } = useSelector(
    (state) => state.vendorReducer
  );
  const { selectedBankDetailsForVa } = useSelector((state) => state.brmData);
  const { login } = useSelector((state) => state);

  // states
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedSection, setSelectedSection] = useState(null);
  const [sectionQuestions, setSectionQuestions] = useState(step3SecQuestions);

  // --------------- Inner Functions ---------------
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };

  // -----------------------------------------------

  // --------------- useEffects() ---------------

  // on page load useEffect
  useEffect(() => {
    // get left sections
    if (login?.user_type === "BSA") {
      dispatch(
        gql_get_step3_survey_sections(
          selectedVASurvey?.frequencyId,
          selectedVASurvey?.surveyId,
          selectedBankDetailsForVa?._id
        )
      );
    } else {
      dispatch(
        gql_get_step3_survey_sections(
          selectedVASurvey?.frequencyId,
          selectedVASurvey?.surveyId,
          login?.vendorId
        )
      );
    }
  }, []);

  // on section change
  useEffect(() => {
    // update Section based Question when selected section changes
    if (selectedSection?._id) {
      if (login?.user_type === "BSA") {
        dispatch(
          gql_get_step3_section_questions(
            selectedVASurvey?.frequencyId,
            selectedSection?._id,
            setSectionQuestions,
            login?.bankId,
            selectedBankDetailsForVa?._id
          )
        );
      } else {
        dispatch(
          gql_get_step3_section_questions(
            selectedVASurvey?.frequencyId,
            selectedSection?._id,
            setSectionQuestions,
            selectedBankDetailsForVa?._id,
            login?.vendorId
          )
        );
      }
    }
    return () => {
      dispatch(setStep3SecQues(null));
    };
  }, [selectedSection]);

  const handleWidgetsStatusChange = () => {
    setTimeout(() => {
      if (login?.user_type === "BSA") {
        dispatch(
          gql_get_step3_survey_sections(
            selectedVASurvey?.frequencyId,
            selectedVASurvey?.surveyId,
            selectedBankDetailsForVa?._id
          )
        );

        dispatch(
          gql_get_step3_section_questions(
            selectedVASurvey?.frequencyId,
            selectedSection?._id,
            setSectionQuestions,
            login?.bankId,
            selectedBankDetailsForVa?._id
          )
        );
      } else {
        dispatch(
          gql_get_step3_survey_sections(
            selectedVASurvey?.frequencyId,
            selectedVASurvey?.surveyId,
            login?.vendorId
          )
        );

        dispatch(
          gql_get_step3_section_questions(
            selectedVASurvey?.frequencyId,
            selectedSection?._id,
            setSectionQuestions,
            selectedBankDetailsForVa?._id,
            login?.vendorId
          )
        );
      }
    }, 300);
  };

  // --------------------------------------------

  return (
    <StepLayOut
      left={
        <Step3Left
          step3Sections={step3Sections}
          handleSelectSection={handleSelectSection}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
        />
      }
      right={
        <Step3Right
          selectedSection={selectedSection}
          sectionQuestions={sectionQuestions}
          tabValue={tabValue}
          handleWidgetsStatusChange={handleWidgetsStatusChange}
        />
      }
    />
  );
}

export default SurveyStep3;
