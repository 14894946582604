import { Box, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { gql_update_questions } from "../../../../../../../../redux/actions/riskdashboard/riskDashboardActions";

// other imports
import QuestionBody from "../../QuestionTabComponents/QuestionBody";
import QuestionFields from "./QuestionLayoutComps/QuestionFields";
import QuestionsActions from "./QuestionLayoutComps/QuestionsActions";

const QuestionsLayout = ({
  question,
  isDefault,
  selectedSection,
  setDisplayedQuestions,
}) => {
  // from redux
  const dispatch = useDispatch();

  // states
  const [usingQues, setUsingQues] = useState({});
  const [selectedQues, setSelectedQues] = useState(null);
  const [checkBoxOption, setCheckBoxOption] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);

  const [comment, setComment] = useState(usingQues?.commentBox);

  useEffect(() => {
    setUsingQues(question);
  }, [question]);

  // -------------- Inner Functions --------------

  // useEffect(() => {
  //   setUsingQues(question);
  // }, [question]);

  const boxStyle =
    selectedQues?._id === question?._id
      ? {
          borderLeft: "4px solid #3374B9",
          boxShadow: "0px 11px 20px 0px rgba(5, 16, 55, 0.10)",
          marginBottom: "30px",
        }
      : {
          borderLeft: "1px solid #76A2D0",
          marginBottom: "30px",
        };

  const handleQuestionSave = async (quesObj, hasSave) => {
    // save question
    if (hasSave) {
      let updateQuestionInput = {};

      const newCheckBoxOpt = checkBoxOption.map((obj, index) => ({
        option: obj?.option, // Replace name with option
        weightage: obj?.weightage,
        justification:
          quesObj?.questionType === "SCT" || quesObj?.questionType === "SCTD"
            ? obj?.justification
            : null,
        documentUpload:
          quesObj?.questionType === "SCD" || quesObj?.questionType === "SCTD"
            ? obj?.documentUpload
            : null,
        // Numeric value starting from 0 based on index
      }));

      // option and weightage

      // if questionType if Multichoice then we pass newCheckBoxOpt instead of actual questions
      const useAnswers =
        quesObj?.questionType === "MULTICHOICE" ||
        quesObj?.questionType === "SC" ||
        quesObj?.questionType === "DROPDOWN" ||
        quesObj?.questionType === "SCT" ||
        quesObj?.questionType === "SCD" ||
        quesObj?.questionType === "SCTD"
          ? newCheckBoxOpt
          : quesObj?.answers;

      if (isDefault) {
        updateQuestionInput = {
          id: quesObj?._id,
          weightage: quesObj?.weightage,
        };
      } else {
        updateQuestionInput = {
          id: quesObj?._id,
          question: quesObj?.question,
          answers: useAnswers,
          questionType: quesObj?.questionType,
          weightage: quesObj?.weightage,
          commentBox: comment,
          tableHeaders: tableHeader,
          // isMandatory: selectedQues?.mandatory,
          // hints: selectedQues?.hints,
          // showComment: selectedQues?.comment,
        };
      }
      // updating question
      await dispatch(
        gql_update_questions(updateQuestionInput, selectedSection?._id)
      );

      setUsingQues(quesObj);
      setSelectedQues(null);
    } else {
      // start editing question
      setSelectedQues(quesObj);
    }
  };
  // ---------------------------------------------

  return (
    <Box style={boxStyle}>
      <>
        {/* Question Fields */}
        <QuestionFields
          selectedQues={selectedQues}
          setSelectedQues={setSelectedQues}
          usingQues={usingQues}
          isDefault={isDefault}
          handleQuestionSave={handleQuestionSave}
          selectedSection={selectedSection}
          checkBoxOption={checkBoxOption}
        />

        {/* {usingQues?.hints && (
          <Box>
            <Typography
              variant="body2"
              style={{
                color: "#5BB37F",
                background: "#EBF6EF",
                textAlign: "center",
                padding: "5px",
                width: "500px",
                margin: "10px auto",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontWeight: "500" }}>Hint:</span>{" "}
              {usingQues?.hints}
            </Typography>
          </Box>
        )} */}

        {/* Question Options body */}
        <Box
          style={{
            width: "100%",
            padding: "25px 25px",
            pointerEvents:
              selectedQues?._id === usingQues?._id
                ? isDefault
                  ? "none"
                  : "auto"
                : "none",
          }}
        >
          {usingQues.questionType && (
            <QuestionBody
              questionType={
                selectedQues?.questionType || usingQues?.questionType
              }
              answers={selectedQues?.answers || usingQues?.answers}
              checkBoxOption={checkBoxOption}
              setCheckBoxOption={setCheckBoxOption}
              isFrom={"surveyCreation"}
              usingQues={usingQues}
              setTableHeader={setTableHeader}
              tableHeadersApi={
                selectedQues?.tableHeaders || usingQues?.tableHeaders
              }
            />
          )}

          {usingQues?.showComment && (
            <TextField
              fullWidth
              id="comment"
              label="Contributor's comment"
              variant="outlined"
              multiline
              rows={2}
              placeholder="Contributor's comment"
              style={{ marginTop: "10px" }}
              value={comment}
              defaultValue={usingQues?.commentBox}
              onChange={(e) => setComment(e.target.value)}
            />
          )}
        </Box>

        {/* Question editing options */}
        {selectedQues?._id === usingQues?._id && (
          <QuestionsActions
            isDefault={isDefault}
            selectedQues={selectedQues}
            handleQuestionSave={handleQuestionSave}
            selectedSection={selectedSection}
            setSelectedQues={setSelectedQues}
            usingQues={usingQues}
          />
        )}
      </>
    </Box>
  );
};
export default QuestionsLayout;
